import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import {EmptyLabel} from '@components/label';

const localized = defineMessages({
    winningPlayer: {
        id: 'PlayerStatusCell_winningPlayer',
        defaultMessage: 'Winning Player',
    },
    losingPlayer: {
        id: 'PlayerStatusCell_losingPlayer',
        defaultMessage: 'Losing Player',
    },
});

type PlayerStatusCellValueProps = {
    depositTotal: number;
    withdrawalTotal: number;
};

export function PlayerStatusCellValue({depositTotal, withdrawalTotal}: PlayerStatusCellValueProps) {
    const {formatMessage} = useIntl();
    let net: number = null;
    if (depositTotal && withdrawalTotal) {
        net = depositTotal - withdrawalTotal;
    }
    return net > 0 ? (
        <Chip fillWidth={false} chipType={ChipType.Status} chipVariant={ChipVariant.Green} label={formatMessage(localized.winningPlayer)} />
    ) : net < 0 ? (
        <Chip fillWidth={false} chipType={ChipType.Status} chipVariant={ChipVariant.Purple} label={formatMessage(localized.losingPlayer)} />
    ) : (
        <EmptyLabel defaultSymbol="dash" />
    );
}

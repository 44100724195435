export type Maybe<T> = T | null;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    AWSJSON: any;
    Long: any;
};

export class AccountVerification {
    __typename?: 'AccountVerification';
    account_verification_status?: Maybe<AccountVerificationStatus>;
    documents?: Maybe<Array<Maybe<KycDocument>>>;
    email?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    /** list of reasons for initiating KYC case; optional for backward compatibility */
    init_reason_codes?: Maybe<Array<KycReasonCode>>;
    initiated_at?: Maybe<Timestamp>;
    iso_alpha2_country_code?: Maybe<Scalars['String']>;
    kyc_case_id?: Maybe<Scalars['String']>;
    kyc_manual?: Maybe<Scalars['Boolean']>;
    /** last list of reasons specified when changing the KYC status; optional for backward compatibility */
    reason_codes?: Maybe<Array<KycReasonCode>>;
    status_log?: Maybe<Array<AccountVerificationStatusLog>>;
    type: AccountVerificationType;
    uid: Scalars['String'];
    /** player's labels */
    user_labels?: Maybe<Array<UserLabel>>;
    username?: Maybe<Scalars['String']>;
}

export class AccountVerificationFilter {
    account_verification_status?: Maybe<Array<AccountVerificationStatus>>;
    /** filter KYC cases initiated with any reason from the list */
    init_reason_codes?: Maybe<Array<KycReasonCode>>;
    initiated_at?: Maybe<DateRange>;
    iso_alpha2_country_code?: Maybe<Array<Maybe<Scalars['String']>>>;
    kyc_status?: Maybe<Array<KycStatus>>;
    /** KYC case filter for the latest status change reasons */
    reason_codes?: Maybe<Array<KycReasonCode>>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
    type?: Maybe<Array<AccountVerificationType>>;
}

export class AccountVerificationInput {
    account_verification_status?: Maybe<AccountVerificationStatus>;
    documents?: Maybe<Array<Maybe<KycDocumentInput>>>;
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    /** list of reasons for initiating KYC case; optional for backward compatibility */
    init_reason_codes?: Maybe<Array<KycReasonCode>>;
    initiated_at?: Maybe<TimestampInput>;
    iso_alpha2_country_code?: Maybe<Scalars['String']>;
    kyc_case_id?: Maybe<Scalars['String']>;
    kyc_manual?: Maybe<Scalars['Boolean']>;
    /** last list of reasons specified when changing the KYC status; optional for backward compatibility */
    reason_codes?: Maybe<Array<KycReasonCode>>;
    status_log?: Maybe<Array<AccountVerificationStatusLogInput>>;
    type?: Maybe<AccountVerificationType>;
    uid?: Maybe<Scalars['String']>;
    username?: Maybe<Scalars['String']>;
}

export class AccountVerificationPage {
    __typename?: 'AccountVerificationPage';
    items?: Maybe<Array<Maybe<AccountVerification>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export enum AccountVerificationStatus {
    Approved = 'Approved',
    DeniedDepositKyc = 'DeniedDepositKYC',
    DeniedLoginKyc = 'DeniedLoginKYC',
    DeniedNdrpKyc = 'DeniedNdrpKYC',
    DeniedPaymentKyc = 'DeniedPaymentKYC',
    DeniedWithdrawalKyc = 'DeniedWithdrawalKYC',
    InitDepositKyc = 'InitDepositKYC',
    InitLoginKyc = 'InitLoginKYC',
    InitNdrpKyc = 'InitNdrpKYC',
    InitPaymentKyc = 'InitPaymentKYC',
    InitWithdrawalKyc = 'InitWithdrawalKYC',
    OnHoldDepositKyc = 'OnHoldDepositKYC',
    OnHoldLoginKyc = 'OnHoldLoginKYC',
    OnHoldPaymentKyc = 'OnHoldPaymentKYC',
    OnHoldWithdrawalKyc = 'OnHoldWithdrawalKYC',
}

export class AccountVerificationStatusLog {
    __typename?: 'AccountVerificationStatusLog';
    account_verification_status?: Maybe<AccountVerificationStatus>;
    kyc_status?: Maybe<KycStatus>;
    logged_at?: Maybe<Timestamp>;
    reason_codes?: Maybe<Array<Maybe<KycReasonCode>>>;
}

export class AccountVerificationStatusLogInput {
    account_verification_status?: Maybe<AccountVerificationStatus>;
    kyc_status?: Maybe<KycStatus>;
    logged_at?: Maybe<TimestampInput>;
}

export enum AccountVerificationType {
    /** NdrpVerification -> initiated automatically during registration */
    NdrpVerification = 'NdrpVerification',
    /** Payment  -> for InitPaymentKYC */
    Payment = 'Payment',
    /** Security -> for InitLoginKYC, InitWithdrawalKYC, InitDepositKYC */
    Security = 'Security',
}

export class ActionFilter {
    action?: Maybe<ActionType>;
    round?: Maybe<BettingRound>;
}

export enum ActionType {
    AddActionTime = 'AddActionTime',
    Allin = 'Allin',
    Bet = 'Bet',
    Call = 'Call',
    CallMuck = 'CallMuck',
    Check = 'Check',
    Fold = 'Fold',
    HoleCards = 'HoleCards',
    Null = 'Null',
    Post = 'Post',
    Raise = 'Raise',
    SendCardCommon = 'SendCard_Common',
    Straddle = 'Straddle',
}

export class ActiveAccountVerification {
    __typename?: 'ActiveAccountVerification';
    payment?: Maybe<AccountVerification>;
    security?: Maybe<AccountVerification>;
}

export class ActiveAccountVerificationInput {
    payment?: Maybe<AccountVerificationInput>;
    security?: Maybe<AccountVerificationInput>;
}

export class AddUserLabelInput {
    label_ids: Array<Scalars['String']>;
    uid: Scalars['String'];
}

export class AffiliateInput {
    btag: Scalars['String'];
}

export class Affiliates {
    __typename?: 'Affiliates';
    affiliated_date?: Maybe<Scalars['String']>;
    btag?: Maybe<Scalars['String']>;
    invite_code?: Maybe<Scalars['String']>;
    notification_date?: Maybe<Scalars['String']>;
}

export class AffiliatesInput {
    affiliated_date?: Maybe<Scalars['String']>;
    btag?: Maybe<Scalars['String']>;
    invite_code?: Maybe<Scalars['String']>;
    notification_date?: Maybe<Scalars['String']>;
}

export class AgentInfo {
    __typename?: 'AgentInfo';
    agent_granted_ts?: Maybe<Timestamp>;
    bo_agent_id?: Maybe<Scalars['String']>;
    default_agent_revenue_share_history?: Maybe<Scalars['String']>;
}

export class AgentInfoInput {
    agent_granted_ts?: Maybe<TimestampInput>;
    bo_agent_id?: Maybe<Scalars['String']>;
    default_agent_revenue_share_history?: Maybe<Scalars['String']>;
}

export class AgentPathStatistics {
    __typename?: 'AgentPathStatistics';
    active_player_number?: Maybe<Scalars['Int']>;
    agent_uid?: Maybe<Scalars['String']>;
    path_to_subagent?: Maybe<Scalars['String']>;
    registered_player_number?: Maybe<Scalars['Int']>;
}

export class AgentPathStatisticsPage {
    __typename?: 'AgentPathStatisticsPage';
    items?: Maybe<Array<Maybe<AgentPathStatistics>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export enum AgentPlayerReportVersion {
    Daily = 'Daily',
    Weekly = 'Weekly',
}

export class AgentPlayerReporting {
    __typename?: 'AgentPlayerReporting';
    bonus_summary?: Maybe<BonusSummary>;
    contact?: Maybe<UserContactInfo>;
    deduction_summary?: Maybe<DeductionSummary>;
    ggr_summary?: Maybe<GgrSummary>;
    highest_blind_level_is_pro_nlhe?: Maybe<Scalars['String']>;
    iso_alpha2_country_code?: Maybe<Scalars['String']>;
    ngr_summary?: Maybe<NgrSummary>;
    other_summary?: Maybe<OtherSummary>;
    player_games_summary?: Maybe<PlayerGamesSummary>;
    pro_summary?: Maybe<ProSummary>;
    referrer_agent_id?: Maybe<Scalars['String']>;
    referrer_agent_username?: Maybe<Scalars['String']>;
    register_marketing_code?: Maybe<Scalars['String']>;
    uid: Scalars['String'];
    username?: Maybe<Scalars['String']>;
}

export class AgentPlayerReportingFilter {
    referrer?: Maybe<ReferrerFilter>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
    transaction_started_ts?: Maybe<DateRange>;
}

export class AgentPlayerReportingFilterVn {
    referrer?: Maybe<ReferrerFilter>;
    report_version: AgentPlayerReportVersion;
    text?: Maybe<Array<Maybe<TextFilter>>>;
    transaction_started_ts?: Maybe<DateRange>;
}

export class AgentPlayerReportingKr {
    __typename?: 'AgentPlayerReportingKr';
    casino_share?: Maybe<Scalars['Float']>;
    poker_share?: Maybe<Scalars['Float']>;
    /** is referee player or subagent */
    referee_type?: Maybe<RefereeType>;
    /** player id of agent */
    referrer_agent_id: Scalars['String'];
    referrer_agent_username?: Maybe<Scalars['String']>;
    /** is revenue generated directly during playing or as a subagent */
    revenue_share_type?: Maybe<RevenueShareType>;
    total_share?: Maybe<Scalars['Float']>;
    /** player id of referee */
    uid?: Maybe<Scalars['String']>;
}

export class AgentPlayerReportingKrPage {
    __typename?: 'AgentPlayerReportingKrPage';
    /** array of reports for sub agents and players invited by korean agents */
    items?: Maybe<Array<Maybe<AgentPlayerReportingKr>>>;
    /** summary with summed up values of reports for all pages */
    summary?: Maybe<AgentPlayerReportingKrSummary>;
    total_count?: Maybe<Scalars['Int']>;
}

export class AgentPlayerReportingKrSummary {
    __typename?: 'AgentPlayerReportingKrSummary';
    casino_share?: Maybe<Scalars['Float']>;
    poker_share?: Maybe<Scalars['Float']>;
    total_share?: Maybe<Scalars['Float']>;
}

export class AgentPlayerReportingPage {
    __typename?: 'AgentPlayerReportingPage';
    /** array of reports for sub agents and players invited by agents */
    items?: Maybe<Array<Maybe<AgentPlayerReporting>>>;
    /** summary with summed up values of reports for all pages */
    summary?: Maybe<AgentPlayerReportingSummary>;
    total_count?: Maybe<Scalars['Int']>;
}

export class AgentPlayerReportingSummary {
    __typename?: 'AgentPlayerReportingSummary';
    bonus_summary?: Maybe<BonusSummary>;
    deduction_summary?: Maybe<DeductionSummary>;
    ggr_summary?: Maybe<GgrSummary>;
    ngr_summary?: Maybe<NgrSummary>;
    other_summary?: Maybe<OtherSummary>;
    player_games_summary?: Maybe<PlayerGamesSummary>;
    pro_summary?: Maybe<ProSummary>;
}

export class AgentPlayerReportingVn {
    __typename?: 'AgentPlayerReportingVn';
    active_player_number?: Maybe<Scalars['Int']>;
    casino_ggr?: Maybe<Scalars['Float']>;
    /** referee contact info: mobile and email */
    contact?: Maybe<UserContactInfo>;
    insurance_tool?: Maybe<Scalars['Float']>;
    rakes?: Maybe<Scalars['Float']>;
    /** is referee player or subagent */
    referee_type?: Maybe<RefereeType>;
    /** player id of agent */
    referrer_agent_id: Scalars['String'];
    referrer_agent_username?: Maybe<Scalars['String']>;
    registered_player_number?: Maybe<Scalars['Int']>;
    revenue_share?: Maybe<Scalars['Float']>;
    /** is revenue generated directly during playing or as a subagent */
    revenue_share_type?: Maybe<RevenueShareType>;
    /** player id of referee */
    uid?: Maybe<Scalars['String']>;
}

export class AgentPlayerReportingVnPage {
    __typename?: 'AgentPlayerReportingVnPage';
    /** array of reports for sub agents and players invited by vietnamese agents */
    items?: Maybe<Array<Maybe<AgentPlayerReportingVn>>>;
    /** summary with summed up values of reports for all pages */
    summary?: Maybe<AgentPlayerReportingVnSummary>;
    total_count?: Maybe<Scalars['Int']>;
}

export class AgentPlayerReportingVnSummary {
    __typename?: 'AgentPlayerReportingVnSummary';
    casino_ggr?: Maybe<Scalars['Float']>;
    insurance_tool?: Maybe<Scalars['Float']>;
    rakes?: Maybe<Scalars['Float']>;
    revenue_share?: Maybe<Scalars['Float']>;
}

export class AgentProfileFilter {
    referrer_agent_id?: Maybe<Scalars['String']>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class AgentRevenueShareTier {
    __typename?: 'AgentRevenueShareTier';
    casino_revenue_share?: Maybe<Scalars['Float']>;
    generated_external_casino_ggr?: Maybe<Scalars['Float']>;
    generated_internal_casino_ggr?: Maybe<Scalars['Float']>;
    poker_revenue_share?: Maybe<Scalars['Float']>;
    revenue_shared?: Maybe<Scalars['Float']>;
}

/** week_start_date and referrer_id pair is unique */
export class AgentRevenueShareWeeklyReport {
    __typename?: 'AgentRevenueShareWeeklyReport';
    adjusted_revenue_shared_total?: Maybe<Scalars['Float']>;
    carry_over_negative_balance?: Maybe<Scalars['Float']>;
    casino_ggr?: Maybe<Scalars['Float']>;
    external_casino_ggr?: Maybe<Scalars['Float']>;
    internal_casino_ggr?: Maybe<Scalars['Float']>;
    poker_ngr?: Maybe<Scalars['Float']>;
    referrer_id: Scalars['String'];
    referrer_psp_fees?: Maybe<Scalars['Float']>;
    referrer_username?: Maybe<Scalars['String']>;
    revenue_shared_total?: Maybe<Scalars['Float']>;
    tiers?: Maybe<Array<Maybe<AgentRevenueShareTier>>>;
    week_start_date_ts: Timestamp;
}

export class AgentRevenueShareWeeklyReportFilter {
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class AgentRevenueShareWeeklyReportPage {
    __typename?: 'AgentRevenueShareWeeklyReportPage';
    items?: Maybe<Array<Maybe<AgentRevenueShareWeeklyReport>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class AggregatedFinanceSummariesFilterInput {
    aggregation_range?: Maybe<DateRange>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class AggregatedFinanceSummariesPage {
    __typename?: 'AggregatedFinanceSummariesPage';
    items?: Maybe<Array<Maybe<AggregatedFinanceSummary>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class AggregatedFinanceSummary {
    __typename?: 'AggregatedFinanceSummary';
    bonus_ratio?: Maybe<Scalars['Float']>;
    created_at?: Maybe<Timestamp>;
    /** total deposit attempts summary */
    deposit?: Maybe<TransactionAttemptsSummary>;
    end_at?: Maybe<Timestamp>;
    generated_rake?: Maybe<Scalars['Float']>;
    ggr?: Maybe<Ggr>;
    lifetime_bonus?: Maybe<Scalars['Float']>;
    start_at?: Maybe<Timestamp>;
    uid: Scalars['String'];
    /** total withdrawal attempts summary */
    withdrawal?: Maybe<TransactionAttemptsSummary>;
}

export class AggregatedFinanceSummaryFilterInput {
    aggregation_range?: Maybe<DateRange>;
    uid: Scalars['String'];
}

export class AggregatedFinanceSummaryInput {
    bonus_ratio?: Maybe<Scalars['Float']>;
    created_at: TimestampInput;
    deposit?: Maybe<TransactionAttemptsSummaryInput>;
    generated_rake?: Maybe<Scalars['Float']>;
    ggr?: Maybe<GgrInput>;
    lifetime_bonus?: Maybe<Scalars['Float']>;
    uid: Scalars['String'];
    withdrawal?: Maybe<TransactionAttemptsSummaryInput>;
}

export enum AmlLabel {
    AccountReview = 'AccountReview',
    ActivityReview = 'ActivityReview',
    EddDeposit = 'EddDeposit',
    EddGameplay = 'EddGameplay',
    EddPep = 'EddPep',
    EddWithdrawal = 'EddWithdrawal',
    GameplayReview = 'GameplayReview',
    VerifyPaymentMethod = 'VerifyPaymentMethod',
}

export class Attachment {
    __typename?: 'Attachment';
    extension?: Maybe<Scalars['String']>;
    filename?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
}

export class AttachmentInput {
    extension?: Maybe<Scalars['String']>;
    filename?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
}

export class AutoWithdrawalRulesData {
    __typename?: 'AutoWithdrawalRulesData';
    is_current_transaction_closed_loop?: Maybe<Scalars['Boolean']>;
    is_current_transaction_first_withdrawal?: Maybe<Scalars['Boolean']>;
    is_kyc_approved?: Maybe<Scalars['Boolean']>;
    is_security_case_opened?: Maybe<Scalars['Boolean']>;
    total_player_locks?: Maybe<Scalars['Int']>;
    total_successful_withdrawals_count?: Maybe<Scalars['Int']>;
    total_successful_withdrawals_last_24h?: Maybe<Scalars['Float']>;
    total_successful_withdrawals_lt?: Maybe<Scalars['Float']>;
    transaction_amount?: Maybe<Scalars['Float']>;
    transaction_id?: Maybe<Scalars['String']>;
    uid?: Maybe<Scalars['String']>;
}

export class BackpackItem {
    __typename?: 'BackpackItem';
    bonus_id: Scalars['String'];
    bonus_name: Scalars['String'];
    bonus_status: BackpackItemStatus;
    created_at: Timestamp;
    marketing_code: Scalars['String'];
    serial_number?: Maybe<Scalars['String']>;
    tool_label?: Maybe<BackpackItemLabel>;
    uid: Scalars['String'];
    used_at?: Maybe<Timestamp>;
    value?: Maybe<Scalars['Float']>;
}

export class BackpackItemInput {
    bonus_id: Scalars['String'];
    bonus_name: Scalars['String'];
    bonus_status: BackpackItemStatus;
    created_at: TimestampInput;
    marketing_code: Scalars['String'];
    serial_number?: Maybe<Scalars['String']>;
    tool_label?: Maybe<BackpackItemLabel>;
    trigger_by?: Maybe<EventInitiator>;
    trigger_by_uid?: Maybe<Scalars['String']>;
    uid: Scalars['String'];
    used_at?: Maybe<TimestampInput>;
    value?: Maybe<Scalars['Float']>;
}

export enum BackpackItemLabel {
    A93 = 'A93',
    CompensationTicket = 'CompensationTicket',
    FreeMatch = 'FreeMatch',
    Gift = 'Gift',
    MttReward = 'MTTReward',
    PlayerBuy = 'PlayerBuy',
    PointRedeem = 'PointRedeem',
    RewardActivity = 'RewardActivity',
}

export enum BackpackItemSource {
    BonusEngine = 'Bonus_Engine',
    MttBackoffice = 'MTT_Backoffice',
}

export enum BackpackItemStatus {
    Active = 'Active',
    Expired = 'Expired',
    Used = 'Used',
}

export class BackpackItemsFilter {
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class BackpackItemsPage {
    __typename?: 'BackpackItemsPage';
    items?: Maybe<Array<BackpackItem>>;
    total_count?: Maybe<Scalars['Int']>;
}

export enum BettingRound {
    Flop = 'Flop',
    None = 'None',
    Preflop = 'Preflop',
    River = 'River',
    ShowDown = 'ShowDown',
    Turn = 'Turn',
}

export class BlackListItem {
    __typename?: 'BlackListItem';
    key: Scalars['String'];
    type: BlackListItemType;
}

export class BlackListItemInput {
    key: Scalars['String'];
    type: BlackListItemType;
}

export enum BlackListItemType {
    Device = 'DEVICE',
    Ip = 'IP',
}

export class BonusCasino {
    __typename?: 'BonusCasino';
    aggregators?: Maybe<Array<Maybe<Scalars['String']>>>;
    game_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
    source_types?: Maybe<Array<Maybe<BonusCasinoSourceType>>>;
    supplier_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export class BonusCasinoInput {
    /** List of casino aggregators. Only `External` games have aggregator. Examples: bragg */
    aggregators?: Maybe<Array<Maybe<Scalars['String']>>>;
    /**
     * List of casino game codes
     *
     * External game examples: ARC_ARM, ARC_DEA, ASL_EGM_EU, ASL_ENM_EU_94
     *
     * Internal game examples: POKERFLIP, POKERMASTER
     */
    game_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
    source_types?: Maybe<Array<Maybe<BonusCasinoSourceType>>>;
    /** List of casino supplier codes. Only `External` games have supplier. Examples: EVOL, GAM, BBR */
    supplier_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum BonusCasinoSourceType {
    External = 'External',
    Internal = 'Internal',
}

export class BonusCodeDetails {
    __typename?: 'BonusCodeDetails';
    bonus_id: Scalars['String'];
    bonus_name: Scalars['String'];
    bonus_status: BonusStatus;
    bonus_to_credit: BonusToCredit;
    create_time: Timestamp;
    credit_type: BonusCreditType;
    description?: Maybe<Scalars['String']>;
    end_time: Timestamp;
    last_update: Timestamp;
    marketing_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
    meta: BonusMeta;
    platform: Platform;
    release_mechanism: BonusReleaseMechanism;
    release_metric: BonusReleaseMetric;
    release_ratio_type: BonusReleaseRatioType;
    start_time: Timestamp;
    threshold?: Maybe<Scalars['Int']>;
    total_paid_out_amount?: Maybe<Scalars['Float']>;
}

export class BonusCodeFilterInput {
    bonus_status?: Maybe<Array<Maybe<BonusStatus>>>;
    create_time?: Maybe<DateRange>;
    credit_type?: Maybe<Array<Maybe<BonusCreditType>>>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class BonusCodeHistory {
    __typename?: 'BonusCodeHistory';
    bonus_id: Scalars['String'];
    created_at: Timestamp;
    email?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    iso_alpha2_country_code?: Maybe<Scalars['String']>;
    transaction_amount: Scalars['Float'];
    /** initiator type for triggering a bonus */
    trigger_by?: Maybe<EventInitiator>;
    /**
     * initiator unique identifier for triggering a bonus
     * ('CIO' when initated by CIO, uid when initiated from back office)
     */
    trigger_by_uid?: Maybe<Scalars['String']>;
    type: BonusCreditType;
    uid: Scalars['String'];
    unrealized_amount: Scalars['Float'];
    username?: Maybe<Scalars['String']>;
}

export class BonusCodeHistoryFilterInput {
    created_at?: Maybe<DateRange>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
    type?: Maybe<Array<Maybe<BonusCreditType>>>;
}

export class BonusCodeHistoryInput {
    bonus_id: Scalars['String'];
    created_at: TimestampInput;
    email?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    iso_alpha2_country_code?: Maybe<Scalars['String']>;
    transaction_amount: Scalars['Float'];
    trigger_by?: Maybe<EventInitiator>;
    /**
     * initiator unique identifier for triggering a bonus
     * ('CIO' when initiated by CIO, uid when initiated from back office)
     */
    trigger_by_uid?: Maybe<Scalars['String']>;
    type: BonusCreditType;
    uid: Scalars['String'];
    unrealized_amount: Scalars['Float'];
    username?: Maybe<Scalars['String']>;
}

export class BonusCodeHistoryPage {
    __typename?: 'BonusCodeHistoryPage';
    items?: Maybe<Array<BonusCodeHistory>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class BonusCodeInput {
    bonus_name: Scalars['String'];
    bonus_status: BonusStatus;
    bonus_to_credit: BonusToCredit;
    create_time: TimestampInput;
    credit_type: BonusCreditType;
    description?: Maybe<Scalars['String']>;
    end_time: TimestampInput;
    meta: BonusMetaInput;
    platform: Platform;
    release_mechanism: BonusReleaseMechanism;
    release_metric: BonusReleaseMetric;
    release_ratio_type: BonusReleaseRatioType;
    start_time: TimestampInput;
    threshold?: Maybe<Scalars['Int']>;
}

export class BonusCodePage {
    __typename?: 'BonusCodePage';
    items?: Maybe<Array<BonusCodeDetails>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class BonusCodeUpsert {
    bonus_id: Scalars['String'];
    bonus_name: Scalars['String'];
    bonus_status: BonusStatus;
    bonus_to_credit: BonusToCredit;
    business_pool?: Maybe<Scalars['String']>;
    create_time: TimestampInput;
    credit_type: BonusCreditType;
    description?: Maybe<Scalars['String']>;
    end_time: TimestampInput;
    last_update: TimestampInput;
    marketing_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
    meta: BonusMetaInput;
    platform: Platform;
    release_mechanism: BonusReleaseMechanism;
    release_metric: BonusReleaseMetric;
    release_ratio_type: BonusReleaseRatioType;
    start_time: TimestampInput;
    threshold?: Maybe<Scalars['Int']>;
    total_paid_out_amount?: Maybe<Scalars['Float']>;
    ts?: Maybe<TimestampInput>;
}

export enum BonusCreditType {
    CashBonus = 'CASH_BONUS',
    CasinoCoin = 'CASINO_COIN',
    MttToken = 'MTT_TOKEN',
}

export class BonusMeta {
    __typename?: 'BonusMeta';
    bonus_value_param?: Maybe<Scalars['Float']>;
    casino_excluded?: Maybe<BonusCasino>;
    casino_included?: Maybe<BonusCasino>;
    expire_period?: Maybe<Scalars['Int']>;
    max_bonus_get?: Maybe<Scalars['Float']>;
    metric_amount?: Maybe<Scalars['Float']>;
    metric_qualify?: Maybe<Scalars['Float']>;
    min_deposit?: Maybe<Scalars['Float']>;
    overall_max_bonus_get?: Maybe<Scalars['Float']>;
    rake_amount?: Maybe<Scalars['Float']>;
    rake_qualify?: Maybe<Scalars['Float']>;
    release_ratios?: Maybe<Array<Maybe<BonusReleaseRatio>>>;
    show_max_bonus_in_total?: Maybe<Scalars['Float']>;
    ticket_serial?: Maybe<Scalars['String']>;
    time_range_by_date?: Maybe<Scalars['Int']>;
    total_max_bonus_get?: Maybe<Scalars['Float']>;
    unrealized_expire_period?: Maybe<Scalars['Int']>;
}

export class BonusMetaInput {
    bonus_value_param?: Maybe<Scalars['Float']>;
    /**
     * Configuration for casino games for which bonus should not be applied.
     *
     * If any field is passed in `casino_excluded`, the same field must not be passed in `casino_included`
     */
    casino_excluded?: Maybe<BonusCasinoInput>;
    /**
     * Configuration for casino games for which bonus should be applied.
     *
     * If any field is passed in `casino_included`, the same field must not be passed in `casino_excluded`
     */
    casino_included?: Maybe<BonusCasinoInput>;
    expire_period?: Maybe<Scalars['Int']>;
    max_bonus_get?: Maybe<Scalars['Float']>;
    metric_amount?: Maybe<Scalars['Float']>;
    metric_qualify?: Maybe<Scalars['Float']>;
    min_deposit?: Maybe<Scalars['Float']>;
    overall_max_bonus_get?: Maybe<Scalars['Float']>;
    rake_amount?: Maybe<Scalars['Float']>;
    rake_qualify?: Maybe<Scalars['Float']>;
    release_ratios?: Maybe<Array<Maybe<BonusReleaseRatioInput>>>;
    show_max_bonus_in_total?: Maybe<Scalars['Float']>;
    ticket_serial?: Maybe<Scalars['String']>;
    time_range_by_date?: Maybe<Scalars['Int']>;
    total_max_bonus_get?: Maybe<Scalars['Float']>;
    unrealized_expire_period?: Maybe<Scalars['Int']>;
}

export enum BonusReleaseMechanism {
    AmountPerReleaseMetricAmount = 'AMOUNT_PER_RELEASE_METRIC_AMOUNT',
    Immediate = 'IMMEDIATE',
}

export enum BonusReleaseMetric {
    None = 'NONE',
    RakeGenerated = 'RAKE_GENERATED',
    WagerGenerated = 'WAGER_GENERATED',
}

export class BonusReleaseRatio {
    __typename?: 'BonusReleaseRatio';
    category: Scalars['Int'];
    layer_metric_amount: Scalars['Float'];
    layer_metric_qualify: Scalars['Float'];
    level: Scalars['Float'];
}

export class BonusReleaseRatioInput {
    category: Scalars['Int'];
    layer_metric_amount: Scalars['Float'];
    layer_metric_qualify: Scalars['Float'];
    level: Scalars['Float'];
}

export enum BonusReleaseRatioType {
    Fixed = 'FIXED',
    Layering = 'LAYERING',
}

export enum BonusStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export class BonusSummary {
    __typename?: 'BonusSummary';
    casino_bonus?: Maybe<Scalars['Float']>;
    cost_global_spins_tickets?: Maybe<Scalars['Float']>;
    mtt_ticket_bonus?: Maybe<Scalars['Float']>;
    poker_bonus?: Maybe<Scalars['Float']>;
    realized_bonus_total?: Maybe<Scalars['Float']>;
    unrealized_bonus_total?: Maybe<Scalars['Float']>;
}

export enum BonusToCredit {
    Amount = 'AMOUNT',
    PercentOfTriggerAmount = 'PERCENT_OF_TRIGGER_AMOUNT',
}

export enum BonusType {
    Deposit = 'Deposit',
    Register = 'Register',
    Team = 'Team',
}

export enum BotDetectionActionType {
    BotDetectionActionTypeAdded = 'BOT_DETECTION_ACTION_TYPE_ADDED',
    BotDetectionActionTypeRemoved = 'BOT_DETECTION_ACTION_TYPE_REMOVED',
}

export enum BotDetectionPlayersGroup {
    BotDetectionBotPlayer = 'BOT_DETECTION_BOT_PLAYER',
    BotDetectionEvidence = 'BOT_DETECTION_EVIDENCE',
    BotDetectionUndefined = 'BOT_DETECTION_UNDEFINED',
}

export class BotGroup {
    __typename?: 'BotGroup';
    count: Scalars['Int'];
    created_at_ts: Timestamp;
    group_id: Scalars['String'];
    pfr_average?: Maybe<Scalars['Float']>;
    recently_active?: Maybe<Scalars['Int']>;
    total_funds?: Maybe<Scalars['Float']>;
    uids?: Maybe<Array<Scalars['String']>>;
    vpip_average?: Maybe<Scalars['Float']>;
}

export class BotGroupFilter {
    created_at_ts?: Maybe<DateRange>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class BotGroupInput {
    count?: Maybe<Scalars['Int']>;
    created_at_ts: TimestampInput;
    group_id: Scalars['String'];
    pfr_average?: Maybe<Scalars['Float']>;
    recently_active?: Maybe<Scalars['Int']>;
    total_funds?: Maybe<Scalars['Float']>;
    uids?: Maybe<Array<Scalars['String']>>;
    vpip_average?: Maybe<Scalars['Float']>;
}

export class BotGroupPage {
    __typename?: 'BotGroupPage';
    items?: Maybe<Array<BotGroup>>;
    total_count: Scalars['Int'];
}

export enum Brand {
    Unknown = 'UNKNOWN',
    Wptg = 'WPTG',
    Wptgo = 'WPTGO',
}

export class BulkItem {
    __typename?: 'BulkItem';
    id: Scalars['String'];
    item_id?: Maybe<Scalars['String']>;
    message?: Maybe<Scalars['String']>;
    status: BulkItemStatus;
}

/** payloads for different operations. Used as a generic type workaround. Each operation must have only one field from BulkItemDataInput */
export class BulkItemDataInput {
    bonus?: Maybe<PlayerBonusTriggerInput>;
    /** input for lockCasinoBulkOperation */
    casino_lock?: Maybe<FeatureAccessLockInput>;
    /** input for initPaymentKYCBulkOperation and initSecurityKYCBulkOperation */
    kyc_init?: Maybe<InitKycInput>;
    /** input for updateKYCBulkOperation */
    kyc_status?: Maybe<KycStatusInput>;
    p2p_lock?: Maybe<FeatureAccessLockInput>;
    /** input for `addP2PTransferBulkOperation` */
    p2p_transfer?: Maybe<P2PTransferInput>;
    /** input for lockSportsbookBulkOperation */
    sportsbook_lock?: Maybe<FeatureAccessLockInput>;
    transaction?: Maybe<ManualTransactionInput>;
    user_label_add?: Maybe<AddUserLabelInput>;
    user_label_delete?: Maybe<DeleteUserLabelInput>;
}

export class BulkItemInput {
    /** payload of bulk operation item */
    data: BulkItemDataInput;
    /** unique id of item */
    id: Scalars['String'];
}

export enum BulkItemStatus {
    Failed = 'Failed',
    InProgress = 'InProgress',
    Pending = 'Pending',
    Successful = 'Successful',
    TimedOut = 'TimedOut',
}

export class BulkMutationInput {
    /** list of items with payload */
    items: Array<BulkItemInput>;
    /** number of retries for single items */
    retries?: Maybe<Scalars['Int']>;
    /** timeout for single item in seconds */
    timeout: Scalars['Int'];
}

export class BulkMutationResponse {
    __typename?: 'BulkMutationResponse';
    id: Scalars['String'];
}

export class BulkOperationFilterInput {
    id: Scalars['String'];
}

export class BulkOperationResult {
    __typename?: 'BulkOperationResult';
    end_at?: Maybe<Timestamp>;
    entity_type: EntityType;
    id: Scalars['String'];
    items?: Maybe<Array<Maybe<BulkItem>>>;
    retries?: Maybe<Scalars['Int']>;
    start_at?: Maybe<Timestamp>;
    status: BulkOperationStatus;
    timeout?: Maybe<Scalars['Int']>;
}

export class BulkOperationResultFilter {
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class BulkOperationResultPage {
    __typename?: 'BulkOperationResultPage';
    items?: Maybe<Array<BulkOperationResult>>;
    total_count?: Maybe<Scalars['Int']>;
}

export enum BulkOperationStatus {
    Completed = 'Completed',
    InProgress = 'InProgress',
    Pending = 'Pending',
}

export class CardItem {
    __typename?: 'CardItem';
    number: CardNumber;
    suit: CardSuite;
}

export class CardItemInput {
    number: CardNumber;
    suit: CardSuite;
}

export enum CardNumber {
    Card_2 = 'Card_2',
    Card_3 = 'Card_3',
    Card_4 = 'Card_4',
    Card_5 = 'Card_5',
    Card_6 = 'Card_6',
    Card_7 = 'Card_7',
    Card_8 = 'Card_8',
    Card_9 = 'Card_9',
    Card_10 = 'Card_10',
    CardA = 'Card_A',
    CardJ = 'Card_J',
    CardK = 'Card_K',
    CardQ = 'Card_Q',
}

export enum CardSuite {
    Club = 'Club',
    Diamond = 'Diamond',
    Heart = 'Heart',
    Spade = 'Spade',
}

export class CasinoError {
    __typename?: 'CasinoError';
    code?: Maybe<Scalars['String']>;
    values?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum CasinoProviderName {
    Oryx = 'Oryx',
}

export class CasinoResponse {
    __typename?: 'CasinoResponse';
    error?: Maybe<CasinoError>;
    reference_id: Scalars['String'];
    success: Scalars['Boolean'];
}

export class CasinoTriggerInput {
    casino_code: Scalars['String'];
    casino_provider_name: CasinoProviderName;
    currency: Scalars['String'];
    reference_id: Scalars['String'];
    uid: Scalars['String'];
}

export class Club {
    __typename?: 'Club';
    club_id: Scalars['String'];
    club_name?: Maybe<Scalars['String']>;
}

export class ClubsFilter {
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class ClubsPage {
    __typename?: 'ClubsPage';
    items?: Maybe<Array<Maybe<Club>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export enum CommentTargetType {
    BotGroup = 'BOT_GROUP',
    Player = 'PLAYER',
}

export class CountryInfo {
    __typename?: 'CountryInfo';
    iso_alpha2_code?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
}

export class CountryInfoInput {
    iso_alpha2_code?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
}

export class DashboardUrl {
    __typename?: 'DashboardUrl';
    expire_at?: Maybe<Timestamp>;
    url?: Maybe<Scalars['String']>;
}

export class DateRange {
    from?: Maybe<TimestampInput>;
    to?: Maybe<TimestampInput>;
}

export class DeductionSummary {
    __typename?: 'DeductionSummary';
    casino_provider_fee?: Maybe<Scalars['Float']>;
    ngr_cost?: Maybe<Scalars['Float']>;
    psp_fee?: Maybe<Scalars['Float']>;
    total?: Maybe<Scalars['Float']>;
}

export class DeleteUserLabelInput {
    label_id: Scalars['String'];
    uid: Scalars['String'];
}

export class Device {
    __typename?: 'Device';
    agent?: Maybe<Scalars['String']>;
    mac_address?: Maybe<Scalars['String']>;
    model?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    operating_system?: Maybe<Scalars['String']>;
    uuid: Scalars['String'];
}

export class DeviceInput {
    agent?: Maybe<Scalars['String']>;
    mac_address?: Maybe<Scalars['String']>;
    model?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    operating_system?: Maybe<Scalars['String']>;
    uuid: Scalars['String'];
}

export enum EmbeddedDashboardType {
    GlobalAgentLevelReport = 'GlobalAgentLevelReport',
    GlobalPlayerLevelReport = 'GlobalPlayerLevelReport',
    GlobalTransferReport = 'GlobalTransferReport',
}

export class Entity {
    __typename?: 'Entity';
    id: Scalars['String'];
    parent?: Maybe<Entity>;
    type: EntityType;
}

export enum EntityFilterType {
    AllRecords = 'AllRecords',
    Specific = 'Specific',
}

export class EntityInput {
    id?: Maybe<Scalars['String']>;
    parent?: Maybe<EntityInput>;
    type?: Maybe<EntityType>;
}

export enum EntityRelationType {
    Global = 'Global',
    Record = 'Record',
}

export enum EntityType {
    Bonus = 'Bonus',
    BotGroup = 'BotGroup',
    Deposit = 'Deposit',
    KycCase = 'KYCCase',
    Player = 'Player',
    Transaction = 'Transaction',
    Withdrawal = 'Withdrawal',
}

export enum EventInitiator {
    Bo = 'BO',
    Cio = 'CIO',
    None = 'None',
    Player = 'Player',
    Service = 'Service',
}

export class EventLogFilter {
    entity?: Maybe<EntityInput>;
    initiator?: Maybe<Array<EventInitiator>>;
    side_effect?: Maybe<Scalars['Boolean']>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
    type?: Maybe<EntityFilterType>;
}

export class EventLogRecord {
    __typename?: 'EventLogRecord';
    entity?: Maybe<Entity>;
    entity_relation_type?: Maybe<EntityRelationType>;
    event_description?: Maybe<Scalars['String']>;
    event_name?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    new_value?: Maybe<Scalars['AWSJSON']>;
    prev_value?: Maybe<Scalars['AWSJSON']>;
    side_effect?: Maybe<Scalars['Boolean']>;
    trigger_at?: Maybe<Timestamp>;
    trigger_by?: Maybe<EventInitiator>;
    trigger_by_id?: Maybe<Scalars['String']>;
}

export class EventLogRecordInput {
    action: Scalars['String'];
    entity: EntityInput;
    event_description?: Maybe<Scalars['String']>;
    event_name: Scalars['String'];
    id?: Maybe<Scalars['String']>;
    new_value?: Maybe<Scalars['AWSJSON']>;
    prev_value?: Maybe<Scalars['AWSJSON']>;
    trigger_at: TimestampInput;
    trigger_by_id?: Maybe<Scalars['String']>;
}

export class EventLogRecordPage {
    __typename?: 'EventLogRecordPage';
    items?: Maybe<Array<EventLogRecord>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class FeatureAccessLockInput {
    /** status of the feature access */
    feature_status: FeatureAccessStatus;
    /** unique id of user */
    uid: Scalars['String'];
}

export enum FeatureAccessStatus {
    Locked = 'LOCKED',
    Unlocked = 'UNLOCKED',
}

export class FriendGroup {
    __typename?: 'FriendGroup';
    group_id: Scalars['String'];
    group_name?: Maybe<Scalars['String']>;
    players?: Maybe<Array<Scalars['String']>>;
}

export class FriendGroupFilter {
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class FriendGroupInput {
    group_id: Scalars['String'];
    group_name?: Maybe<Scalars['String']>;
    players?: Maybe<Array<Scalars['String']>>;
}

export class FriendGroupPage {
    __typename?: 'FriendGroupPage';
    items?: Maybe<Array<FriendGroup>>;
    total_count: Scalars['Int'];
}

export enum FriendsGroupUsersActionType {
    Added = 'ADDED',
    Removed = 'REMOVED',
}

export class Ggr {
    __typename?: 'GGR';
    casino_connector?: Maybe<Scalars['Float']>;
    casino_poker_flips?: Maybe<Scalars['Float']>;
    mtt_service_fee?: Maybe<Scalars['Float']>;
    poker_features_purchase?: Maybe<Scalars['Float']>;
    poker_guess_hand?: Maybe<Scalars['Float']>;
    poker_insurance?: Maybe<Scalars['Float']>;
    poker_rake?: Maybe<Scalars['Float']>;
}

export class GgrInput {
    casino_connector?: Maybe<Scalars['Float']>;
    casino_poker_flips?: Maybe<Scalars['Float']>;
    mtt_service_fee?: Maybe<Scalars['Float']>;
    poker_features_purchase?: Maybe<Scalars['Float']>;
    poker_guess_hand?: Maybe<Scalars['Float']>;
    poker_insurance?: Maybe<Scalars['Float']>;
    poker_rake?: Maybe<Scalars['Float']>;
}

export class GgrLifetime {
    __typename?: 'GGRLifetime';
    /** casino GGR */
    casino?: Maybe<Scalars['Float']>;
    /** poker GGR rake + fee */
    poker?: Maybe<Scalars['Float']>;
    /** sports GGR */
    sports?: Maybe<Scalars['Float']>;
}

export class GgrSummary {
    __typename?: 'GGRSummary';
    cash_ggr?: Maybe<Scalars['Float']>;
    casino_ggr?: Maybe<Scalars['Float']>;
    casino_share?: Maybe<Scalars['Float']>;
    external_casino_bet_amount?: Maybe<Scalars['Float']>;
    external_casino_ggr?: Maybe<Scalars['Float']>;
    global_spin_ggr?: Maybe<Scalars['Float']>;
    internal_casino_bet_amount?: Maybe<Scalars['Float']>;
    internal_casino_ggr?: Maybe<Scalars['Float']>;
    mtt_ggr?: Maybe<Scalars['Float']>;
    mtt_total_buyin?: Maybe<Scalars['Float']>;
    plo_ggr_high_stake?: Maybe<Scalars['Float']>;
    plo_ggr_low_stake?: Maybe<Scalars['Float']>;
    plo_ggr_medium_stake?: Maybe<Scalars['Float']>;
    plo_ggr_micro_stake?: Maybe<Scalars['Float']>;
    plo_ggr_total?: Maybe<Scalars['Float']>;
    plo_ggr_unknown_stake?: Maybe<Scalars['Float']>;
    poker_addons?: Maybe<Scalars['Float']>;
    poker_ggr?: Maybe<Scalars['Float']>;
    poker_mtt_fees?: Maybe<Scalars['Float']>;
    poker_rake?: Maybe<Scalars['Float']>;
    poker_share?: Maybe<Scalars['Float']>;
    total?: Maybe<Scalars['Float']>;
    total_share?: Maybe<Scalars['Float']>;
}

export class GameHistoryFilter {
    big_blind_size?: Maybe<NumRange>;
    game_type?: Maybe<Array<Maybe<GameType>>>;
    limit_size?: Maybe<NumRange>;
    player_action?: Maybe<Array<Maybe<ActionFilter>>>;
    pot_size?: Maybe<NumRange>;
    started_at?: Maybe<DateRange>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export enum GameMode {
    Match = 'MATCH',
    None = 'NONE',
    Normal = 'NORMAL',
    Other = 'OTHER',
    Short = 'SHORT',
}

export class GameTable {
    __typename?: 'GameTable';
    game_mode?: Maybe<GameMode>;
    game_type?: Maybe<GameType>;
    limit?: Maybe<Scalars['Float']>;
}

export class GameTableInput {
    game_mode?: Maybe<GameMode>;
    game_type?: Maybe<GameType>;
    limit?: Maybe<Scalars['Float']>;
}

export enum GameType {
    AofServer = 'AofServer',
    BetServer = 'BetServer',
    BlMtt = 'BlMtt',
    CowBoy = 'CowBoy',
    DataServer = 'DataServer',
    GameIdDummy = 'GameId_Dummy',
    HumanBoy = 'HumanBoy',
    Jackfruit = 'Jackfruit',
    PokerMaster = 'PokerMaster',
    StarSeat = 'StarSeat',
    Texas = 'Texas',
    VideoCowboy = 'VideoCowboy',
    World = 'World',
    ZoomTexas = 'ZoomTexas',
    ZoomTexasMax = 'ZoomTexasMax',
}

export enum Gender {
    Female = 'FEMALE',
    Male = 'MALE',
    Undefined = 'UNDEFINED',
}

export class GeoCheck {
    __typename?: 'GeoCheck';
    check_id?: Maybe<Scalars['String']>;
    connection_type?: Maybe<Scalars['String']>;
    correlation_id?: Maybe<Scalars['String']>;
    current_balance?: Maybe<Scalars['Float']>;
    device?: Maybe<Device>;
    error_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
    event_source: GeoCheckEventSource;
    id: Scalars['String'];
    ip?: Maybe<Scalars['String']>;
    ip_quality_score?: Maybe<Scalars['Float']>;
    isp?: Maybe<Scalars['String']>;
    language?: Maybe<Scalars['String']>;
    location?: Maybe<Location>;
    logged_at_ts?: Maybe<Timestamp>;
    login_status?: Maybe<LoginStatus>;
    login_type?: Maybe<LoginType>;
    reason_categories?: Maybe<Array<Maybe<Scalars['String']>>>;
    recaptcha_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
    recaptcha_score?: Maybe<Scalars['Float']>;
    session_id?: Maybe<Scalars['String']>;
    status?: Maybe<GeoCheckStatus>;
    uid: Scalars['String'];
    username?: Maybe<Scalars['String']>;
    vpn?: Maybe<Scalars['Boolean']>;
}

export enum GeoCheckEventSource {
    Geocomply = 'Geocomply',
    Login = 'Login',
}

export class GeoCheckFilter {
    logged_at_ts?: Maybe<DateRange>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class GeoCheckInput {
    check_id?: Maybe<Scalars['String']>;
    connection_type?: Maybe<Scalars['String']>;
    correlation_id?: Maybe<Scalars['String']>;
    current_balance?: Maybe<Scalars['Float']>;
    device?: Maybe<DeviceInput>;
    error_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
    event_source: GeoCheckEventSource;
    id: Scalars['String'];
    ip?: Maybe<Scalars['String']>;
    ip_quality_score?: Maybe<Scalars['Float']>;
    isp?: Maybe<Scalars['String']>;
    language?: Maybe<Scalars['String']>;
    location?: Maybe<LocationInput>;
    logged_at_ts?: Maybe<TimestampInput>;
    login_status?: Maybe<LoginStatus>;
    login_type?: Maybe<LoginType>;
    reason_categories?: Maybe<Array<Maybe<Scalars['String']>>>;
    recaptcha_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
    recaptcha_score?: Maybe<Scalars['Float']>;
    session_id?: Maybe<Scalars['String']>;
    status?: Maybe<GeoCheckStatus>;
    uid: Scalars['String'];
    username?: Maybe<Scalars['String']>;
    vpn?: Maybe<Scalars['Boolean']>;
}

export class GeoCheckPage {
    __typename?: 'GeoCheckPage';
    items?: Maybe<Array<Maybe<GeoCheck>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export enum GeoCheckStatus {
    Failed = 'Failed',
    Success = 'Success',
}

export class GeoCheckSummary {
    __typename?: 'GeoCheckSummary';
    fail_count?: Maybe<Scalars['Int']>;
    reason_categories?: Maybe<Array<Maybe<Scalars['String']>>>;
    status: GeoCheckStatus;
    uid: Scalars['String'];
}

export class GeoCheckSummaryInput {
    reason_categories?: Maybe<Array<Maybe<Scalars['String']>>>;
    status: GeoCheckStatus;
    uid: Scalars['String'];
}

export class GlobalSearchFilter {
    source?: Maybe<Array<GlobalSearchSource>>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class GlobalSearchRecord {
    __typename?: 'GlobalSearchRecord';
    key: Scalars['String'];
    meta?: Maybe<Array<Scalars['String']>>;
    source: GlobalSearchSource;
}

export enum GlobalSearchSource {
    PaymentMethod = 'PaymentMethod',
}

export class InitKycInput {
    /** list of reasons for initiating KYC case; optional for backward compatibility */
    reason_codes?: Maybe<Array<KycReasonCode>>;
    uid: Scalars['String'];
}

export enum JumioDocumentSubType {
    ConsularId = 'CONSULAR_ID',
    ElectoralId = 'ELECTORAL_ID',
    EPassport = 'E_PASSPORT',
    HealthId = 'HEALTH_ID',
    LearningDrivingLicense = 'LEARNING_DRIVING_LICENSE',
    MilitaryId = 'MILITARY_ID',
    NationalId = 'NATIONAL_ID',
    OtherId = 'OTHER_ID',
    PassportCardId = 'PASSPORT_CARD_ID',
    PublicSafetyId = 'PUBLIC_SAFETY_ID',
    RegularDrivingLicense = 'REGULAR_DRIVING_LICENSE',
    ResidentPermitId = 'RESIDENT_PERMIT_ID',
    StudentId = 'STUDENT_ID',
    TaxId = 'TAX_ID',
    Unknown = 'UNKNOWN',
    Visa = 'VISA',
}

export enum JumioDocumentType {
    CustomId = 'CUSTOM_ID',
    IdCard = 'ID_CARD',
    LicenseDriver = 'LICENSE_DRIVER',
    Passport = 'PASSPORT',
    ProofOfAddress = 'PROOF_OF_ADDRESS',
    ProofOfPayment = 'PROOF_OF_PAYMENT',
    Unsupported = 'UNSUPPORTED',
    Visa = 'VISA',
}

export class JumioLocation {
    __typename?: 'JumioLocation';
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    formatted?: Maybe<Scalars['String']>;
    line1?: Maybe<Scalars['String']>;
    line2?: Maybe<Scalars['String']>;
    postal_code?: Maybe<Scalars['String']>;
    subdivision?: Maybe<Scalars['String']>;
}

export class JumioLocationInput {
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    formatted?: Maybe<Scalars['String']>;
    line1?: Maybe<Scalars['String']>;
    line2?: Maybe<Scalars['String']>;
    postal_code?: Maybe<Scalars['String']>;
    subdivision?: Maybe<Scalars['String']>;
}

export class KycCheckResults {
    __typename?: 'KYCCheckResults';
    history?: Maybe<Array<Maybe<AccountVerification>>>;
    uid: Scalars['String'];
    username?: Maybe<Scalars['String']>;
}

export class KycCheckResultsFilter {
    doc_type?: Maybe<Array<Maybe<JumioDocumentType>>>;
    status?: Maybe<Array<Maybe<Scalars['String']>>>;
    submission_date_ts?: Maybe<DateRange>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class KycCheckResultsInput {
    history?: Maybe<Array<Maybe<AccountVerificationInput>>>;
    uid: Scalars['String'];
}

export enum KycCheckType {
    Jumio = 'JUMIO',
}

export class KycDocument {
    __typename?: 'KYCDocument';
    client_ip?: Maybe<Scalars['String']>;
    country_info?: Maybe<CountryInfo>;
    device_id?: Maybe<Scalars['String']>;
    device_verified?: Maybe<PlayerVerificationStatus>;
    did?: Maybe<Scalars['String']>;
    dob?: Maybe<Scalars['String']>;
    doc_id_sub_type?: Maybe<JumioDocumentSubType>;
    doc_ref_id?: Maybe<Scalars['String']>;
    doc_type?: Maybe<JumioDocumentType>;
    first_name?: Maybe<Scalars['String']>;
    gender?: Maybe<Scalars['String']>;
    geolocation_verified?: Maybe<PlayerVerificationStatus>;
    ip_verified?: Maybe<PlayerVerificationStatus>;
    last_name?: Maybe<Scalars['String']>;
    location?: Maybe<JumioLocation>;
    number?: Maybe<Scalars['String']>;
    status?: Maybe<KycStatus>;
    uploaded_ts?: Maybe<Timestamp>;
}

export class KycDocumentInput {
    client_ip?: Maybe<Scalars['String']>;
    country_info?: Maybe<CountryInfoInput>;
    device_id?: Maybe<Scalars['String']>;
    device_verified?: Maybe<PlayerVerificationStatus>;
    did?: Maybe<Scalars['String']>;
    dob?: Maybe<Scalars['String']>;
    doc_id_sub_type?: Maybe<JumioDocumentSubType>;
    doc_ref_id?: Maybe<Scalars['String']>;
    doc_type?: Maybe<JumioDocumentType>;
    first_name?: Maybe<Scalars['String']>;
    gender?: Maybe<Scalars['String']>;
    geolocation_verified?: Maybe<PlayerVerificationStatus>;
    ip_verified?: Maybe<PlayerVerificationStatus>;
    last_name?: Maybe<Scalars['String']>;
    location?: Maybe<JumioLocationInput>;
    number?: Maybe<Scalars['String']>;
    status?: Maybe<KycStatus>;
    uploaded_ts?: Maybe<TimestampInput>;
}

export enum KycReasonCode {
    CcDep = 'CCDep',
    Hrj = 'HRJ',
    NoEmail = 'NoEmail',
    Others = 'Others',
    PePs = 'PEPs',
    Payment = 'Payment',
    Proac = 'Proac',
    Referral = 'Referral',
    Security = 'Security',
}

export enum KycStatus {
    Failed = 'FAILED',
    None = 'NONE',
    Pending = 'PENDING',
    Successful = 'SUCCESSFUL',
}

export class KycStatusInput {
    id: Scalars['String'];
    status: AccountVerificationStatus;
}

export class Label {
    __typename?: 'Label';
    id: Scalars['String'];
    name: Scalars['String'];
}

export class LabelGroup {
    __typename?: 'LabelGroup';
    color: Scalars['String'];
    id: Scalars['String'];
    labels?: Maybe<Array<Label>>;
    name: Scalars['String'];
    source_type: LabelGroupSourceType;
}

export class LabelGroupFilter {
    source_type?: Maybe<Array<Maybe<LabelGroupSourceType>>>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class LabelGroupInput {
    color: Scalars['String'];
    name: Scalars['String'];
    source_type: LabelGroupSourceType;
}

export class LabelGroupPage {
    __typename?: 'LabelGroupPage';
    items?: Maybe<Array<LabelGroup>>;
    total_count?: Maybe<Scalars['Int']>;
}

export enum LabelGroupSourceType {
    Custom = 'Custom',
    System = 'System',
}

export class LabelInput {
    group_id: Scalars['String'];
    name: Scalars['String'];
}

export enum LimitDurationType {
    Daily = 'DAILY',
    Monthly = 'MONTHLY',
    Weekly = 'WEEKLY',
}

export class Location {
    __typename?: 'Location';
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    state?: Maybe<Scalars['String']>;
}

export class LocationInput {
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    state?: Maybe<Scalars['String']>;
}

export enum LoginStatus {
    Failed = 'Failed',
    Success = 'Success',
}

export enum LoginType {
    App = 'App',
    Web = 'Web',
}

export class LogoutInfo {
    __typename?: 'LogoutInfo';
    logged_out_at_ts?: Maybe<Timestamp>;
}

export class ManualTransaction {
    __typename?: 'ManualTransaction';
    amount: Scalars['Float'];
    created_by_uid: Scalars['String'];
    currency?: Maybe<Scalars['String']>;
    payment_method_description?: Maybe<Scalars['String']>;
    reasons: Array<Reason>;
    transaction_id: Scalars['String'];
    type: TransactionType;
    uid: Scalars['String'];
}

export class ManualTransactionInput {
    amount: Scalars['Float'];
    created_by_uid: Scalars['String'];
    currency?: Maybe<Scalars['String']>;
    /** unique identifier to guarantee operation idempotence; optional for backward compatibility */
    id?: Maybe<Scalars['String']>;
    note?: Maybe<NoteInput>;
    payment_method_description?: Maybe<Scalars['String']>;
    reasons: Array<ReasonInput>;
    type: TransactionType;
    uid: Scalars['String'];
}

/** marketing_code and type are used as key */
export class MarketingCode {
    __typename?: 'MarketingCode';
    /** BO user id who added code */
    added_by_uid: Scalars['String'];
    btag?: Maybe<Scalars['String']>;
    created_at: Timestamp;
    /** marketing code which players enter to get bonus */
    marketing_code: Scalars['String'];
    /** player id of agent who is the owner of Team code */
    referrer_id?: Maybe<Scalars['String']>;
    type: BonusType;
}

export class MarketingCodeDeleteInput {
    btag?: Maybe<Scalars['String']>;
    marketing_code: Scalars['String'];
    type: BonusType;
}

export class MarketingCodeFilterInput {
    text?: Maybe<Array<Maybe<TextFilter>>>;
    type?: Maybe<Array<BonusType>>;
}

export class MarketingCodeInput {
    added_by_uid: Scalars['String'];
    btag?: Maybe<Scalars['String']>;
    created_at: TimestampInput;
    marketing_code: Scalars['String'];
    /** player id of agent who is the owner of Team code */
    referrer_id?: Maybe<Scalars['String']>;
    type: BonusType;
}

export class MarketingCodesPage {
    __typename?: 'MarketingCodesPage';
    items?: Maybe<Array<MarketingCode>>;
    total_count?: Maybe<Scalars['Int']>;
}

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export class Mutation {
    __typename?: 'Mutation';
    addAccountVerification?: Maybe<AccountVerification>;
    addAdminAction?: Maybe<UserAction>;
    addAdminFriendGroup?: Maybe<FriendGroup>;
    addAgentProfile?: Maybe<Scalars['String']>;
    addAggregatedFinanceSummary?: Maybe<Array<Maybe<AggregatedFinanceSummary>>>;
    addAmlLabels?: Maybe<UserProfile>;
    addBackpackItem?: Maybe<BackpackItem>;
    /**
     * creates new bonus in Bonus Engine. Used for all types of bonuses
     *
     * ### Policies: ###
     *
     * auth token contains `entity-bonus` OR `entity-bonus:update`
     */
    addBonusCode?: Maybe<BonusCodeDetails>;
    addBonusCodeHistory?: Maybe<BonusCodeHistory>;
    addBotGroup?: Maybe<BotGroup>;
    addDetectedBotGroup?: Maybe<BotGroup>;
    addEventLogRecord?: Maybe<EventLogRecord>;
    addGeoCheck?: Maybe<GeoCheck>;
    addGeoCheckSummary?: Maybe<GeoCheckSummary>;
    addKYCResult?: Maybe<KycCheckResults>;
    addLabel?: Maybe<LabelGroup>;
    addLabelGroup?: Maybe<LabelGroup>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    addManualTransaction?: Maybe<ManualTransaction>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    addManualTransactions?: Maybe<BulkMutationResponse>;
    /**
     * creates new marketing code which players can enter in the app to get bonus. Returns added marketing code
     *
     * ### Policies: ###
     *
     * auth token has `entity-bonus:create`
     */
    addMarketingCode?: Maybe<MarketingCode>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` apply for items related to South Korea
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` apply for items related to Philippines
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` apply for items related to all countries except South Korea and Philippines
     */
    addNote?: Maybe<Note>;
    /**
     * creates the list of P2P Transfer transactions.
     *
     * ### Policies: ###
     *
     * auth token contains `entity-transaction:p2p-transfer:create`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    addP2PTransferBulkOperation?: Maybe<BulkMutationResponse>;
    addP2PTransferTransaction?: Maybe<P2PTransferResponse>;
    addPaymentDetails?: Maybe<PaymentStatus>;
    addPlayerBonus?: Maybe<PlayerBonus>;
    addPlayerReferral?: Maybe<PlayerReferral>;
    addPlayerReferrer?: Maybe<PlayerReferrerSummary>;
    /**
     * adds a referrer summary info of a player
     *
     * ### Policies: ###
     *
     * auth token has `entity-all` or `entity-all:update`
     */
    addPlayerReferrerSummary?: Maybe<PlayerReferrerSummary>;
    addPlayerRelations?: Maybe<Array<Maybe<PlayersRelation>>>;
    addPokerStatistics?: Maybe<Array<Maybe<UserStatistics>>>;
    addRGUserLimit?: Maybe<RgUserLimits>;
    /**
     * adds a referrer of a player
     *
     * ### Policies: ###
     *
     * auth token has `entity-all` or `entity-all:update`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    addReferrer?: Maybe<PlayerReferral>;
    addUser?: Maybe<UserProfile>;
    addUserBlackList?: Maybe<UserProfile>;
    addUserBlockedStakesAccess?: Maybe<UserProfile>;
    addUserCountry?: Maybe<Scalars['String']>;
    addUserDevice?: Maybe<Device>;
    addUserGame?: Maybe<UserGame>;
    addUserIp?: Maybe<Scalars['String']>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    addUserLabels?: Maybe<UserProfile>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    addUserLabelsOperation?: Maybe<BulkMutationResponse>;
    addUserLogin?: Maybe<UserLogin>;
    addUserLogout?: Maybe<UserLogout>;
    addUserReCaptchaScore?: Maybe<UserReCaptchaScore>;
    addUserSecurityCase?: Maybe<UserProfile>;
    addUserSecurityLimit?: Maybe<UserProfile>;
    addUserTransaction?: Maybe<Transaction>;
    addUserVpn?: Maybe<Scalars['Boolean']>;
    /**
     * assigns clubs for a user
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all` OR `entity-all:update`
     */
    assignUserClubs?: Maybe<Array<Maybe<UserClub>>>;
    /**
     * creates Revenue Share transaction
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token (contains `entity-all:update` OR `entity-transaction:revenue-share:update`) AND (doesn’t contain `agent:read` AND `agent:update`)
     *
     * ** For agents: ** auth token contains (`entity-all:update` OR `entity-transaction:revenue-share:update`) AND (`agent:read` OR `agent:update`) AND (`uid` param is linked to `sub` from Auth token)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    claimRevenueShare?: Maybe<RevenueShareClaimResult>;
    /**
     * deactivates and blocks the bonus from further use
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-bonus` OR `entity-bonus:update`
     *
     * ** For agents **: not allowed
     */
    deactivateBonus?: Maybe<BonusCodeDetails>;
    deleteBotGroup?: Maybe<BotGroup>;
    /**
     * removes the list of marketing codes. Returns deleted codes
     *
     * ### Policies: ###
     *
     * auth token has `entity-bonus:delete`
     */
    deleteMarketingCodes?: Maybe<Array<Maybe<MarketingCode>>>;
    deletePlayerRelation?: Maybe<PlayersRelation>;
    deletePokerStatistics?: Maybe<UserStatistics>;
    deleteUser?: Maybe<UserProfile>;
    deleteUserBlackList?: Maybe<UserProfile>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    deleteUserLabel?: Maybe<UserProfile>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    deleteUserLabelsOperation?: Maybe<BulkMutationResponse>;
    deleteUserSecurityCase?: Maybe<UserProfile>;
    /**
     * Force expire cash bonus. It sends request to Bonus Engine to force expire player cash bonus.
     * When successful result received form Bonus Engine need to update PlayerBonus and add new EventLogRecord
     * with event_name `ForceExpireCashBonus` and bonus status in prev_value and next_value
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all` OR `entity-all:update`
     *
     * ** For agents **: not allowed
     */
    forceExpireCashBonus?: Maybe<PlayerBonus>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    initNdrpKYC?: Maybe<AccountVerification>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    initPaymentKYC?: Maybe<AccountVerification>;
    /**
     * init list of payment kyc cases
     *
     * ### Policies: ###
     *
     * auth token contains `entity-all` OR `entity-all:update`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    initPaymentKYCBulkOperation?: Maybe<BulkMutationResponse>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    initSecurityKYC?: Maybe<AccountVerification>;
    /**
     * lock and unlock casino games access for players
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `action-center` OR `action-center:update` OR `action-center:games` OR `action-center:games:update`
     *
     * ** For agents: ** auth token contains `agent:update` AND (`action-center` OR `action-center:update` OR `action-center:games` OR `action-center:games:update`) AND (player is an agent's direct referee)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    lockCasino?: Maybe<UserProfile>;
    /**
     * lock and unlock casino games access for multiple players
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `action-center` OR `action-center:update` OR `action-center:games` OR `action-center:games:update`
     *
     * ** For agents: ** auth token contains `agent:update` AND (`action-center` OR `action-center:update` OR `action-center:games` OR `action-center:games:update`) AND (player is an agent's direct referee)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    lockCasinoBulkOperation?: Maybe<BulkMutationResponse>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    lockDeposit?: Maybe<UserProfile>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `action-center` OR `action-center:update` OR `action-center:p2p` OR `action-center:p2p:update`
     *
     * ** For agents: ** auth token contains `agent:update` AND (`action-center` OR `action-center:update` OR `action-center:p2p` OR `action-center:p2p:update`) AND (player is an agent's direct referee)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    lockP2pBulkOperation?: Maybe<BulkMutationResponse>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `action-center` OR `action-center:update` OR `action-center:p2p` OR `action-center:p2p:update`
     *
     * ** For agents: ** auth token contains `agent:update` AND (`action-center` OR `action-center:update` OR `action-center:p2p` OR `action-center:p2p:update`) AND (player is an agent's direct referee)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    lockP2pFeature?: Maybe<UserProfile>;
    /**
     * lock and unlock sportsbook transaction access for players
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `action-center` OR `action-center:update` OR `action-center:games` OR `action-center:games:update`
     *
     * ** For agents: ** auth token contains `agent:update` AND (`action-center` OR `action-center:update` OR `action-center:games` OR `action-center:games:update`) AND (player is an agent's direct referee)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     *
     * if auth token contains `internal` OR `internal:update` mutation available for all players
     */
    lockSportsbook?: Maybe<UserProfile>;
    /**
     * lock and unlock sportsbook transaction for multiple players
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `action-center` OR `action-center:update` OR `action-center:games` OR `action-center:games:update`
     *
     * ** For agents: ** auth token contains `agent:update` AND (`action-center` OR `action-center:update` OR `action-center:games` OR `action-center:games:update`) AND (player is an agent's direct referee)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     *
     * if auth token contains `internal` OR `internal:update` mutation available for all players
     */
    lockSportsbookBulkOperation?: Maybe<BulkMutationResponse>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    lockWithdrawal?: Maybe<UserProfile>;
    removeAmlLabels?: Maybe<UserProfile>;
    /**
     * removes a referrer of a player
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all` OR `entity-all:update` AND doesn’t contain `agent` AND `agent:update`
     *
     * ** For agents: ** auth token contains `agent` OR `agent:update` AND player is an agent's direct referee
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    removeReferrer?: Maybe<PlayerReferral>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    sendMessage?: Maybe<PlayerMessage>;
    testTimeout?: Maybe<Scalars['String']>;
    triggerCasino?: Maybe<CasinoResponse>;
    /**
     * triggers the bonus for a single player
     *
     * ### Policies: ###
     *
     * auth token contains `entity-bonus` OR `entity-bonus:update`
     */
    triggerPlayerBonus?: Maybe<TriggerOutput>;
    /**
     * triggers the bonus for the list of players
     *
     * ### Policies: ###
     *
     * auth token contains `entity-bonus` OR `entity-bonus:update`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    triggerPlayerBonuses?: Maybe<BulkMutationResponse>;
    /**
     * unassigns clubs from a user
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all` OR `entity-all:update`
     */
    unassignUserClubs?: Maybe<Array<Maybe<UserClub>>>;
    updateAccountVerification?: Maybe<AccountVerification>;
    updateAdminFriendGroup?: Maybe<FriendGroup>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    updateAffiliateBtag?: Maybe<UserProfile>;
    updateAggregatedFinanceSummary?: Maybe<AggregatedFinanceSummary>;
    updateAggregatedLifetimeBonus?: Maybe<Scalars['Float']>;
    updateBackpackItem?: Maybe<BackpackItem>;
    /**
     * updates default referrer settings for regular players
     *
     * ### Policies: ###
     *
     * auth token has `entity-all` or `entity-all:update`
     */
    updateDefaultPlayerReferralSettings?: Maybe<ReferralSettings>;
    updateGeoCheck?: Maybe<GeoCheck>;
    updateGeoCheckSummary?: Maybe<GeoCheckSummary>;
    /**
     * update list of kyc cases
     *
     * ### Policies: ###
     *
     * auth token contains `entity-all` OR `entity-all:update`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    updateKYCBulkOperation?: Maybe<BulkMutationResponse>;
    updateKYCResult?: Maybe<KycCheckResults>;
    updateLabel?: Maybe<LabelGroup>;
    updateLabelGroup?: Maybe<LabelGroup>;
    /**
     * updates the status of NDRP case (only **Approved** and **DeniedNdrpKYC** statuses are allowed)
     * - `kyc_case_id`: The unique identifier for the KYC case.
     * - `status`: The new status for the account verification.
     * - `auto_update`: Flag to indicate if the update should be automatic.
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    updateNdrpKYC?: Maybe<AccountVerification>;
    /**
     * updates a note
     *
     * ### Policies: ###
     *
     * auth token has `entity-all` or `entity-all:update`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` apply for items related to South Korea
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` apply for items related to Philippines
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` apply for items related to all countries except South Korea and Philippines
     */
    updateNote?: Maybe<Note>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    updatePaymentKYC?: Maybe<AccountVerification>;
    updatePlayerBalanceSummary?: Maybe<PlayerBalanceSummary>;
    updatePlayerBonus?: Maybe<PlayerBonus>;
    updatePlayerFinanceSummary?: Maybe<UserProfile>;
    updatePlayerReferral?: Maybe<PlayerReferral>;
    updatePlayerReferrer?: Maybe<PlayerReferrerSummary>;
    /**
     * updates a referrer summary info of a player
     *
     * ### Policies: ###
     *
     * auth token has `entity-all` or `entity-all:update`
     */
    updatePlayerReferrerSummary?: Maybe<PlayerReferrerSummary>;
    updateRGUserLimit?: Maybe<RgUserLimits>;
    updateReferralSettings?: Maybe<ReferralSettings>;
    /**
     * updates a referrer of a player
     *
     * ### Policies: ###
     *
     * auth token has `entity-all` or `entity-all:update`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    updateReferrer?: Maybe<PlayerReferral>;
    /**
     * updates referral settings (revenue share, expiration date) for regular players only
     *
     * ### Policies: ###
     *
     * auth token has `entity-all` or `entity-all:update`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    updateRegularPlayerReferralSettings?: Maybe<PlayerReferral>;
    updateReputationScores?: Maybe<Array<Maybe<UserReputationScore>>>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    updateSecurityKYC?: Maybe<AccountVerification>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:update` mutation available for KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:update` mutation available for PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:update` mutation available for not KR and not PH players
     */
    updateUser?: Maybe<UserProfile>;
    updateUser2FA?: Maybe<UserProfile>;
    updateUserChallengeQuestion?: Maybe<UserProfile>;
    updateUserIdentity?: Maybe<UserProfile>;
    updateUserLogin?: Maybe<UserLogin>;
    updateUserSecurityLimit?: Maybe<UserProfile>;
    updateUserTransaction?: Maybe<Transaction>;
    updateUsersBiggestValues?: Maybe<Array<Maybe<UserBiggestValues>>>;
    upsertBonusCode?: Maybe<BonusCodeDetails>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all` OR `entity-all:update`
     */
    upsertPageView?: Maybe<PageView>;
    validateUsersBets?: Maybe<Scalars['Boolean']>;
}

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddAccountVerificationArgs = {
    account_verification: AccountVerificationInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddAdminActionArgs = {
    admin_action?: Maybe<UserActionInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddAdminFriendGroupArgs = {
    friend_group: FriendGroupInput;
    group_id: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddAgentProfileArgs = {
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddAggregatedFinanceSummaryArgs = {
    finance_summary?: Maybe<Array<AggregatedFinanceSummaryInput>>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddAmlLabelsArgs = {
    aml_labels?: Maybe<Array<AmlLabel>>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddBackpackItemArgs = {
    backpack_item: BackpackItemInput;
    bonus_id: Scalars['String'];
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddBonusCodeArgs = {
    bonus?: Maybe<BonusCodeInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddBonusCodeHistoryArgs = {
    bonus_history?: Maybe<BonusCodeHistoryInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddBotGroupArgs = {
    bot_group: BotGroupInput;
    group_id: Scalars['String'];
    players_group: PlayersGroup;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddDetectedBotGroupArgs = {
    bot_group: BotGroupInput;
    group_id: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddEventLogRecordArgs = {
    event_log?: Maybe<EventLogRecordInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddGeoCheckArgs = {
    geoCheck: GeoCheckInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddGeoCheckSummaryArgs = {
    geoCheck: GeoCheckSummaryInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddKycResultArgs = {
    kyc: KycCheckResultsInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddLabelArgs = {
    label: LabelInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddLabelGroupArgs = {
    label_group: LabelGroupInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddManualTransactionArgs = {
    transaction: ManualTransactionInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddManualTransactionsArgs = {
    bulkData: BulkMutationInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddMarketingCodeArgs = {
    marketing_code: MarketingCodeInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddNoteArgs = {
    note: NoteInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddP2PTransferBulkOperationArgs = {
    bulkData: BulkMutationInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddP2PTransferTransactionArgs = {
    p2p_transfer: P2PTransferInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddPaymentDetailsArgs = {
    data?: Maybe<Array<PaymentDetailsInput>>;
    endTime: Scalars['String'];
    startTime: Scalars['String'];
    type: Scalars['Int'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddPlayerBonusArgs = {
    bonus_id: Scalars['String'];
    player_bonus: PlayerBonusInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddPlayerReferralArgs = {
    referral?: Maybe<PlayerReferralInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddPlayerReferrerArgs = {
    invite_code?: Maybe<Scalars['String']>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddPlayerReferrerSummaryArgs = {
    invite_code?: Maybe<Scalars['String']>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddPlayerRelationsArgs = {
    player_relations?: Maybe<Array<PlayersRelationInput>>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddPokerStatisticsArgs = {
    poker_statistics?: Maybe<Array<UserStatisticsInput>>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddRgUserLimitArgs = {
    rgLimit?: Maybe<RgUserLimitsInput>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddReferrerArgs = {
    referral?: Maybe<PlayerReferralInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserArgs = {
    uid: Scalars['String'];
    user: UserProfileInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserBlackListArgs = {
    black_list_item: BlackListItemInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserBlockedStakesAccessArgs = {
    blocked_stakes_access?: Maybe<Array<GameTableInput>>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserCountryArgs = {
    country: Scalars['String'];
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserDeviceArgs = {
    device: DeviceInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserGameArgs = {
    uid: Scalars['String'];
    userGame?: Maybe<UserGameInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserIpArgs = {
    ip: Scalars['String'];
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserLabelsArgs = {
    input: AddUserLabelInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserLabelsOperationArgs = {
    bulkData: BulkMutationInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserLoginArgs = {
    login: UserLoginInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserLogoutArgs = {
    logout: UserLogoutInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserReCaptchaScoreArgs = {
    score?: Maybe<UserReCaptchaScoreInput>;
    uid?: Maybe<Scalars['String']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserSecurityCaseArgs = {
    security_case: SecurityCaseInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserSecurityLimitArgs = {
    uid: Scalars['String'];
    withdrawal_limit: SecurityLimitHistoryInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserTransactionArgs = {
    transaction: TransactionInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAddUserVpnArgs = {
    uid: Scalars['String'];
    vpn: Scalars['Boolean'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationAssignUserClubsArgs = {
    club_ids?: Maybe<Array<Scalars['String']>>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationClaimRevenueShareArgs = {
    claim: RevenueShareClaimInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationDeactivateBonusArgs = {
    bonus_id: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationDeleteBotGroupArgs = {
    bot_group: BotGroupInput;
    group_id: Scalars['String'];
    players_group: PlayersGroup;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationDeleteMarketingCodesArgs = {
    codes: Array<MarketingCodeDeleteInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationDeletePlayerRelationArgs = {
    related_player: PlayerUidInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationDeletePokerStatisticsArgs = {
    poker_statistics: PlayerUidInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationDeleteUserArgs = {
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationDeleteUserBlackListArgs = {
    black_list_item: BlackListItemInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationDeleteUserLabelArgs = {
    input: DeleteUserLabelInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationDeleteUserLabelsOperationArgs = {
    bulkData: BulkMutationInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationDeleteUserSecurityCaseArgs = {
    security_case: SecurityCaseInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationForceExpireCashBonusArgs = {
    bonus_id: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationInitNdrpKycArgs = {
    kyc_case_id: Scalars['String'];
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationInitPaymentKycArgs = {
    kyc_input?: Maybe<InitKycInput>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationInitPaymentKycBulkOperationArgs = {
    bulkData: BulkMutationInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationInitSecurityKycArgs = {
    status?: Maybe<AccountVerificationStatus>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationLockCasinoArgs = {
    casino_lock: FeatureAccessLockInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationLockCasinoBulkOperationArgs = {
    bulkData: BulkMutationInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationLockDepositArgs = {
    deposit_lock: FeatureAccessLockInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationLockP2pBulkOperationArgs = {
    bulkData: BulkMutationInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationLockP2pFeatureArgs = {
    p2p_lock: FeatureAccessLockInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationLockSportsbookArgs = {
    sportsbook_lock: FeatureAccessLockInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationLockSportsbookBulkOperationArgs = {
    bulkData: BulkMutationInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationLockWithdrawalArgs = {
    uid: Scalars['String'];
    withdrawal_lock: FeatureAccessLockInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationRemoveAmlLabelsArgs = {
    aml_labels?: Maybe<Array<AmlLabel>>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationRemoveReferrerArgs = {
    referral?: Maybe<RemovePlayerReferralInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationSendMessageArgs = {
    message: PlayerMessageInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationTestTimeoutArgs = {
    timeout_sec: Scalars['Int'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationTriggerCasinoArgs = {
    payload: CasinoTriggerInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationTriggerPlayerBonusArgs = {
    bonus: PlayerBonusTriggerInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationTriggerPlayerBonusesArgs = {
    bulkData: BulkMutationInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUnassignUserClubsArgs = {
    club_ids?: Maybe<Array<Scalars['String']>>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateAccountVerificationArgs = {
    account_verification: AccountVerificationInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateAdminFriendGroupArgs = {
    action_type: FriendsGroupUsersActionType;
    friend_group: FriendGroupInput;
    group_id: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateAffiliateBtagArgs = {
    affiliate: AffiliateInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateAggregatedFinanceSummaryArgs = {
    transaction: TransactionInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateAggregatedLifetimeBonusArgs = {
    created_at: TimestampInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateBackpackItemArgs = {
    backpack_item: BackpackItemInput;
    bonus_id: Scalars['String'];
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateDefaultPlayerReferralSettingsArgs = {
    referralSettings: ReferralSettingsInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateGeoCheckArgs = {
    geoCheck: GeoCheckInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateGeoCheckSummaryArgs = {
    geoCheck: GeoCheckSummaryInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateKycBulkOperationArgs = {
    bulkData: BulkMutationInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateKycResultArgs = {
    kyc: KycCheckResultsInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateLabelArgs = {
    id: Scalars['String'];
    label: LabelInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateLabelGroupArgs = {
    id: Scalars['String'];
    label_group: LabelGroupInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateNdrpKycArgs = {
    auto_update?: Maybe<Scalars['Boolean']>;
    kyc_case_id: Scalars['String'];
    status: AccountVerificationStatus;
    uid?: Maybe<Scalars['String']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateNoteArgs = {
    id: Scalars['String'];
    note: NoteInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdatePaymentKycArgs = {
    id?: Maybe<Scalars['String']>;
    reason_codes?: Maybe<Array<Maybe<KycReasonCode>>>;
    status?: Maybe<AccountVerificationStatus>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdatePlayerBalanceSummaryArgs = {
    login?: Maybe<UserLoginInput>;
    transaction?: Maybe<TransactionInput>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdatePlayerBonusArgs = {
    bonus_id: Scalars['String'];
    player_bonus: PlayerBonusInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdatePlayerFinanceSummaryArgs = {
    transaction: TransactionInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdatePlayerReferralArgs = {
    referral?: Maybe<ReferralUpdateInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdatePlayerReferrerArgs = {
    referrer?: Maybe<ReferralUpdateInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdatePlayerReferrerSummaryArgs = {
    referrer?: Maybe<ReferralUpdateInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateRgUserLimitArgs = {
    rgLimit?: Maybe<RgUserLimitsInput>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateReferralSettingsArgs = {
    referralSettings: ReferralSettingsInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateReferrerArgs = {
    referral?: Maybe<PlayerReferralInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateRegularPlayerReferralSettingsArgs = {
    referral?: Maybe<ReferralUpdateInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateReputationScoresArgs = {
    reputation_scores: Array<Maybe<UserReputationScoreInput>>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateSecurityKycArgs = {
    id?: Maybe<Scalars['String']>;
    status?: Maybe<AccountVerificationStatus>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateUserArgs = {
    uid: Scalars['String'];
    user: UserProfileInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateUser2FaArgs = {
    twofa?: Maybe<User2FaInput>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateUserChallengeQuestionArgs = {
    question?: Maybe<UserChallengeQuestionInput>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateUserIdentityArgs = {
    identity: UserIdentityInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateUserLoginArgs = {
    login: UserLoginInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateUserSecurityLimitArgs = {
    uid: Scalars['String'];
    withdrawal_limit: SecurityLimitHistoryInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateUserTransactionArgs = {
    transaction: TransactionInput;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpdateUsersBiggestValuesArgs = {
    users_biggest_values?: Maybe<Array<Maybe<UserBiggestValuesInput>>>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpsertBonusCodeArgs = {
    bonus: BonusCodeUpsert;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationUpsertPageViewArgs = {
    pageView: PageViewInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:update` all mutations available
 */
export type MutationValidateUsersBetsArgs = {
    users_bets?: Maybe<Array<UserBetInput>>;
};

export class NgrSummary {
    __typename?: 'NGRSummary';
    cash_ngr?: Maybe<Scalars['Float']>;
    casino_ngr?: Maybe<Scalars['Float']>;
    mtt_ngr?: Maybe<Scalars['Float']>;
    poker_ngr?: Maybe<Scalars['Float']>;
    total?: Maybe<Scalars['Float']>;
}

export class Note {
    __typename?: 'Note';
    attachments?: Maybe<Array<Maybe<Attachment>>>;
    body: Scalars['String'];
    entity: Entity;
    id: Scalars['String'];
    /**
     * shows if note is pinned or not
     *
     * default value: false
     */
    is_pinned?: Maybe<Scalars['Boolean']>;
    note_type: NoteType;
    posted_at: Timestamp;
    posted_by_uid: Scalars['String'];
    users_tagged?: Maybe<Array<Scalars['String']>>;
    workspace: Workspace;
}

export class NoteInput {
    attachments?: Maybe<Array<Maybe<AttachmentInput>>>;
    body: Scalars['String'];
    entity: EntityInput;
    /**
     * shows if note is pinned or not
     *
     * default value: false
     */
    is_pinned?: Maybe<Scalars['Boolean']>;
    note_type: NoteType;
    posted_at: TimestampInput;
    posted_by_uid: Scalars['String'];
    users_tagged?: Maybe<Array<Scalars['String']>>;
    workspace: Workspace;
}

export enum NoteType {
    Global = 'Global',
    Request = 'Request',
}

export class NotesFilterInput {
    /** filter by entity and parent entity id should be supported */
    entity?: Maybe<EntityInput>;
    /** filter to fetch all vs pinned/unpinned notes */
    is_pinned?: Maybe<Scalars['Boolean']>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
    /** filter type to fetch all vs record specific notes */
    type: NotesFilterType;
    /** filter by one of the workspace for which note was added */
    workspace?: Maybe<Array<Workspace>>;
}

export enum NotesFilterType {
    AllRecords = 'AllRecords',
    Specific = 'Specific',
}

export class NotesPage {
    __typename?: 'NotesPage';
    items?: Maybe<Array<Note>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class NumRange {
    max?: Maybe<Scalars['Int']>;
    min?: Maybe<Scalars['Int']>;
}

export class OtherSummary {
    __typename?: 'OtherSummary';
    cost_tournament_overlay_excl_freeroll?: Maybe<Scalars['Float']>;
}

export class P2PTransferInput {
    amount: Scalars['Float'];
    created_by_uid?: Maybe<Scalars['String']>;
    type: TransactionType;
    uid: Scalars['String'];
}

export class P2PTransferResponse {
    __typename?: 'P2PTransferResponse';
    success: Scalars['Boolean'];
}

export class PageView {
    __typename?: 'PageView';
    last_opened_at: Timestamp;
    page_id: Scalars['String'];
    user_id: Scalars['String'];
}

export class PageViewFilter {
    last_opened_at?: Maybe<DateRange>;
    page_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export class PageViewInput {
    last_opened_at: TimestampInput;
    page_id: Scalars['String'];
}

export class PaymentDetailsInput {
    amount: Scalars['Float'];
    bank_card?: Maybe<Scalars['String']>;
    bank_card_name?: Maybe<Scalars['String']>;
    cancel_default?: Maybe<Scalars['Int']>;
    change_amount?: Maybe<Scalars['Int']>;
    createtime: Scalars['String'];
    match_id?: Maybe<Scalars['Int']>;
    match_status?: Maybe<Scalars['Int']>;
    plat?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['Int']>;
    successtime: Scalars['String'];
    uid: Scalars['Long'];
    vip_level: Scalars['Int'];
}

export enum PaymentOption {
    PaymentOptionAstropaycard = 'PaymentOptionAstropaycard',
    PaymentOptionBankintl = 'PaymentOptionBankintl',
    PaymentOptionBanklocal = 'PaymentOptionBanklocal',
    PaymentOptionCreditcard = 'PaymentOptionCreditcard',
    PaymentOptionCryptocurrency = 'PaymentOptionCryptocurrency',
    PaymentOptionDebitcard = 'PaymentOptionDebitcard',
    PaymentOptionFastbankttransfer = 'PaymentOptionFastbankttransfer',
    PaymentOptionNeteller = 'PaymentOptionNeteller',
    PaymentOptionNone = 'PaymentOptionNone',
    PaymentOptionPaypal = 'PaymentOptionPaypal',
    PaymentOptionQiwi = 'PaymentOptionQiwi',
    PaymentOptionSkrill = 'PaymentOptionSkrill',
    PaymentOptionYandex = 'PaymentOptionYandex',
}

export class PaymentStatus {
    __typename?: 'PaymentStatus';
    msg: Scalars['String'];
    status: Scalars['Int'];
}

export enum PaymentSystem {
    Hexopay = 'Hexopay',
    None = 'None',
    Nuvei = 'Nuvei',
    PayPal = 'PayPal',
    PremierCashier = 'PremierCashier',
}

export enum PaymentVendor {
    DevCode = 'DevCode',
    Hexopay = 'Hexopay',
    Intech = 'Intech',
    None = 'None',
    Nuvei = 'Nuvei',
    PayPal = 'PayPal',
    PremierCashier = 'PremierCashier',
}

export class PhoneNumber {
    __typename?: 'PhoneNumber';
    area?: Maybe<Scalars['String']>;
    full_number?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
}

export class PhoneNumberInput {
    area?: Maybe<Scalars['String']>;
    full_number?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
}

export enum Platform {
    Bl = 'BL',
    Im = 'IM',
    Meme = 'MEME',
    Mt = 'MT',
    Nj = 'NJ',
    None = 'NONE',
    Pkw = 'PKW',
    Wpk = 'WPK',
    Wpto = 'WPTO',
}

export class PlayerBalanceSummary {
    __typename?: 'PlayerBalanceSummary';
    balance?: Maybe<Scalars['Float']>;
    generated_rake?: Maybe<Scalars['Float']>;
    payment_methods?: Maybe<Array<Maybe<PaymentSystem>>>;
}

export class PlayerBalanceSummaryInput {
    balance?: Maybe<Scalars['Float']>;
    generated_rake?: Maybe<Scalars['Float']>;
    payment_methods?: Maybe<Array<Maybe<PaymentSystem>>>;
}

export class PlayerBonus {
    __typename?: 'PlayerBonus';
    bonus_engine_code: Scalars['String'];
    bonus_id: Scalars['String'];
    bonus_name?: Maybe<Scalars['String']>;
    bonus_status: PlayerBonusStatus;
    created_at: Timestamp;
    desc?: Maybe<Scalars['String']>;
    expire_at: Timestamp;
    marketing_code?: Maybe<Scalars['String']>;
    realized_amount?: Maybe<Scalars['Float']>;
    total_amount?: Maybe<Scalars['Float']>;
    type?: Maybe<BonusType>;
    uid: Scalars['String'];
}

export class PlayerBonusInput {
    bonus_engine_code: Scalars['String'];
    bonus_id: Scalars['String'];
    bonus_name?: Maybe<Scalars['String']>;
    bonus_status: PlayerBonusStatus;
    created_at: TimestampInput;
    desc?: Maybe<Scalars['String']>;
    expire_at: TimestampInput;
    marketing_code: Scalars['String'];
    realized_amount?: Maybe<Scalars['Float']>;
    total_amount?: Maybe<Scalars['Float']>;
    trigger_by?: Maybe<EventInitiator>;
    trigger_by_uid?: Maybe<Scalars['String']>;
    type?: Maybe<BonusType>;
    uid: Scalars['String'];
}

export enum PlayerBonusStatus {
    Active = 'Active',
    Complete = 'Complete',
    Expired = 'Expired',
    Pending = 'Pending',
}

export class PlayerBonusTriggerInput {
    bonus_id: Scalars['String'];
    bonus_name: Scalars['String'];
    event_type: BonusType;
    meta: PlayerBonusTriggerMetaInput;
    timestamp: TimestampInput;
    uid: Scalars['String'];
}

export class PlayerBonusTriggerMetaInput {
    amount?: Maybe<Scalars['Float']>;
    currency?: Maybe<Scalars['String']>;
    marketing_code?: Maybe<Scalars['String']>;
}

export class PlayerBonusesFilter {
    text?: Maybe<Array<Maybe<TextFilter>>>;
    type?: Maybe<Array<Maybe<BonusType>>>;
}

export class PlayerBonusesPage {
    __typename?: 'PlayerBonusesPage';
    items?: Maybe<Array<PlayerBonus>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class PlayerDepositSummary {
    __typename?: 'PlayerDepositSummary';
    amount: Scalars['Float'];
    currency: Scalars['String'];
    total_number: Scalars['Int'];
}

export class PlayerDepositSummaryInput {
    amount: Scalars['Float'];
    currency: Scalars['String'];
    total_number: Scalars['Int'];
}

export class PlayerFinanceSummary {
    __typename?: 'PlayerFinanceSummary';
    balance?: Maybe<Scalars['Float']>;
    bonus_ratio?: Maybe<Scalars['Float']>;
    casino_coin_balance?: Maybe<Scalars['Float']>;
    deposit?: Maybe<TransactionSummary>;
    generated_rake?: Maybe<Scalars['Float']>;
    ggr?: Maybe<Scalars['Float']>;
    ggr_lifetime?: Maybe<GgrLifetime>;
    lifetime_bonus?: Maybe<Scalars['Float']>;
    p2p_credit?: Maybe<TransactionGroupSummary>;
    p2p_debit?: Maybe<TransactionGroupSummary>;
    payment_methods?: Maybe<Array<Maybe<PaymentSystem>>>;
    withdrawal?: Maybe<TransactionSummary>;
}

export class PlayerFinanceSummaryInput {
    balance?: Maybe<Scalars['Float']>;
    bonus_ratio?: Maybe<Scalars['Float']>;
    casino_coin_balance?: Maybe<Scalars['Float']>;
    deposit?: Maybe<TransactionSummaryInput>;
    generated_rake?: Maybe<Scalars['Float']>;
    ggr?: Maybe<Scalars['Float']>;
    lifetime_bonus?: Maybe<Scalars['Float']>;
    payment_methods?: Maybe<Array<Maybe<PaymentSystem>>>;
    withdrawal?: Maybe<TransactionSummaryInput>;
}

export class PlayerGamesSummary {
    __typename?: 'PlayerGamesSummary';
    cash_game_hand_played?: Maybe<Scalars['Int']>;
    mtt_played?: Maybe<Scalars['Int']>;
    player_net_deposit?: Maybe<Scalars['Float']>;
    player_profit_loss?: Maybe<Scalars['Float']>;
}

export class PlayerMessage {
    __typename?: 'PlayerMessage';
    content: Scalars['String'];
    id: Scalars['String'];
    mail_type: PlayerMessageMailType;
    sender: Scalars['String'];
    sender_id: Scalars['String'];
    sent_at: Timestamp;
    signature: Scalars['String'];
    source: PlayerMessageSource;
    subject: Scalars['String'];
    title: Scalars['String'];
    uid: Scalars['String'];
}

export class PlayerMessageFilterInput {
    mail_type?: Maybe<PlayerMessageMailType>;
    sent_at?: Maybe<DateRange>;
    source?: Maybe<PlayerMessageSource>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class PlayerMessageInput {
    content: Scalars['String'];
    mail_type: PlayerMessageMailType;
    sender: Scalars['String'];
    sender_id: Scalars['String'];
    sent_at: TimestampInput;
    signature: Scalars['String'];
    source: PlayerMessageSource;
    subject: Scalars['String'];
    title: Scalars['String'];
    uid: Scalars['String'];
}

export enum PlayerMessageMailType {
    AsyncAnnounce = 'AsyncAnnounce',
    Mail = 'Mail',
    RealAnnounce = 'RealAnnounce',
}

export enum PlayerMessageSource {
    Bo = 'BO',
    Cio = 'CIO',
}

export class PlayerMessagesPage {
    __typename?: 'PlayerMessagesPage';
    items?: Maybe<Array<PlayerMessage>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class PlayerReferral {
    __typename?: 'PlayerReferral';
    expiration_date?: Maybe<Timestamp>;
    invite_code?: Maybe<Scalars['String']>;
    is_agent?: Maybe<Scalars['Boolean']>;
    referee: UserProfile;
    referee_uid?: Maybe<Scalars['String']>;
    referrer_id?: Maybe<Scalars['String']>;
    revenue_generated_total?: Maybe<Scalars['Float']>;
    revenue_share?: Maybe<Scalars['Float']>;
    revenue_share_offering_status?: Maybe<RevenueShareOfferingStatus>;
}

export class PlayerReferralInput {
    invite_code?: Maybe<Scalars['String']>;
    referee_uid: Scalars['String'];
    referrer_id: Scalars['String'];
}

export class PlayerReferralsFilter {
    referrer_id?: Maybe<Scalars['String']>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class PlayerReferralsPage {
    __typename?: 'PlayerReferralsPage';
    items?: Maybe<Array<PlayerReferral>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class PlayerReferrerSummary {
    __typename?: 'PlayerReferrerSummary';
    invite_code?: Maybe<Scalars['String']>;
    redeem_pending_total?: Maybe<Scalars['Float']>;
    referrer: UserProfile;
    revenue_generated_total?: Maybe<Scalars['Float']>;
    revenue_redeemed_total?: Maybe<Scalars['Float']>;
    revenue_share?: Maybe<Scalars['Float']>;
}

export class PlayerSecuritySummary {
    __typename?: 'PlayerSecuritySummary';
    gto_score: Scalars['Float'];
    player_skill: PlayerSkill;
}

export class PlayerSecuritySummaryInput {
    gto_score?: Maybe<Scalars['Float']>;
    player_skill?: Maybe<PlayerSkillInput>;
}

export class PlayerSkill {
    __typename?: 'PlayerSkill';
    custom_label: PlayerSkillCustomLabel;
    label: PlayerSkillLabel;
    score: Scalars['Float'];
}

export enum PlayerSkillCustomLabel {
    Undefined = 'Undefined',
    Vip = 'VIP',
}

export class PlayerSkillInput {
    custom_label?: Maybe<PlayerSkillCustomLabel>;
    label?: Maybe<PlayerSkillLabel>;
    score?: Maybe<Scalars['Float']>;
}

export enum PlayerSkillLabel {
    NonPro = 'NonPro',
    Pro = 'Pro',
}

export class PlayerUidInput {
    uid: Scalars['String'];
}

export class PlayerVerificationInfo {
    __typename?: 'PlayerVerificationInfo';
    is_address_ssn_verified: PlayerVerificationStatus;
    is_email_verified: PlayerVerificationStatus;
    is_phone_verified: PlayerVerificationStatus;
}

export class PlayerVerificationInfoInput {
    is_address_ssn_verified?: Maybe<PlayerVerificationStatus>;
    is_email_verified?: Maybe<PlayerVerificationStatus>;
    is_phone_verified?: Maybe<PlayerVerificationStatus>;
}

export enum PlayerVerificationStatus {
    Failed = 'Failed',
    InProgress = 'InProgress',
    NotVerified = 'NotVerified',
    Passed = 'Passed',
}

export class PlayerWithdrawalSummary {
    __typename?: 'PlayerWithdrawalSummary';
    amount: Scalars['Float'];
    currency: Scalars['String'];
    total_number: Scalars['Int'];
}

export class PlayerWithdrawalSummaryInput {
    amount: Scalars['Float'];
    currency: Scalars['String'];
    total_number: Scalars['Int'];
}

export enum PlayersGroup {
    BotPlayer = 'BotPlayer',
    Evidence = 'Evidence',
    Undefined = 'Undefined',
}

export class PlayersRelation {
    __typename?: 'PlayersRelation';
    created_at: Timestamp;
    id: Scalars['String'];
    player: UserProfile;
    related_player: UserProfile;
    relation_type: PlayersRelationType;
    relevance: Scalars['String'];
    security_case_types?: Maybe<Array<Maybe<SecurityCaseType>>>;
}

export class PlayersRelationFilter {
    created_at?: Maybe<DateRange>;
    players_group?: Maybe<Array<PlayersGroup>>;
    security_case_types?: Maybe<Array<Maybe<SecurityCaseType>>>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class PlayersRelationInput {
    created_at: TimestampInput;
    player: PlayerUidInput;
    related_player: PlayerUidInput;
    relation_type: PlayersRelationType;
    relevance: Scalars['String'];
    security_case_types?: Maybe<Array<Maybe<SecurityCaseType>>>;
}

export class PlayersRelationPage {
    __typename?: 'PlayersRelationPage';
    items?: Maybe<Array<PlayersRelation>>;
    total_count?: Maybe<Scalars['Int']>;
}

export enum PlayersRelationType {
    BankCard = 'BankCard',
    BankCardName = 'BankCardName',
    Device = 'Device',
    Ip = 'Ip',
    Subnet = 'Subnet',
}

export class ProPlayer {
    __typename?: 'ProPlayer';
    platform?: Maybe<Scalars['Int']>;
    pro?: Maybe<Scalars['Int']>;
    third_id?: Maybe<Scalars['String']>;
    uid?: Maybe<Scalars['String']>;
    update_time?: Maybe<Timestamp>;
}

export class ProPlayerFilter {
    platform?: Maybe<Scalars['String']>;
    update_time?: Maybe<DateRange>;
}

export class ProPlayerPage {
    __typename?: 'ProPlayerPage';
    items?: Maybe<Array<ProPlayer>>;
    total_count: Scalars['Int'];
}

export class ProSummary {
    __typename?: 'ProSummary';
    cost_ftd_bonus_pro?: Maybe<Scalars['Float']>;
    cost_psp_fee_pro?: Maybe<Scalars['Float']>;
    cost_spins_tournament_tickets_pro?: Maybe<Scalars['Float']>;
    is_pro_shortdeck?: Maybe<Scalars['Int']>;
    nlhe_ggr_usd_pro?: Maybe<Scalars['Float']>;
    nlhe_high_ggr_usd_pro?: Maybe<Scalars['Float']>;
    nlhe_low_ggr_usd_pro?: Maybe<Scalars['Float']>;
    nlhe_medium_ggr_usd_pro?: Maybe<Scalars['Float']>;
    nlhe_micro_ggr_usd_pro?: Maybe<Scalars['Float']>;
    poker_ggr_usd_pro?: Maybe<Scalars['Float']>;
    shortdeck_ggr_usd_pro?: Maybe<Scalars['Float']>;
}

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export class Query {
    __typename?: 'Query';
    fakeError?: Maybe<Scalars['String']>;
    generateCaseNumber?: Maybe<Scalars['String']>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getAccountVerifications?: Maybe<AccountVerificationPage>;
    /**
     * returns reports for sub agents and players invited by agents.
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has `entity-all` OR `entity-all:read` policy
     *
     * ** For agents: ** auth token has `agent` OR `agent:read` policy
     */
    getAgentPathStatistics?: Maybe<AgentPathStatisticsPage>;
    /**
     * returns reports for sub agents and players invited by agents.
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has (`entity-all` OR `entity-all:read`) AND (`wpt-global` OR `wpt-global:read`) policy
     *
     * ** For agents: ** auth token has (`agent` OR `agent:read`) AND (`wpt-global` OR `wpt-global:read`) policies AND reports belongs to sub agents or players referred by an agent whose id in the token
     */
    getAgentPlayerReportings?: Maybe<AgentPlayerReportingPage>;
    /**
     * returns reports for sub agents and players invited by agents, agents without referees
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has (`entity-all` OR `entity-all:read`) AND (`wpt-asia` OR `wpt-asia:read`) policy
     *
     * ** For agents: ** auth token has (`agent` OR `agent:read`) AND (`wpt-asia` OR `wpt-asia:read`) policies AND reports belongs to player directly referred by an agent or agent without referee whose id in the token
     */
    getAgentPlayerReportingsKr?: Maybe<AgentPlayerReportingKrPage>;
    /**
     * returns reports for sub agents and players invited by agents.
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has (`entity-all` OR `entity-all:read`) AND (`wpt-global` OR `wpt-global:read`) policy
     *
     * ** For agents: ** auth token has (`agent` OR `agent:read`) AND (`wpt-global` OR `wpt-global:read`) policies AND reports belongs to sub agents or players referred by an agent whose id in the token
     */
    getAgentPlayerReportingsNew?: Maybe<AgentPlayerReportingPage>;
    /**
     * returns reports for sub agents and players invited by agents, agents without referees
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has (`entity-all` OR `entity-all:read`) AND (`wpt-global` OR `wpt-global:read`) policy
     *
     * ** For agents: ** auth token has (`agent` OR `agent:read`) AND (`wpt-global` OR `wpt-global:read`) policies AND reports belongs to sub agents or players referred by an agent whose id in the token
     */
    getAgentPlayerReportingsVn?: Maybe<AgentPlayerReportingVnPage>;
    /**
     * ### Policies ###
     *
     * ** For all users: ** auth token has (`entity-all` OR `entity-all:read`) AND (`wpt-global` OR `wpt-global:read`) policy
     *
     * ** For agents: ** auth token has (`agent` OR `agent:read`) AND (`wpt-global` OR `wpt-global:read`) policies AND reports belongs to sub agents or players referred by an agent whose id in the token
     */
    getAgentPlayerReportingsVnNew?: Maybe<AgentPlayerReportingVnPage>;
    /**
     * returns list of agent profiles
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has `entity-all` OR `entity-all:read`
     *
     * ** For agents: ** (auth token has `agent` OR `agent:read`) AND (result user profiles are agents in agent tree of user who called query)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return players from any country except KR and PH
     */
    getAgentProfiles?: Maybe<Array<Maybe<UserProfile>>>;
    /**
     * returns list of agent profiles
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has `entity-all` OR `entity-all:read`
     *
     * ** For agents: ** (auth token has `agent` OR `agent:read`) AND (result user profiles are agents in agent tree of user who called query)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getAgentProfilesNew?: Maybe<Array<Maybe<UserProfile>>>;
    /**
     * returns weekly Agent level reports for ROW agents
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has (`entity-all` OR `entity-all:read`) AND (`wpt-global` OR `wpt-global:read`) policy
     *
     * ** For agents: ** if auth token has (`agent` OR `agent:read`) AND (`wpt-global` OR `wpt-global:read`) policies, user has access to own data
     */
    getAgentRevenueShareWeeklyReport?: Maybe<AgentRevenueShareWeeklyReportPage>;
    /**
     * returns list of aggregated financial summary
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has `entity-all` OR `entity-all:read` policy
     *
     * ** For agents: ** (auth token has `agent` OR `agent:read`) AND (result items are owned by direct or downstream referees)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to all not KR or PH players
     */
    getAggregatedFinanceSummaries?: Maybe<AggregatedFinanceSummariesPage>;
    /**
     * returns aggregated financial summary based on historical data
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has `entity-all` OR `entity-all:read` policy
     *
     * ** For agents: ** (auth token has `agent` OR `agent:read`) AND (result items are owned by direct or downstream referees)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getAggregatedFinanceSummary?: Maybe<AggregatedFinanceSummary>;
    getAutoWithdrawalRulesData?: Maybe<AutoWithdrawalRulesData>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getBackpackItem?: Maybe<BackpackItem>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getBackpackItems?: Maybe<BackpackItemsPage>;
    /**
     * returns the list of bonuses triggered
     *
     * ### Policies: ###
     *
     * auth token contains `entity-bonus` OR `entity-bonus:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getBonusCodeHistory?: Maybe<BonusCodeHistoryPage>;
    getBonusCodes?: Maybe<BonusCodePage>;
    getBotGroups?: Maybe<BotGroupPage>;
    /**
     * returns current status of operation and each bulk item
     *
     * ### Policies ###
     *
     * auth token has `entity-all:read`, `entity-bulk:read`
     */
    getBulkOperationResult?: Maybe<BulkOperationResult>;
    /**
     * returns the list of operations
     *
     * ### Policies ###
     *
     * auth token has `entity-all:read`, `entity-bulk:read`
     */
    getBulkOperationResults?: Maybe<BulkOperationResultPage>;
    /**
     * returns list of clubs
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has `entity-all:read`
     */
    getClubs?: Maybe<ClubsPage>;
    /** returns reports for sub agents and players invited by agents, agents without referees */
    getEmbeddedDashboard?: Maybe<DashboardUrl>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getEventLogs?: Maybe<EventLogRecordPage>;
    getFriendGroups?: Maybe<FriendGroupPage>;
    getFullAgentReport?: Maybe<Scalars['AWSJSON']>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getGeoCheckSummary?: Maybe<GeoCheckSummary>;
    getLabelGroups?: Maybe<LabelGroupPage>;
    getMarketingCodes?: Maybe<MarketingCodesPage>;
    /**
     * returns the list of notes
     *
     * ### Policies: ###
     *
     * auth token has `entity-all` or `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getNotes?: Maybe<NotesPage>;
    /**
     * ### Policies ###
     *
     * ** For all users: ** auth token contains `entity-all` or `entity-all:read`
     */
    getPageViews?: Maybe<Array<Maybe<PageView>>>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getPlayerBonus?: Maybe<PlayerBonus>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getPlayerBonuses?: Maybe<PlayerBonusesPage>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getPlayerMessages?: Maybe<PlayerMessagesPage>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getPlayerReferralLink?: Maybe<Scalars['String']>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getPlayerReferrals?: Maybe<PlayerReferralsPage>;
    /**
     * returns referrer info and his revenue share
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * ** For agents: ** auth token contains `agent:read` AND `uid` param is linked to `sub` from Auth token
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getPlayerReferrerSummary?: Maybe<PlayerReferrerSummary>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getProPlayers?: Maybe<ProPlayerPage>;
    getReferralSettings?: Maybe<ReferralSettings>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items where both `player` and `related_player` are from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items where both `player` and `related_player` are from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items where both `player` and `related_player` are not from KR or PH
     */
    getRelatedPlayers?: Maybe<PlayersRelationPage>;
    /**
     * returns list of daily statistics: registrations, first-day deposits, etc.
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has (`entity-report` OR `entity-report:read` OR `entity-report:mkt` OR `entity-report:mkt:read`)
     *
     * ** For club owners: ** auth token has (`club` OR `club:read`) AND (`entity-report` OR `entity-report:read` OR`entity-report:mkt` OR `entity-report:mkt:read`)
     */
    getReportingMarketing?: Maybe<ReportingMarketing>;
    getReputationScores?: Maybe<UserReputationScorePage>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getRgUsersLimits?: Maybe<UserRgLimitsPage>;
    getSecurityCases?: Maybe<Array<SecurityCase>>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getSecurityKYCVerificationStatus?: Maybe<AccountVerificationStatus>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getSecurityKYCVerificationStatusWPK?: Maybe<AccountVerificationStatus>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getUserActionHistory?: Maybe<UserActionHistoryPage>;
    /**
     * ### Policies ###
     *
     * ** For all users: ** auth token contains `entity-all` or `entity-all:read`
     */
    getUserActivitySummary?: Maybe<UserActivitySummary>;
    /**
     * returns list of user club relations
     *
     * ### Policies ###
     *
     * ** For all users: ** auth token has `entity-all:read`
     */
    getUserClubs?: Maybe<UserClubsPage>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getUserGeoChecks?: Maybe<Array<Maybe<GeoCheck>>>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getUserKYC?: Maybe<KycCheckResults>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getUserKYCWPK?: Maybe<KycCheckResults>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getUserLoginHistory?: Maybe<Array<Maybe<UserLogin>>>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getUserPokerStatistics?: Maybe<UserStatisticsPage>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return players from any country except KR and PH
     */
    getUserProfile?: Maybe<UserProfile>;
    /**
     * returns the list of players. It includes: account details, personal info, financial summary, marketing info, security info
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * ** For agents: ** auth token contains `agent:read` AND (player is and agent which called the query OR his direct referee OR his downstream referee)
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return players from any country except KR and PH
     */
    getUserProfiles?: Maybe<UserPage>;
    getUserReCaptchaScores?: Maybe<UserReCaptchaScorePage>;
    getUserUploadedDocuments?: Maybe<UploadedDocumentPage>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getUsersGames?: Maybe<UserGamePage>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getUsersGeoChecks?: Maybe<GeoCheckPage>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getUsersKYC?: Maybe<UserKycPage>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getUsersLoginHistory?: Maybe<UserLoginPage>;
    /**
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` return items related to KR players
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` return items related to PH players
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` return items related to not KR or PH players
     */
    getUsersTransactions?: Maybe<UserTransactionPage>;
    search?: Maybe<Array<GlobalSearchRecord>>;
}

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGenerateCaseNumberArgs = {
    security_case: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAccountVerificationsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<AccountVerificationFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAgentPathStatisticsArgs = {
    end?: Maybe<Scalars['Int']>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAgentPlayerReportingsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<AgentPlayerReportingFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAgentPlayerReportingsKrArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<AgentPlayerReportingFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAgentPlayerReportingsNewArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<AgentPlayerReportingFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAgentPlayerReportingsVnArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<AgentPlayerReportingFilterVn>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAgentPlayerReportingsVnNewArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<AgentPlayerReportingFilterVn>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAgentProfilesArgs = {
    filter?: Maybe<AgentProfileFilter>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAgentProfilesNewArgs = {
    filter?: Maybe<AgentProfileFilter>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAgentRevenueShareWeeklyReportArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<AgentRevenueShareWeeklyReportFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAggregatedFinanceSummariesArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<AggregatedFinanceSummariesFilterInput>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAggregatedFinanceSummaryArgs = {
    filter?: Maybe<AggregatedFinanceSummaryFilterInput>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetAutoWithdrawalRulesDataArgs = {
    transaction_id?: Maybe<Scalars['String']>;
    uid?: Maybe<Scalars['String']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetBackpackItemArgs = {
    bonus_id: Scalars['String'];
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetBackpackItemsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<BackpackItemsFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetBonusCodeHistoryArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<BonusCodeHistoryFilterInput>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetBonusCodesArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<BonusCodeFilterInput>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetBotGroupsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<BotGroupFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetBulkOperationResultArgs = {
    filter: BulkOperationFilterInput;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetBulkOperationResultsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<BulkOperationResultFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetClubsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<ClubsFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetEmbeddedDashboardArgs = {
    type?: Maybe<EmbeddedDashboardType>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetEventLogsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<EventLogFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetFriendGroupsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<FriendGroupFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetFullAgentReportArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<AgentPlayerReportingFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetGeoCheckSummaryArgs = {
    uid?: Maybe<Scalars['String']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetLabelGroupsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<LabelGroupFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetMarketingCodesArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<MarketingCodeFilterInput>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetNotesArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<NotesFilterInput>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetPageViewsArgs = {
    filter?: Maybe<PageViewFilter>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetPlayerBonusArgs = {
    bonus_id: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetPlayerBonusesArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<PlayerBonusesFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetPlayerMessagesArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<PlayerMessageFilterInput>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetPlayerReferralLinkArgs = {
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetPlayerReferralsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<PlayerReferralsFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetPlayerReferrerSummaryArgs = {
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetProPlayersArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<ProPlayerFilter>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetRelatedPlayersArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<PlayersRelationFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetReportingMarketingArgs = {
    filter: ReportingFilter;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetReputationScoresArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<UserReputationScoreFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetRgUsersLimitsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<RgUserLimitsFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetSecurityCasesArgs = {
    case_id?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    type: SecurityCaseType;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetSecurityKycVerificationStatusArgs = {
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetSecurityKycVerificationStatusWpkArgs = {
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserActionHistoryArgs = {
    end?: Maybe<Scalars['Int']>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserActivitySummaryArgs = {
    uid?: Maybe<Scalars['String']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserClubsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<UserClubsFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserGeoChecksArgs = {
    uid?: Maybe<Scalars['String']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserKycArgs = {
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserKycwpkArgs = {
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserLoginHistoryArgs = {
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserPokerStatisticsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<UserStatisticsFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserProfileArgs = {
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserProfilesArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<UserProfileFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserReCaptchaScoresArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<UserReCaptchaScoreFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUserUploadedDocumentsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<UploadedDocumentFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUsersGamesArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<GameHistoryFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUsersGeoChecksArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<GeoCheckFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUsersKycArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<KycCheckResultsFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUsersLoginHistoryArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<UserLoginFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QueryGetUsersTransactionsArgs = {
    end?: Maybe<Scalars['Int']>;
    filter?: Maybe<UserTransactionFilter>;
    sort?: Maybe<Array<Maybe<Sorting>>>;
    start?: Maybe<Scalars['Int']>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all queries available
 */
export type QuerySearchArgs = {
    filter?: Maybe<GlobalSearchFilter>;
    limit?: Maybe<Scalars['Int']>;
};

export class Reason {
    __typename?: 'Reason';
    created_at_ts?: Maybe<Timestamp>;
    reason_code?: Maybe<ReasonCode>;
    reason_text?: Maybe<Scalars['String']>;
    reason_type?: Maybe<ReasonType>;
}

export enum ReasonCode {
    Bonus = 'Bonus',
    BountyBonus = 'BountyBonus',
    CancelledByPlayer = 'CancelledByPlayer',
    CasinoManualBonus = 'CasinoManualBonus',
    Cheating = 'Cheating',
    Collusion = 'Collusion',
    Compensation = 'Compensation',
    Confiscation = 'Confiscation',
    Correction = 'Correction',
    DepositNotFullyWagered = 'DepositNotFullyWagered',
    Fraud = 'Fraud',
    FraudulentActivities = 'FraudulentActivities',
    GoodwillGesture = 'GoodwillGesture',
    KycPendingFailed = 'KYCPendingFailed',
    KycRequired = 'KYCRequired',
    ManualDeposit = 'ManualDeposit',
    ManualWithdrawal = 'ManualWithdrawal',
    Marketing = 'Marketing',
    NoCloseLoop = 'NoCloseLoop',
    Other = 'Other',
    OtherCost = 'OtherCost',
    OtherRevenue = 'OtherRevenue',
    P2pTransfer = 'P2pTransfer',
    PspError = 'PSPError',
    PspFailed = 'PSPFailed',
    RakeBack = 'RakeBack',
    ReachRGlimits = 'ReachRGlimits',
    RefundChargeback = 'RefundChargeback',
    RevShareAffiliate = 'RevShareAffiliate',
    RevShareReferral = 'RevShareReferral',
    RevenueShare = 'RevenueShare',
    SecurityCase = 'SecurityCase',
    Test = 'Test',
    ThirdDeposit = 'ThirdDeposit',
    Undefined = 'Undefined',
}

export class ReasonInput {
    created_at_ts?: Maybe<TimestampInput>;
    reason_code?: Maybe<ReasonCode>;
    reason_text?: Maybe<Scalars['String']>;
    reason_type?: Maybe<ReasonType>;
}

export enum ReasonType {
    Credit = 'Credit',
    Debit = 'Debit',
    WithdrawalCancellation = 'WithdrawalCancellation',
    WithdrawalFailed = 'WithdrawalFailed',
    WithdrawalManualReview = 'WithdrawalManualReview',
    WithdrawalRejection = 'WithdrawalRejection',
}

export enum RefereeType {
    None = 'None',
    Player = 'Player',
    SubAgent = 'SubAgent',
}

export enum ReferralAmountUpdateType {
    Agent = 'Agent',
    Percentage = 'Percentage',
    Redeem = 'Redeem',
    Revenue = 'Revenue',
    RevenueTotal = 'RevenueTotal',
}

export class ReferralSettings {
    __typename?: 'ReferralSettings';
    expiration_period: Scalars['Int'];
    revenue_share: Scalars['Float'];
}

export class ReferralSettingsInput {
    expiration_period?: Maybe<Scalars['Int']>;
    revenue_share?: Maybe<Scalars['Float']>;
}

export class ReferralUpdateInput {
    agent_revenue_share_history?: Maybe<Scalars['String']>;
    amount?: Maybe<Scalars['Float']>;
    expiration_date?: Maybe<TimestampInput>;
    is_agent?: Maybe<Scalars['Boolean']>;
    revenue_share?: Maybe<Scalars['Float']>;
    uid: Scalars['String'];
    update_type: ReferralAmountUpdateType;
}

export class Referrals {
    __typename?: 'Referrals';
    inviting_user_id?: Maybe<Scalars['String']>;
    inviting_user_username?: Maybe<Scalars['String']>;
    personal_referral_code?: Maybe<Scalars['String']>;
    referral_code?: Maybe<Scalars['String']>;
    referred_at?: Maybe<Timestamp>;
}

export class ReferralsInput {
    inviting_user_id?: Maybe<Scalars['String']>;
    inviting_user_username?: Maybe<Scalars['String']>;
    personal_referral_code?: Maybe<Scalars['String']>;
    referral_code?: Maybe<Scalars['String']>;
    referred_at?: Maybe<TimestampInput>;
}

export class ReferrerFilter {
    is_agent: Scalars['Boolean'];
    is_downstream?: Maybe<Scalars['Boolean']>;
    referrer_player_id?: Maybe<Scalars['String']>;
}

export enum ReferrerType {
    Affiliate = 'Affiliate',
    P2P = 'P2P',
}

export class RegionPlayerInfo {
    __typename?: 'RegionPlayerInfo';
    country_of_birth?: Maybe<Scalars['String']>;
    district?: Maybe<Scalars['String']>;
    national_id?: Maybe<Scalars['String']>;
    nationality?: Maybe<Scalars['String']>;
    occupation?: Maybe<Scalars['String']>;
    tax_id?: Maybe<Scalars['String']>;
}

export class RegionPlayerInfoInput {
    country_of_birth?: Maybe<Scalars['String']>;
    district?: Maybe<Scalars['String']>;
    national_id?: Maybe<Scalars['String']>;
    nationality?: Maybe<Scalars['String']>;
    occupation?: Maybe<Scalars['String']>;
    tax_id?: Maybe<Scalars['String']>;
}

export class RemovePlayerReferralInput {
    referee_uid: Scalars['String'];
    referrer_id?: Maybe<Scalars['String']>;
}

export class ReportingFilter {
    club_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
    date?: Maybe<DateRange>;
}

export class ReportingMarketing {
    __typename?: 'ReportingMarketing';
    club_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
    metrics?: Maybe<ReportingMarketingMetrics>;
    time_series?: Maybe<Array<Maybe<ReportingMarketingTimeSeries>>>;
}

export class ReportingMarketingMetrics {
    __typename?: 'ReportingMarketingMetrics';
    active_2nd_day?: Maybe<Scalars['Float']>;
    active_3rd_day?: Maybe<Scalars['Float']>;
    active_7th_day?: Maybe<Scalars['Float']>;
    avg_ltv_1st_day?: Maybe<Scalars['Float']>;
    avg_ltv_2st_day?: Maybe<Scalars['Float']>;
    avg_ltv_7st_day?: Maybe<Scalars['Float']>;
    avg_ltv_14st_day?: Maybe<Scalars['Float']>;
    reg_to_deposit?: Maybe<Scalars['Float']>;
    reg_to_play?: Maybe<Scalars['Float']>;
    total_registrations?: Maybe<Scalars['Int']>;
}

export class ReportingMarketingTimeSeries {
    __typename?: 'ReportingMarketingTimeSeries';
    active_2nd_day_ratio?: Maybe<Scalars['Float']>;
    active_3rd_day_ratio?: Maybe<Scalars['Float']>;
    active_7th_day_ratio?: Maybe<Scalars['Float']>;
    date: Timestamp;
    first_day_deposit_ratio?: Maybe<Scalars['Float']>;
    first_day_play_ratio?: Maybe<Scalars['Float']>;
    ltv_2d_avg?: Maybe<Scalars['Float']>;
    ltv_7d_avg?: Maybe<Scalars['Float']>;
    ltv_14d_avg?: Maybe<Scalars['Float']>;
    registered_player_number?: Maybe<Scalars['Int']>;
}

export enum ResultType {
    Failure = 'Failure',
    Success = 'Success',
}

export class RevenueShareClaimInput {
    amount: Scalars['Float'];
    currency?: Maybe<Scalars['String']>;
}

export class RevenueShareClaimResult {
    __typename?: 'RevenueShareClaimResult';
    claimstatus?: Maybe<ValidationResult>;
    transactionId?: Maybe<Scalars['String']>;
}

export enum RevenueShareOfferingStatus {
    Active = 'Active',
    Inactive = 'Inactive',
}

export enum RevenueShareType {
    Direct = 'Direct',
    None = 'None',
    SubAgent = 'SubAgent',
}

export interface RgLimitHistory {
    end_at?: Maybe<Timestamp>;
    limit_id?: Maybe<Scalars['String']>;
    start_at: Timestamp;
    trigger_at?: Maybe<Timestamp>;
    type: RgLimitType;
}

export enum RgLimitType {
    Deposit = 'DEPOSIT',
    DepositBlocked = 'DEPOSIT_BLOCKED',
    Loss = 'LOSS',
    SelfExclusion = 'SELF_EXCLUSION',
    Time = 'TIME',
    Wager = 'WAGER',
}

export class RgMoneyLimitHistory implements RgLimitHistory {
    __typename?: 'RgMoneyLimitHistory';
    currency: Scalars['String'];
    duration_type: LimitDurationType;
    end_at?: Maybe<Timestamp>;
    limit: Scalars['Float'];
    limit_id?: Maybe<Scalars['String']>;
    start_at: Timestamp;
    trigger_at?: Maybe<Timestamp>;
    type: RgLimitType;
    version?: Maybe<Scalars['Int']>;
}

export class RgMoneyLimitHistoryInput {
    currency?: Maybe<Scalars['String']>;
    duration_type?: Maybe<LimitDurationType>;
    end_at?: Maybe<TimestampInput>;
    limit?: Maybe<Scalars['Float']>;
    limit_id?: Maybe<Scalars['String']>;
    start_at?: Maybe<TimestampInput>;
    trigger_at?: Maybe<TimestampInput>;
    type: RgLimitType;
}

export class RgStateLimitHistory implements RgLimitHistory {
    __typename?: 'RgStateLimitHistory';
    end_at?: Maybe<Timestamp>;
    limit_id?: Maybe<Scalars['String']>;
    start_at: Timestamp;
    trigger_at?: Maybe<Timestamp>;
    type: RgLimitType;
    version?: Maybe<Scalars['Int']>;
}

export class RgStateLimitHistoryInput {
    end_at?: Maybe<TimestampInput>;
    limit_id?: Maybe<Scalars['String']>;
    start_at?: Maybe<TimestampInput>;
    trigger_at?: Maybe<TimestampInput>;
    type: RgLimitType;
}

export class RgTimeLimitHistory implements RgLimitHistory {
    __typename?: 'RgTimeLimitHistory';
    end_at?: Maybe<Timestamp>;
    limit: Scalars['Int'];
    limit_id?: Maybe<Scalars['String']>;
    start_at: Timestamp;
    trigger_at?: Maybe<Timestamp>;
    type: RgLimitType;
    version?: Maybe<Scalars['Int']>;
}

export class RgTimeLimitHistoryInput {
    end_at?: Maybe<TimestampInput>;
    limit?: Maybe<Scalars['Int']>;
    limit_id?: Maybe<Scalars['String']>;
    start_at?: Maybe<TimestampInput>;
    trigger_at?: Maybe<TimestampInput>;
    type: RgLimitType;
}

export class RgUserLimits {
    __typename?: 'RgUserLimits';
    deposit?: Maybe<Array<RgMoneyLimitHistory>>;
    deposit_blocked?: Maybe<Array<RgStateLimitHistory>>;
    loss?: Maybe<Array<RgMoneyLimitHistory>>;
    self_exclusion?: Maybe<Array<RgStateLimitHistory>>;
    time?: Maybe<Array<RgTimeLimitHistory>>;
    uid: Scalars['String'];
    username?: Maybe<Scalars['String']>;
    wager?: Maybe<Array<RgMoneyLimitHistory>>;
}

export class RgUserLimitsFilter {
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class RgUserLimitsInput {
    deposit?: Maybe<Array<RgMoneyLimitHistoryInput>>;
    deposit_blocked?: Maybe<Array<RgStateLimitHistoryInput>>;
    loss?: Maybe<Array<RgMoneyLimitHistoryInput>>;
    self_exclusion?: Maybe<Array<RgStateLimitHistoryInput>>;
    time?: Maybe<Array<RgTimeLimitHistoryInput>>;
    uid: Scalars['String'];
    username?: Maybe<Scalars['String']>;
    wager?: Maybe<Array<RgMoneyLimitHistoryInput>>;
}

export class SecurityCase {
    __typename?: 'SecurityCase';
    case_id: Scalars['String'];
    closed_at?: Maybe<Timestamp>;
    opened_at?: Maybe<Timestamp>;
    type: SecurityCaseType;
}

export class SecurityCaseInput {
    case_id: Scalars['String'];
    closed_at?: Maybe<TimestampInput>;
    opened_at?: Maybe<TimestampInput>;
    type?: Maybe<SecurityCaseType>;
}

export enum SecurityCaseType {
    Aml = 'Aml',
    BlacklistProbable = 'BlacklistProbable',
    Bot = 'Bot',
    ChipDumping = 'ChipDumping',
    Collusion = 'Collusion',
    ExternalRequest = 'ExternalRequest',
    Finance = 'Finance',
    GeoLocation = 'GeoLocation',
    Kyc = 'Kyc',
    SharedInstance = 'SharedInstance',
    Undefined = 'Undefined',
}

export class SecurityLimitHistory {
    __typename?: 'SecurityLimitHistory';
    currency: Scalars['String'];
    durationType: LimitDurationType;
    end_at?: Maybe<Timestamp>;
    limit: Scalars['Float'];
    limit_id?: Maybe<Scalars['String']>;
    start_at: Timestamp;
    trigger_at?: Maybe<Timestamp>;
    type: SecurityLimitType;
}

export class SecurityLimitHistoryInput {
    currency?: Maybe<Scalars['String']>;
    durationType?: Maybe<LimitDurationType>;
    end_at?: Maybe<TimestampInput>;
    limit?: Maybe<Scalars['Float']>;
    limit_id?: Maybe<Scalars['String']>;
    start_at?: Maybe<TimestampInput>;
    trigger_at?: Maybe<TimestampInput>;
    type: SecurityLimitType;
}

export enum SecurityLimitType {
    Withdrawal = 'WITHDRAWAL',
}

export enum SecurityPunishmentStatus {
    BanAccount = 'BAN_ACCOUNT',
    ConfiscateBalance = 'CONFISCATE_BALANCE',
    Nothing = 'NOTHING',
}

export enum SortOrder {
    Asc = 'ASC',
    Desc = 'DESC',
}

export class Sorting {
    order?: Maybe<SortOrder>;
    sort?: Maybe<Scalars['String']>;
}

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all subscriptions available
 */
export class Subscription {
    __typename?: 'Subscription';
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    accountVerificationAdded?: Maybe<AccountVerification>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    accountVerificationUpdated?: Maybe<AccountVerification>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    backpackItemAdded?: Maybe<BackpackItem>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    backpackItemUpdated?: Maybe<BackpackItem>;
    beBonusAdded?: Maybe<BonusCodeDetails>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    beBonusHistoryAdded?: Maybe<BonusCodeHistory>;
    botGroupAdded?: Maybe<BotGroup>;
    botGroupUpdated?: Maybe<BotGroup>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    eventLogAdded?: Maybe<EventLogRecord>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    geoCheckSummaryAdded?: Maybe<GeoCheckSummary>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    geoCheckSummaryUpdated?: Maybe<GeoCheckSummary>;
    labelGroupAdded?: Maybe<LabelGroup>;
    labelGroupUpdated?: Maybe<LabelGroup>;
    marketingCodeAdded?: Maybe<MarketingCode>;
    marketingCodesDeleted?: Maybe<Array<Maybe<MarketingCode>>>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    noteAdded?: Maybe<Note>;
    /**
     * Message should be sent for `upsertPageView` mutation if there is no item with passed `page_id` and `user_id` pair or its `last_opened_at` field is more
     *
     * Subscribe only for updates for passed page ids
     *
     * ### Policies: ###
     *
     * auth token contains `entity-all` or `entity-all:read`
     */
    pageViewAdded?: Maybe<PageView>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    playerBonusAdded?: Maybe<PlayerBonus>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    playerBonusUpdated?: Maybe<PlayerBonus>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    playerMessageAdded?: Maybe<PlayerMessage>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    playerReferralAdded?: Maybe<PlayerReferral>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    playerReferralUpdated?: Maybe<PlayerReferral>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    playerReferrerAdded?: Maybe<PlayerReferrerSummary>;
    /**
     * subscription for referrer and his revenue share amount updates
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * ** For agents: ** auth token contains `agent:read` AND `uid` param is linked to `sub` from auth token
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    playerReferrerUpdated?: Maybe<PlayerReferrerSummary>;
    playerRelationAdded?: Maybe<PlayersRelation>;
    playerRelationDeleted?: Maybe<PlayersRelation>;
    referralSettingsUpdated?: Maybe<ReferralSettings>;
    rgLimitAdded?: Maybe<RgUserLimits>;
    rgLimitUpdated?: Maybe<RgUserLimits>;
    rgUserLimitsUpdated?: Maybe<RgUserLimits>;
    userActionAdded?: Maybe<UserAction>;
    userGameUpdated?: Maybe<UserGame>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    userGeoCheckAdded?: Maybe<GeoCheck>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    userKYCResults?: Maybe<KycCheckResults>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    userLoginHistory?: Maybe<UserLogin>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for players from any country except KR and PH
     */
    userProfileAdded?: Maybe<UserProfile>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for players from any country except KR and PH
     */
    userProfileUpdated?: Maybe<UserProfile>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for players from any country except KR and PH
     */
    userProfiles?: Maybe<UserProfile>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    userTransactionAdded?: Maybe<Transaction>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    userTransactionUpdated?: Maybe<Transaction>;
    /**
     * ### Policies: ###
     *
     * if auth token contains `wpt-asia` OR `wpt-asia:read` trigger for items that related to players from KR
     *
     * if auth token contains `wpt-ph` OR `wpt-ph:read` trigger for items that related to players from PH
     *
     * if auth token contains `wpt-global` OR `wpt-global:read` trigger for items that related to players from any country except KR and PH
     */
    userTransactions?: Maybe<Transaction>;
}

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all subscriptions available
 */
export type SubscriptionPageViewAddedArgs = {
    page_ids: Array<Maybe<Scalars['String']>>;
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all subscriptions available
 */
export type SubscriptionUserActionAddedArgs = {
    uid: Scalars['String'];
};

/**
 * ### Policies: ###
 *
 * If auth token contains `internal` or `internal:read` all subscriptions available
 */
export type SubscriptionUserTransactionUpdatedArgs = {
    transaction_id?: Maybe<Scalars['String']>;
};

export class TextFilter {
    search_in: Array<Maybe<Scalars['String']>>;
    text: Scalars['String'];
}

export class Timestamp {
    __typename?: 'Timestamp';
    nanos?: Maybe<Scalars['Long']>;
    seconds?: Maybe<Scalars['Long']>;
}

export class TimestampInput {
    nanos?: Maybe<Scalars['Long']>;
    seconds?: Maybe<Scalars['Long']>;
}

export class Transaction {
    __typename?: 'Transaction';
    amount?: Maybe<Scalars['Float']>;
    casino_coin_amount?: Maybe<Scalars['Float']>;
    contact?: Maybe<UserContactInfo>;
    created_by_uid?: Maybe<Scalars['String']>;
    currency?: Maybe<Scalars['String']>;
    current_balance?: Maybe<Scalars['Float']>;
    current_casino_coin_balance?: Maybe<Scalars['Float']>;
    email?: Maybe<Scalars['String']>;
    iso_alpha2_country_code?: Maybe<Scalars['String']>;
    payment_method_description?: Maybe<Scalars['String']>;
    payment_method_name?: Maybe<Scalars['String']>;
    payment_option?: Maybe<PaymentOption>;
    payment_system_transaction_id?: Maybe<Scalars['String']>;
    payment_system_uid?: Maybe<Scalars['String']>;
    payment_vendor?: Maybe<PaymentVendor>;
    previous_balance?: Maybe<Scalars['Float']>;
    previous_casino_coin_balance?: Maybe<Scalars['Float']>;
    reasons?: Maybe<Array<Maybe<Reason>>>;
    referrer_player_id?: Maybe<Scalars['String']>;
    register_marketing_code?: Maybe<Scalars['String']>;
    status_log?: Maybe<Array<TransactionHistory>>;
    transaction_id: Scalars['String'];
    transaction_started_ts?: Maybe<Timestamp>;
    transaction_status?: Maybe<TransactionStatus>;
    transaction_updated_ts?: Maybe<Timestamp>;
    type?: Maybe<TransactionType>;
    uid: Scalars['String'];
    user_labels?: Maybe<Array<UserLabel>>;
    username?: Maybe<Scalars['String']>;
}

/** contains information about all attempts for transaction group */
export class TransactionAttemptsSummary {
    __typename?: 'TransactionAttemptsSummary';
    /** aggregation currency */
    currency?: Maybe<Scalars['String']>;
    /** total failed amount in base currency */
    failed_amount?: Maybe<Scalars['Float']>;
    /** total number of failed transactions */
    failed_count?: Maybe<Scalars['Long']>;
    /** total pending amount in base currency */
    pending_amount?: Maybe<Scalars['Float']>;
    /** total number of pending transactions */
    pending_count?: Maybe<Scalars['Long']>;
    /** total rejected amount in base currency */
    rejected_amount?: Maybe<Scalars['Float']>;
    /** total number of rejected transactions */
    rejected_count?: Maybe<Scalars['Long']>;
    /** total successful amount in base currency */
    successful_amount?: Maybe<Scalars['Float']>;
    /** total number of successful transactions */
    successful_count?: Maybe<Scalars['Long']>;
}

export class TransactionAttemptsSummaryInput {
    /** aggregation currency */
    currency?: Maybe<Scalars['String']>;
    /** total failed amount in base currency */
    failed_amount?: Maybe<Scalars['Float']>;
    /** total number of failed transactions */
    failed_count?: Maybe<Scalars['Long']>;
    /** total pending amount in base currency */
    pending_amount?: Maybe<Scalars['Float']>;
    /** total number of pending transactions */
    pending_count?: Maybe<Scalars['Long']>;
    /** total rejected amount in base currency */
    rejected_amount?: Maybe<Scalars['Float']>;
    /** total number of rejected transactions */
    rejected_count?: Maybe<Scalars['Long']>;
    /** total successful amount in base currency */
    successful_amount?: Maybe<Scalars['Float']>;
    /** total number of successful transactions */
    successful_count?: Maybe<Scalars['Long']>;
}

export class TransactionGroupSummary {
    __typename?: 'TransactionGroupSummary';
    category?: Maybe<Scalars['String']>;
    /** aggregation currency */
    currency?: Maybe<Scalars['String']>;
    /** total failed amount in base currency */
    failed_amount?: Maybe<Scalars['Float']>;
    /** total number of failed transactions */
    failed_count?: Maybe<Scalars['Long']>;
    group_name?: Maybe<Scalars['String']>;
    payment_method?: Maybe<Scalars['String']>;
    payment_method_description?: Maybe<Scalars['String']>;
    /** total pending amount in base currency */
    pending_amount?: Maybe<Scalars['Float']>;
    /** total number of pending transactions */
    pending_count?: Maybe<Scalars['Long']>;
    /** total rejected amount in base currency */
    rejected_amount?: Maybe<Scalars['Float']>;
    /** total number of rejected transactions */
    rejected_count?: Maybe<Scalars['Long']>;
    /** total successful amount in base currency */
    successful_amount?: Maybe<Scalars['Float']>;
    /** total number of successful transactions */
    successful_count?: Maybe<Scalars['Long']>;
}

export class TransactionGroupSummaryInput {
    category?: Maybe<Scalars['String']>;
    /** aggregation currency */
    currency?: Maybe<Scalars['String']>;
    /** total failed amount in base currency */
    failed_amount?: Maybe<Scalars['Float']>;
    /** total number of failed transactions */
    failed_count?: Maybe<Scalars['Long']>;
    group_name?: Maybe<Scalars['String']>;
    payment_method?: Maybe<Scalars['String']>;
    payment_method_description?: Maybe<Scalars['String']>;
    /** total pending amount in base currency */
    pending_amount?: Maybe<Scalars['Float']>;
    /** total number of pending transactions */
    pending_count?: Maybe<Scalars['Long']>;
    /** total rejected amount in base currency */
    rejected_amount?: Maybe<Scalars['Float']>;
    /** total number of rejected transactions */
    rejected_count?: Maybe<Scalars['Long']>;
    /** total successful amount in base currency */
    successful_amount?: Maybe<Scalars['Float']>;
    /** total number of successful transactions */
    successful_count?: Maybe<Scalars['Long']>;
}

export class TransactionHistory {
    __typename?: 'TransactionHistory';
    logged_at: Timestamp;
    status: TransactionStatus;
}

export class TransactionHistoryInput {
    logged_at: TimestampInput;
    status: TransactionStatus;
}

export class TransactionInput {
    amount?: Maybe<Scalars['Float']>;
    casino_coin_amount?: Maybe<Scalars['Float']>;
    contact?: Maybe<UserContactInfoInput>;
    created_by_uid?: Maybe<Scalars['String']>;
    currency?: Maybe<Scalars['String']>;
    current_balance?: Maybe<Scalars['Float']>;
    current_casino_coin_balance?: Maybe<Scalars['Float']>;
    email?: Maybe<Scalars['String']>;
    iso_alpha2_country_code?: Maybe<Scalars['String']>;
    payment_method_description?: Maybe<Scalars['String']>;
    payment_method_name?: Maybe<Scalars['String']>;
    payment_option?: Maybe<PaymentOption>;
    payment_system_transaction_id?: Maybe<Scalars['String']>;
    payment_system_uid?: Maybe<Scalars['String']>;
    payment_vendor?: Maybe<PaymentVendor>;
    previous_balance?: Maybe<Scalars['Float']>;
    previous_casino_coin_balance?: Maybe<Scalars['Float']>;
    reasons?: Maybe<Array<Maybe<ReasonInput>>>;
    referrer_player_id?: Maybe<Scalars['String']>;
    register_marketing_code?: Maybe<Scalars['String']>;
    status_log?: Maybe<Array<Maybe<TransactionHistoryInput>>>;
    transaction_id: Scalars['String'];
    transaction_started_ts?: Maybe<TimestampInput>;
    transaction_status?: Maybe<TransactionStatus>;
    transaction_updated_ts?: Maybe<TimestampInput>;
    type?: Maybe<TransactionType>;
    uid: Scalars['String'];
    user_labels?: Maybe<Array<UserLabelInput>>;
    username?: Maybe<Scalars['String']>;
}

export enum TransactionStatus {
    Cancelled = 'Cancelled',
    Failed = 'Failed',
    InProgress = 'InProgress',
    OnHoldPayment = 'OnHoldPayment',
    OnHoldRisk = 'OnHoldRisk',
    Pending = 'Pending',
    Rejected = 'Rejected',
    RiskAuthorized = 'RiskAuthorized',
    Succeeded = 'Succeeded',
}

export class TransactionSummary {
    __typename?: 'TransactionSummary';
    per_method?: Maybe<Array<TransactionGroupSummary>>;
    total?: Maybe<TransactionGroupSummary>;
}

export class TransactionSummaryInput {
    per_method?: Maybe<Array<TransactionGroupSummaryInput>>;
    total?: Maybe<TransactionGroupSummaryInput>;
}

export enum TransactionType {
    Bonus = 'BONUS',
    BuyIn = 'BUY_IN',
    BuyOut = 'BUY_OUT',
    CasinoFreeSpinPayout = 'CASINO_FREE_SPIN_PAYOUT',
    CasinoFreeSpinPayoutRefund = 'CASINO_FREE_SPIN_PAYOUT_REFUND',
    CasinoFreeSpinWager = 'CASINO_FREE_SPIN_WAGER',
    CasinoFreeSpinWagerRefund = 'CASINO_FREE_SPIN_WAGER_REFUND',
    CasinoPayout = 'CASINO_PAYOUT',
    CasinoPayoutRefund = 'CASINO_PAYOUT_REFUND',
    CasinoWager = 'CASINO_WAGER',
    CasinoWagerRefund = 'CASINO_WAGER_REFUND',
    Chargeback = 'CHARGEBACK',
    Credit = 'CREDIT',
    Debit = 'DEBIT',
    Deposit = 'DEPOSIT',
    Fee = 'FEE',
    FeeReverse = 'FEE_REVERSE',
    LiveEventBuyIn = 'LIVE_EVENT_BUY_IN',
    MttCredit = 'MTT_CREDIT',
    MttDebit = 'MTT_DEBIT',
    P2PTransferCredit = 'P2P_TRANSFER_CREDIT',
    P2PTransferDebit = 'P2P_TRANSFER_DEBIT',
    Payin = 'PAYIN',
    Payout = 'PAYOUT',
    PayoutReturn = 'PAYOUT_RETURN',
    PokerAddonCredit = 'POKER_ADDON_CREDIT',
    PokerAddonDebit = 'POKER_ADDON_DEBIT',
    PokerFlipsPayout = 'POKER_FLIPS_PAYOUT',
    PokerFlipsWager = 'POKER_FLIPS_WAGER',
    Refund = 'REFUND',
    Release = 'RELEASE',
    Reserve = 'RESERVE',
    RevenueShare = 'REVENUE_SHARE',
    Reverse = 'REVERSE',
    SportsBetSettlement = 'SPORTS_BET_SETTLEMENT',
    SportsBuyin = 'SPORTS_BUYIN',
    SportsBuyout = 'SPORTS_BUYOUT',
    Withdrawal = 'WITHDRAWAL',
}

export class TriggerOutput {
    __typename?: 'TriggerOutput';
    error?: Maybe<Scalars['String']>;
    player_bonus?: Maybe<PlayerBonus>;
}

export class UploadedDocumentFilter {
    doc_type?: Maybe<Array<JumioDocumentType>>;
    status?: Maybe<Array<KycStatus>>;
    uploaded_ts?: Maybe<DateRange>;
}

export class UploadedDocumentPage {
    __typename?: 'UploadedDocumentPage';
    items?: Maybe<Array<JumioDocumentType>>;
    total_count: Scalars['Int'];
}

export class User2FaInput {
    is_enabled?: Maybe<Scalars['Boolean']>;
    uid?: Maybe<Scalars['String']>;
}

export enum UserAccountStatus {
    Active = 'ACTIVE',
    Blocked = 'BLOCKED',
    Closed = 'CLOSED',
    Deregistered = 'DEREGISTERED',
    Dormant = 'DORMANT',
    Locked = 'LOCKED',
    Pending = 'PENDING',
}

export class UserAction {
    __typename?: 'UserAction';
    action_by_uid: Scalars['String'];
    action_type: Scalars['String'];
    id: Scalars['String'];
    new_value?: Maybe<Scalars['AWSJSON']>;
    player_uid?: Maybe<Scalars['String']>;
    prev_value?: Maybe<Scalars['AWSJSON']>;
    trigger_at: Timestamp;
}

export class UserActionHistoryPage {
    __typename?: 'UserActionHistoryPage';
    items?: Maybe<Array<UserAction>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class UserActionInput {
    action?: Maybe<Scalars['String']>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_type?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    new_value?: Maybe<Scalars['AWSJSON']>;
    player_uid?: Maybe<Scalars['String']>;
    trigger_at: TimestampInput;
}

export class UserActivitySummary {
    __typename?: 'UserActivitySummary';
    average_bet?: Maybe<Scalars['Float']>;
    average_poker_buy_in?: Maybe<Scalars['Float']>;
    /** 5 most Casino games played type */
    casino_favorite_games?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** days from last real money play */
    days_from_last_active?: Maybe<Scalars['Int']>;
    /** last played game */
    last_played_game?: Maybe<Scalars['String']>;
    /** 5 most Poker games played type */
    poker_favorite_games?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** (VIP) as per existing flags NVIP, Level 1, Level 2, etc */
    skill_flag?: Maybe<Scalars['String']>;
    /** 5 most Slot games played type */
    slot_favorite_games?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export class UserAddressInfo {
    __typename?: 'UserAddressInfo';
    address?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    country_info?: Maybe<CountryInfo>;
    post_code?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
}

export class UserAddressInfoInput {
    address?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    country_info?: Maybe<CountryInfoInput>;
    post_code?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
}

export class UserBetInput {
    at_ts: TimestampInput;
    bet_return: Scalars['Int'];
    bet_size: Scalars['Int'];
    business_pool?: Maybe<Scalars['String']>;
    game_type?: Maybe<Scalars['Int']>;
    game_uuid?: Maybe<Scalars['String']>;
    room_id?: Maybe<Scalars['String']>;
    uid: Scalars['Int'];
}

export class UserBiggestValues {
    __typename?: 'UserBiggestValues';
    business_pool?: Maybe<Scalars['String']>;
    net_loss?: Maybe<Scalars['Float']>;
    num_sessions?: Maybe<Scalars['Int']>;
    single_bet?: Maybe<Scalars['Float']>;
    total_bet?: Maybe<Scalars['Float']>;
    uid: Scalars['Int'];
}

export class UserBiggestValuesInput {
    business_pool?: Maybe<Scalars['String']>;
    net_loss?: Maybe<Scalars['Float']>;
    num_sessions?: Maybe<Scalars['Int']>;
    single_bet?: Maybe<Scalars['Float']>;
    total_bet?: Maybe<Scalars['Float']>;
    uid: Scalars['Int'];
}

export class UserBlackList {
    __typename?: 'UserBlackList';
    blacklisted?: Maybe<Scalars['Boolean']>;
    deposit_threshold?: Maybe<Scalars['Float']>;
    items?: Maybe<Array<BlackListItem>>;
}

export class UserBlackListInput {
    blacklisted?: Maybe<Scalars['Boolean']>;
    deposit_threshold?: Maybe<Scalars['Float']>;
    items?: Maybe<Array<BlackListItemInput>>;
}

export enum UserBusinessPool {
    Pkw = 'PKW',
    Wpk = 'WPK',
}

export class UserChallengeQuestionInput {
    is_enabled?: Maybe<Scalars['Boolean']>;
    uid?: Maybe<Scalars['String']>;
}

export class UserClub {
    __typename?: 'UserClub';
    club_id: Scalars['String'];
    uid: Scalars['String'];
}

export class UserClubsFilter {
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class UserClubsPage {
    __typename?: 'UserClubsPage';
    items?: Maybe<Array<Maybe<UserClub>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class UserContactInfo {
    __typename?: 'UserContactInfo';
    email?: Maybe<Scalars['String']>;
    mobile?: Maybe<PhoneNumber>;
}

export class UserContactInfoInput {
    email?: Maybe<Scalars['String']>;
    mobile?: Maybe<PhoneNumberInput>;
}

export class UserGame {
    __typename?: 'UserGame';
    action?: Maybe<ActionType>;
    community_cards?: Maybe<Array<CardItem>>;
    currency: Scalars['String'];
    final_balance: Scalars['Float'];
    game_id: Scalars['String'];
    game_type: GameType;
    initial_balance: Scalars['Float'];
    private_cards?: Maybe<Array<CardItem>>;
    result: Scalars['Boolean'];
    round?: Maybe<BettingRound>;
    started_at: Timestamp;
    total_pot: Scalars['Float'];
    uid: Scalars['String'];
    username: Scalars['String'];
    winner_username: Scalars['String'];
}

export class UserGameInput {
    action?: Maybe<ActionType>;
    community_cards?: Maybe<Array<CardItemInput>>;
    currency: Scalars['String'];
    final_balance: Scalars['Float'];
    game_id: Scalars['String'];
    game_type: GameType;
    initial_balance: Scalars['Float'];
    private_cards?: Maybe<Array<CardItemInput>>;
    result: Scalars['Boolean'];
    round?: Maybe<BettingRound>;
    started_at?: Maybe<TimestampInput>;
    total_pot: Scalars['Float'];
    uid: Scalars['String'];
    username: Scalars['String'];
    winner_username: Scalars['String'];
}

export class UserGamePage {
    __typename?: 'UserGamePage';
    items?: Maybe<Array<Maybe<UserGame>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class UserIdentityInfo {
    __typename?: 'UserIdentityInfo';
    last_ssn_digits?: Maybe<Scalars['String']>;
}

export class UserIdentityInfoInput {
    last_ssn_digits?: Maybe<Scalars['String']>;
}

export class UserIdentityInput {
    address?: Maybe<UserAddressInfoInput>;
    birthday?: Maybe<TimestampInput>;
    first_name?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    last_name?: Maybe<Scalars['String']>;
    last_ssn_digits?: Maybe<Scalars['String']>;
    nickname?: Maybe<Scalars['String']>;
    platform?: Maybe<Platform>;
    post_code?: Maybe<Scalars['String']>;
    uid?: Maybe<Scalars['String']>;
}

export class UserKycPage {
    __typename?: 'UserKYCPage';
    items?: Maybe<Array<Maybe<KycCheckResults>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class UserLabel {
    __typename?: 'UserLabel';
    group: UserLabelGroup;
    id: Scalars['String'];
    name: Scalars['String'];
}

export class UserLabelGroup {
    __typename?: 'UserLabelGroup';
    color: Scalars['String'];
    id: Scalars['String'];
    name: Scalars['String'];
    source_type?: Maybe<LabelGroupSourceType>;
}

export class UserLabelGroupInput {
    color: Scalars['String'];
    id: Scalars['String'];
    name: Scalars['String'];
    source_type?: Maybe<LabelGroupSourceType>;
}

export class UserLabelInput {
    group: UserLabelGroupInput;
    id: Scalars['String'];
    name: Scalars['String'];
}

export class UserLogin {
    __typename?: 'UserLogin';
    connection_type?: Maybe<Scalars['String']>;
    correlation_id: Scalars['String'];
    current_balance?: Maybe<Scalars['Float']>;
    device?: Maybe<Device>;
    ip?: Maybe<Scalars['String']>;
    ip_quality_score?: Maybe<Scalars['Float']>;
    isp?: Maybe<Scalars['String']>;
    language?: Maybe<Scalars['String']>;
    location?: Maybe<Location>;
    logged_at_ts?: Maybe<Timestamp>;
    login_status?: Maybe<LoginStatus>;
    login_type?: Maybe<LoginType>;
    logout_info?: Maybe<LogoutInfo>;
    recaptcha_reasons?: Maybe<Array<Scalars['String']>>;
    recaptcha_score?: Maybe<Scalars['Float']>;
    session_id?: Maybe<Scalars['String']>;
    uid: Scalars['String'];
    username?: Maybe<Scalars['String']>;
    vpn?: Maybe<Scalars['Boolean']>;
}

export class UserLoginFilter {
    logged_at_ts?: Maybe<DateRange>;
    players_groups?: Maybe<Array<PlayersGroup>>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class UserLoginInput {
    connection_type?: Maybe<Scalars['String']>;
    correlation_id: Scalars['String'];
    current_balance?: Maybe<Scalars['Float']>;
    device?: Maybe<DeviceInput>;
    ip?: Maybe<Scalars['String']>;
    ip_quality_score?: Maybe<Scalars['Float']>;
    isp?: Maybe<Scalars['String']>;
    language?: Maybe<Scalars['String']>;
    location?: Maybe<LocationInput>;
    logged_at_ts?: Maybe<TimestampInput>;
    login_status?: Maybe<LoginStatus>;
    login_type?: Maybe<LoginType>;
    recaptcha_reasons?: Maybe<Array<Scalars['String']>>;
    recaptcha_score?: Maybe<Scalars['Float']>;
    session_id?: Maybe<Scalars['String']>;
    uid: Scalars['String'];
    username?: Maybe<Scalars['String']>;
    vpn?: Maybe<Scalars['Boolean']>;
}

export class UserLoginPage {
    __typename?: 'UserLoginPage';
    items?: Maybe<Array<Maybe<UserLogin>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class UserLogout {
    __typename?: 'UserLogout';
    logged_out_at_ts?: Maybe<Timestamp>;
    reason?: Maybe<Scalars['String']>;
    session_id?: Maybe<Scalars['String']>;
    uid: Scalars['String'];
}

export class UserLogoutInput {
    logged_out_at_ts?: Maybe<TimestampInput>;
    reason?: Maybe<Scalars['String']>;
    session_id?: Maybe<Scalars['String']>;
    uid: Scalars['String'];
}

export class UserMarketingInfo {
    __typename?: 'UserMarketingInfo';
    register_marketing_code?: Maybe<Scalars['String']>;
    register_player_bonus?: Maybe<PlayerBonus>;
}

export class UserMarketingInfoInput {
    register_marketing_code?: Maybe<Scalars['String']>;
    register_player_bonus?: Maybe<PlayerBonusInput>;
}

export class UserNetworkProfile {
    __typename?: 'UserNetworkProfile';
    platform: Platform;
    uid: Scalars['String'];
}

export class UserNetworkProfileInput {
    platform: Platform;
    uid: Scalars['String'];
}

export class UserPage {
    __typename?: 'UserPage';
    items?: Maybe<Array<Maybe<UserProfile>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class UserProfile {
    __typename?: 'UserProfile';
    /**
     * shows if player can login into the app
     *
     * default value: ACTIVE
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * ** For agents: ** auth token contains `agent:read` AND (player is an agent which called the query OR his direct referee OR his downstream referee)
     */
    account_status?: Maybe<UserAccountStatus>;
    /**
     * contains information about state, city and home address
     *
     * ### Policies: ###
     *
     * auth token contains `entity-all:read`
     */
    address?: Maybe<UserAddressInfo>;
    affiliate?: Maybe<Affiliates>;
    agent_info?: Maybe<AgentInfo>;
    aml_labels?: Maybe<Array<Maybe<AmlLabel>>>;
    avg_binary_mistake_score?: Maybe<Scalars['Float']>;
    bb_100?: Maybe<Scalars['Float']>;
    birthday?: Maybe<Timestamp>;
    black_list?: Maybe<UserBlackList>;
    blocked_stakes_access?: Maybe<Array<Maybe<GameTable>>>;
    brand?: Maybe<Brand>;
    business_pool?: Maybe<UserBusinessPool>;
    /**
     * shows if player can access casino games
     *
     * default value: UNLOCKED
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * ** For agents: ** auth token contains `agent:read` AND (player is an agent which called the query OR his direct referee OR his downstream referee)
     */
    casino_status?: Maybe<FeatureAccessStatus>;
    club?: Maybe<Scalars['Int']>;
    contact?: Maybe<UserContactInfo>;
    countries?: Maybe<Array<Maybe<Scalars['String']>>>;
    date_of_joining?: Maybe<Timestamp>;
    deposit_status?: Maybe<FeatureAccessStatus>;
    devices?: Maybe<Array<Maybe<Device>>>;
    distance_score?: Maybe<Scalars['Float']>;
    evidence?: Maybe<Array<Maybe<Scalars['String']>>>;
    finance?: Maybe<PlayerFinanceSummary>;
    first_name?: Maybe<Scalars['String']>;
    friend_groups?: Maybe<Array<Maybe<FriendGroup>>>;
    gender?: Maybe<Gender>;
    identity_info?: Maybe<UserIdentityInfo>;
    ip_addresses?: Maybe<Array<Maybe<Scalars['String']>>>;
    ip_quality_score?: Maybe<Scalars['Float']>;
    kyc_action?: Maybe<Scalars['String']>;
    labels?: Maybe<Array<UserLabel>>;
    language?: Maybe<Scalars['String']>;
    last_name?: Maybe<Scalars['String']>;
    last_region?: Maybe<Scalars['String']>;
    latest_kyc?: Maybe<ActiveAccountVerification>;
    latest_login?: Maybe<UserLogin>;
    license_type?: Maybe<Scalars['String']>;
    lobby_access_status?: Maybe<FeatureAccessStatus>;
    marketing_info?: Maybe<UserMarketingInfo>;
    most_played_stake?: Maybe<Scalars['Float']>;
    networks?: Maybe<Array<UserNetworkProfile>>;
    nickname?: Maybe<Scalars['String']>;
    num_linked_bots_by_bank_card?: Maybe<Scalars['Int']>;
    num_linked_bots_by_bank_card_name?: Maybe<Scalars['Int']>;
    num_linked_bots_by_devices?: Maybe<Scalars['Int']>;
    num_linked_bots_by_ips?: Maybe<Scalars['Int']>;
    num_linked_bots_by_subnets?: Maybe<Scalars['Int']>;
    num_linked_members_by_bank_card?: Maybe<Scalars['Int']>;
    num_linked_members_by_bank_card_name?: Maybe<Scalars['Int']>;
    num_linked_members_by_devices?: Maybe<Scalars['Int']>;
    num_linked_members_by_subnets?: Maybe<Scalars['Int']>;
    p2p_transfer_status?: Maybe<FeatureAccessStatus>;
    platform?: Maybe<Platform>;
    players_groups?: Maybe<Array<Maybe<PlayersGroup>>>;
    pool_id?: Maybe<Scalars['String']>;
    punishment_status?: Maybe<SecurityPunishmentStatus>;
    recaptcha_score?: Maybe<Scalars['Float']>;
    referral?: Maybe<Referrals>;
    region_player_info?: Maybe<RegionPlayerInfo>;
    remote_logins?: Maybe<Scalars['Int']>;
    security?: Maybe<PlayerSecuritySummary>;
    security_cases?: Maybe<Array<Maybe<SecurityCase>>>;
    security_settings?: Maybe<UserSecuritySettings>;
    /**
     * shows sportsbook transactions is available for player
     *
     * default value: UNLOCKED
     *
     * ### Policies: ###
     *
     * ** For all users: ** auth token contains `entity-all:read`
     *
     * ** For agents: ** auth token contains `agent:read` AND (player is an agent which called the query OR his direct referee OR his downstream referee)
     */
    sportsbook_status?: Maybe<FeatureAccessStatus>;
    uid: Scalars['String'];
    username?: Maybe<Scalars['String']>;
    verification_info?: Maybe<PlayerVerificationInfo>;
    vpn?: Maybe<Scalars['Boolean']>;
    win_total?: Maybe<Scalars['Float']>;
    withdrawal_limits?: Maybe<Array<Maybe<SecurityLimitHistory>>>;
    withdrawal_status?: Maybe<FeatureAccessStatus>;
}

export class UserProfileFilter {
    account_status?: Maybe<Array<Maybe<UserAccountStatus>>>;
    birthday?: Maybe<DateRange>;
    casino_ggr?: Maybe<NumRange>;
    date_of_joining?: Maybe<DateRange>;
    deposit_status?: Maybe<Array<FeatureAccessStatus>>;
    deposit_total?: Maybe<NumRange>;
    kyc_status?: Maybe<Array<KycStatus>>;
    players_groups?: Maybe<Array<PlayersGroup>>;
    poker_ggr?: Maybe<NumRange>;
    referrer?: Maybe<ReferrerFilter>;
    security_cases?: Maybe<Array<SecurityCaseType>>;
    sports_ggr?: Maybe<NumRange>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
    withdrawal_status?: Maybe<Array<FeatureAccessStatus>>;
    withdrawal_total?: Maybe<NumRange>;
}

export class UserProfileInput {
    account_status?: Maybe<UserAccountStatus>;
    account_verification_status?: Maybe<AccountVerificationStatus>;
    action_by_uid?: Maybe<Scalars['String']>;
    address?: Maybe<UserAddressInfoInput>;
    affiliate?: Maybe<AffiliatesInput>;
    agent_info?: Maybe<AgentInfoInput>;
    avg_binary_mistake_score?: Maybe<Scalars['Float']>;
    balance?: Maybe<PlayerBalanceSummaryInput>;
    bb_100?: Maybe<Scalars['Float']>;
    birthday?: Maybe<TimestampInput>;
    black_list?: Maybe<UserBlackListInput>;
    blocked_stakes_access?: Maybe<Array<Maybe<GameTableInput>>>;
    brand?: Maybe<Brand>;
    business_pool?: Maybe<UserBusinessPool>;
    club?: Maybe<Scalars['Int']>;
    contact?: Maybe<UserContactInfoInput>;
    countries?: Maybe<Array<Maybe<Scalars['String']>>>;
    date_of_joining?: Maybe<TimestampInput>;
    deposit_status?: Maybe<FeatureAccessStatus>;
    devices?: Maybe<Array<Maybe<DeviceInput>>>;
    distance_score?: Maybe<Scalars['Float']>;
    evidence?: Maybe<Array<Maybe<Scalars['String']>>>;
    finance?: Maybe<PlayerFinanceSummaryInput>;
    first_name?: Maybe<Scalars['String']>;
    friend_groups?: Maybe<Array<Maybe<FriendGroupInput>>>;
    gender?: Maybe<Gender>;
    identity_info?: Maybe<UserIdentityInfoInput>;
    ip_addresses?: Maybe<Array<Maybe<Scalars['String']>>>;
    ip_quality_score?: Maybe<Scalars['Float']>;
    kyc_action?: Maybe<Scalars['String']>;
    kyc_status?: Maybe<KycStatus>;
    language?: Maybe<Scalars['String']>;
    last_name?: Maybe<Scalars['String']>;
    last_region?: Maybe<Scalars['String']>;
    latest_kyc?: Maybe<ActiveAccountVerificationInput>;
    latest_login?: Maybe<UserLoginInput>;
    license_type?: Maybe<Scalars['String']>;
    lobby_access_status?: Maybe<FeatureAccessStatus>;
    marketing_info?: Maybe<UserMarketingInfoInput>;
    most_played_stake?: Maybe<Scalars['Float']>;
    networks?: Maybe<Array<UserNetworkProfileInput>>;
    nickname?: Maybe<Scalars['String']>;
    num_linked_bots_by_bank_card?: Maybe<Scalars['Int']>;
    num_linked_bots_by_bank_card_name?: Maybe<Scalars['Int']>;
    num_linked_bots_by_devices?: Maybe<Scalars['Int']>;
    num_linked_bots_by_ips?: Maybe<Scalars['Int']>;
    num_linked_bots_by_subnets?: Maybe<Scalars['Int']>;
    num_linked_members_by_bank_card?: Maybe<Scalars['Int']>;
    num_linked_members_by_bank_card_name?: Maybe<Scalars['Int']>;
    num_linked_members_by_devices?: Maybe<Scalars['Int']>;
    num_linked_members_by_subnets?: Maybe<Scalars['Int']>;
    p2p_transfer_status?: Maybe<FeatureAccessStatus>;
    pii_manual?: Maybe<Scalars['Boolean']>;
    platform?: Maybe<Platform>;
    players_groups?: Maybe<Array<Maybe<PlayersGroup>>>;
    pool_id?: Maybe<Scalars['String']>;
    punishment_status?: Maybe<SecurityPunishmentStatus>;
    recaptcha_score?: Maybe<Scalars['Float']>;
    referral?: Maybe<ReferralsInput>;
    region_player_info?: Maybe<RegionPlayerInfoInput>;
    remote_logins?: Maybe<Scalars['Int']>;
    security?: Maybe<PlayerSecuritySummaryInput>;
    security_cases?: Maybe<Array<Maybe<SecurityCaseInput>>>;
    security_settings?: Maybe<UserSecuritySettingsInput>;
    uid: Scalars['String'];
    username?: Maybe<Scalars['String']>;
    verification_info?: Maybe<PlayerVerificationInfoInput>;
    vpn?: Maybe<Scalars['Boolean']>;
    win_total?: Maybe<Scalars['Float']>;
    withdrawal_limits?: Maybe<Array<Maybe<SecurityLimitHistoryInput>>>;
    withdrawal_status?: Maybe<FeatureAccessStatus>;
}

export class UserRgLimitsPage {
    __typename?: 'UserRGLimitsPage';
    items?: Maybe<Array<Maybe<RgUserLimits>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class UserReCaptchaScore {
    __typename?: 'UserReCaptchaScore';
    reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
    score?: Maybe<Scalars['Float']>;
    uid?: Maybe<Scalars['String']>;
    updated_at_ts?: Maybe<Timestamp>;
}

export class UserReCaptchaScoreFilter {
    score?: Maybe<NumRange>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
    updated_at_ts?: Maybe<DateRange>;
}

export class UserReCaptchaScoreInput {
    reasons?: Maybe<Array<Scalars['String']>>;
    score: Scalars['Float'];
    uid: Scalars['String'];
    updated_at_ts: TimestampInput;
}

export class UserReCaptchaScorePage {
    __typename?: 'UserReCaptchaScorePage';
    items?: Maybe<Array<Maybe<UserReCaptchaScore>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class UserReputationScore {
    __typename?: 'UserReputationScore';
    latest_time?: Maybe<Timestamp>;
    login_name?: Maybe<Scalars['Int']>;
    plat?: Maybe<Scalars['Int']>;
    reputation?: Maybe<Scalars['Int']>;
    update_time?: Maybe<Timestamp>;
}

export class UserReputationScoreFilter {
    latest_time?: Maybe<DateRange>;
    update_time?: Maybe<DateRange>;
}

export class UserReputationScoreInput {
    latest_time?: Maybe<TimestampInput>;
    login_name?: Maybe<Scalars['Int']>;
    plat?: Maybe<Scalars['Int']>;
    reputation?: Maybe<Scalars['Int']>;
    update_time?: Maybe<TimestampInput>;
}

export class UserReputationScorePage {
    __typename?: 'UserReputationScorePage';
    items?: Maybe<Array<UserReputationScore>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class UserSecuritySettings {
    __typename?: 'UserSecuritySettings';
    is_2fa_enabled?: Maybe<Scalars['Boolean']>;
    is_challenge_questions_enabled?: Maybe<Scalars['Boolean']>;
}

export class UserSecuritySettingsInput {
    is_2fa_enabled?: Maybe<Scalars['Boolean']>;
    is_challenge_questions_enabled?: Maybe<Scalars['Boolean']>;
}

export class UserStatistics {
    __typename?: 'UserStatistics';
    AF?: Maybe<Scalars['Float']>;
    AF_flop?: Maybe<Scalars['Float']>;
    AF_river?: Maybe<Scalars['Float']>;
    AF_turn?: Maybe<Scalars['Float']>;
    AFq?: Maybe<Scalars['Float']>;
    AFq_flop?: Maybe<Scalars['Float']>;
    AFq_river?: Maybe<Scalars['Float']>;
    AFq_turn?: Maybe<Scalars['Float']>;
    BB_100?: Maybe<Scalars['Float']>;
    PFR?: Maybe<Scalars['Float']>;
    RFI?: Maybe<Scalars['Float']>;
    VPIP?: Maybe<Scalars['Float']>;
    VS_three_bet_call?: Maybe<Scalars['Float']>;
    VS_three_bet_raise?: Maybe<Scalars['Float']>;
    WSD?: Maybe<Scalars['Float']>;
    WTSD?: Maybe<Scalars['Float']>;
    WWSF?: Maybe<Scalars['Float']>;
    buy_insurance_times?: Maybe<Scalars['Long']>;
    cbet_ip?: Maybe<Scalars['Float']>;
    cbet_muti?: Maybe<Scalars['Float']>;
    cbet_oop?: Maybe<Scalars['Float']>;
    cold_call?: Maybe<Scalars['Float']>;
    created_at: Timestamp;
    curdrawin?: Maybe<Scalars['Float']>;
    drawin?: Maybe<Scalars['Float']>;
    flop_cb_vs_raise_call?: Maybe<Scalars['Float']>;
    flop_cb_vs_raise_fold?: Maybe<Scalars['Float']>;
    flop_cb_vs_raise_raise?: Maybe<Scalars['Float']>;
    flop_cbet?: Maybe<Scalars['Float']>;
    flop_donk_bet?: Maybe<Scalars['Float']>;
    flop_donk_bet_vs_raise_call?: Maybe<Scalars['Float']>;
    flop_donk_bet_vs_raise_fold?: Maybe<Scalars['Float']>;
    flop_donk_bet_vs_raise_raise?: Maybe<Scalars['Float']>;
    flop_vs_donk_bet_call?: Maybe<Scalars['Float']>;
    flop_vs_donk_bet_fold?: Maybe<Scalars['Float']>;
    flop_vs_donk_bet_raise?: Maybe<Scalars['Float']>;
    game?: Maybe<Scalars['Long']>;
    gametype: UserStatisticsGameType;
    insurance_total?: Maybe<Scalars['Float']>;
    insurancebetamount?: Maybe<Scalars['Float']>;
    insurancewinbet?: Maybe<Scalars['Float']>;
    jack_total?: Maybe<Scalars['Float']>;
    jackwinbet?: Maybe<Scalars['Float']>;
    limp?: Maybe<Scalars['Float']>;
    openfold?: Maybe<Scalars['Float']>;
    openlimp?: Maybe<Scalars['Float']>;
    player?: Maybe<Scalars['Int']>;
    playerid: Scalars['String'];
    poker_total?: Maybe<Scalars['Float']>;
    rfi_four_bet?: Maybe<Scalars['Float']>;
    river_cb_vs_raise_call?: Maybe<Scalars['Float']>;
    river_cb_vs_raise_fold?: Maybe<Scalars['Float']>;
    river_cb_vs_raise_raise?: Maybe<Scalars['Float']>;
    river_cbet?: Maybe<Scalars['Float']>;
    river_donk_bet?: Maybe<Scalars['Float']>;
    river_donk_bet_vs_raise_call?: Maybe<Scalars['Float']>;
    river_donk_bet_vs_raise_fold?: Maybe<Scalars['Float']>;
    river_donk_bet_vs_raise_raise?: Maybe<Scalars['Float']>;
    river_vs_donk_bet_call?: Maybe<Scalars['Float']>;
    river_vs_donk_bet_fold?: Maybe<Scalars['Float']>;
    river_vs_donk_bet_raise?: Maybe<Scalars['Float']>;
    seat?: Maybe<Scalars['String']>;
    squeeze?: Maybe<Scalars['Float']>;
    stake?: Maybe<Scalars['Float']>;
    steal?: Maybe<Scalars['Float']>;
    three_bet_call?: Maybe<Scalars['Float']>;
    three_bet_fold?: Maybe<Scalars['Float']>;
    three_bet_raise?: Maybe<Scalars['Float']>;
    turn_cb_vs_raise_call?: Maybe<Scalars['Float']>;
    turn_cb_vs_raise_fold?: Maybe<Scalars['Float']>;
    turn_cb_vs_raise_raise?: Maybe<Scalars['Float']>;
    turn_cbet?: Maybe<Scalars['Float']>;
    turn_donk_bet?: Maybe<Scalars['Float']>;
    turn_donk_bet_vs_raise_call?: Maybe<Scalars['Float']>;
    turn_donk_bet_vs_raise_fold?: Maybe<Scalars['Float']>;
    turn_donk_bet_vs_raise_raise?: Maybe<Scalars['Float']>;
    turn_vs_donk_bet_call?: Maybe<Scalars['Float']>;
    turn_vs_donk_bet_fold?: Maybe<Scalars['Float']>;
    turn_vs_donk_bet_raise?: Maybe<Scalars['Float']>;
    vs_three_bet_fold?: Maybe<Scalars['Float']>;
    wintotal?: Maybe<Scalars['Float']>;
}

export class UserStatisticsFilter {
    created_at?: Maybe<DateRange>;
    players_group?: Maybe<Array<PlayersGroup>>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export enum UserStatisticsGameType {
    Nl = 'NL',
    Plo = 'PLO',
    Sd = 'SD',
}

export class UserStatisticsInput {
    AF?: Maybe<Scalars['Float']>;
    AF_flop?: Maybe<Scalars['Float']>;
    AF_river?: Maybe<Scalars['Float']>;
    AF_turn?: Maybe<Scalars['Float']>;
    AFq?: Maybe<Scalars['Float']>;
    AFq_flop?: Maybe<Scalars['Float']>;
    AFq_river?: Maybe<Scalars['Float']>;
    AFq_turn?: Maybe<Scalars['Float']>;
    BB_100?: Maybe<Scalars['Float']>;
    PFR?: Maybe<Scalars['Float']>;
    RFI?: Maybe<Scalars['Float']>;
    VPIP?: Maybe<Scalars['Float']>;
    VS_three_bet_call?: Maybe<Scalars['Float']>;
    VS_three_bet_raise?: Maybe<Scalars['Float']>;
    WSD?: Maybe<Scalars['Float']>;
    WTSD?: Maybe<Scalars['Float']>;
    WWSF?: Maybe<Scalars['Float']>;
    buy_insurance_times?: Maybe<Scalars['Long']>;
    cbet_ip?: Maybe<Scalars['Float']>;
    cbet_muti?: Maybe<Scalars['Float']>;
    cbet_oop?: Maybe<Scalars['Float']>;
    cold_call?: Maybe<Scalars['Float']>;
    created_at: TimestampInput;
    curdrawin?: Maybe<Scalars['Float']>;
    drawin?: Maybe<Scalars['Float']>;
    flop_cb_vs_raise_call?: Maybe<Scalars['Float']>;
    flop_cb_vs_raise_fold?: Maybe<Scalars['Float']>;
    flop_cb_vs_raise_raise?: Maybe<Scalars['Float']>;
    flop_cbet?: Maybe<Scalars['Float']>;
    flop_donk_bet?: Maybe<Scalars['Float']>;
    flop_donk_bet_vs_raise_call?: Maybe<Scalars['Float']>;
    flop_donk_bet_vs_raise_fold?: Maybe<Scalars['Float']>;
    flop_donk_bet_vs_raise_raise?: Maybe<Scalars['Float']>;
    flop_vs_donk_bet_call?: Maybe<Scalars['Float']>;
    flop_vs_donk_bet_fold?: Maybe<Scalars['Float']>;
    flop_vs_donk_bet_raise?: Maybe<Scalars['Float']>;
    game?: Maybe<Scalars['Long']>;
    gametype: UserStatisticsGameType;
    insurance_total?: Maybe<Scalars['Float']>;
    insurancebetamount?: Maybe<Scalars['Float']>;
    insurancewinbet?: Maybe<Scalars['Float']>;
    jack_total?: Maybe<Scalars['Float']>;
    jackwinbet?: Maybe<Scalars['Float']>;
    limp?: Maybe<Scalars['Float']>;
    openfold?: Maybe<Scalars['Float']>;
    openlimp?: Maybe<Scalars['Float']>;
    player: Scalars['Int'];
    playerid: Scalars['String'];
    poker_total?: Maybe<Scalars['Float']>;
    rfi_four_bet?: Maybe<Scalars['Float']>;
    river_cb_vs_raise_call?: Maybe<Scalars['Float']>;
    river_cb_vs_raise_fold?: Maybe<Scalars['Float']>;
    river_cb_vs_raise_raise?: Maybe<Scalars['Float']>;
    river_cbet?: Maybe<Scalars['Float']>;
    river_donk_bet?: Maybe<Scalars['Float']>;
    river_donk_bet_vs_raise_call?: Maybe<Scalars['Float']>;
    river_donk_bet_vs_raise_fold?: Maybe<Scalars['Float']>;
    river_donk_bet_vs_raise_raise?: Maybe<Scalars['Float']>;
    river_vs_donk_bet_call?: Maybe<Scalars['Float']>;
    river_vs_donk_bet_fold?: Maybe<Scalars['Float']>;
    river_vs_donk_bet_raise?: Maybe<Scalars['Float']>;
    seat: Scalars['String'];
    squeeze?: Maybe<Scalars['Float']>;
    stake: Scalars['Float'];
    steal?: Maybe<Scalars['Float']>;
    three_bet_call?: Maybe<Scalars['Float']>;
    three_bet_fold?: Maybe<Scalars['Float']>;
    three_bet_raise?: Maybe<Scalars['Float']>;
    turn_cb_vs_raise_call?: Maybe<Scalars['Float']>;
    turn_cb_vs_raise_fold?: Maybe<Scalars['Float']>;
    turn_cb_vs_raise_raise?: Maybe<Scalars['Float']>;
    turn_cbet?: Maybe<Scalars['Float']>;
    turn_donk_bet?: Maybe<Scalars['Float']>;
    turn_donk_bet_vs_raise_call?: Maybe<Scalars['Float']>;
    turn_donk_bet_vs_raise_fold?: Maybe<Scalars['Float']>;
    turn_donk_bet_vs_raise_raise?: Maybe<Scalars['Float']>;
    turn_vs_donk_bet_call?: Maybe<Scalars['Float']>;
    turn_vs_donk_bet_fold?: Maybe<Scalars['Float']>;
    turn_vs_donk_bet_raise?: Maybe<Scalars['Float']>;
    vs_three_bet_fold?: Maybe<Scalars['Float']>;
    wintotal?: Maybe<Scalars['Float']>;
}

export class UserStatisticsPage {
    __typename?: 'UserStatisticsPage';
    items?: Maybe<Array<UserStatistics>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class UserTransactionFilter {
    account_status?: Maybe<Array<UserAccountStatus>>;
    account_verification_status?: Maybe<Array<AccountVerificationStatus>>;
    amount?: Maybe<NumRange>;
    iso_alpha2_country_code?: Maybe<Array<Maybe<Scalars['String']>>>;
    payment_option?: Maybe<Array<Maybe<PaymentOption>>>;
    reason_code?: Maybe<Array<ReasonCode>>;
    referrer?: Maybe<ReferrerFilter>;
    show_total_amount?: Maybe<Scalars['Boolean']>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
    transaction_started_ts?: Maybe<DateRange>;
    transaction_status?: Maybe<Array<Maybe<TransactionStatus>>>;
    transaction_types?: Maybe<Array<Maybe<TransactionType>>>;
    transaction_updated_ts?: Maybe<DateRange>;
}

export class UserTransactionPage {
    __typename?: 'UserTransactionPage';
    items?: Maybe<Array<Maybe<Transaction>>>;
    total_amount?: Maybe<Scalars['Float']>;
    total_count?: Maybe<Scalars['Int']>;
}

export class ValidationResult {
    __typename?: 'ValidationResult';
    message?: Maybe<Scalars['String']>;
    status?: Maybe<ResultType>;
}

export enum Workspace {
    CustomerSupport = 'CustomerSupport',
    Global = 'Global',
    Kyc = 'KYC',
    Payment = 'Payment',
    Security = 'Security',
}

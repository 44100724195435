import React, {ChangeEvent} from 'react';
import {ControllerFieldState} from 'react-hook-form';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';

import {CustomIcon} from '@components/icons';
import {FormSelect} from '@components/input';
import {withLabel} from '@components/label';

import {SelectOption} from 'src/features/module-shared/types';

import {Operator} from './types';

const localized = defineMessages({
    less: {
        id: 'ValueRangeFilter_isLessThan',
        defaultMessage: 'is less than',
    },
    greater: {
        id: 'ValueRangeFilter_isGreaterThan',
        defaultMessage: 'is greater than',
    },
    equals: {
        id: 'ValueRangeFilter_equals',
        defaultMessage: 'equals',
    },
    between: {
        id: 'ValueRangeFilter_between',
        defaultMessage: 'between',
    },
});

const options: SelectOption<Operator>[] = [
    {
        value: 'less',
        label: localized.less,
        startIcon: CustomIcon.LessThan,
    },
    {
        value: 'greater',
        label: localized.greater,
        startIcon: CustomIcon.GreaterThan,
    },
    {
        value: 'equals',
        label: localized.equals,
        startIcon: CustomIcon.Equals,
    },
    {
        value: 'between',
        label: localized.between,
        startIcon: CustomIcon.Between,
    },
];

type OperatorSelectProps = {
    value: Operator;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    key: string;
    label: string | MessageDescriptor;
    fieldState?: ControllerFieldState;
};

export function OperatorSelectInner({value, onChange, key, fieldState}: OperatorSelectProps) {
    return (
        <Box>
            <Box>
                <FormSelect
                    key={key}
                    value={value}
                    onChange={onChange}
                    options={options}
                    fieldState={fieldState}
                    isCompareOptionsAvailable={false}
                />
            </Box>
        </Box>
    );
}

export const OperatorSelect = withLabel(OperatorSelectInner);

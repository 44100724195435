import React from 'react';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ModuleName} from '@models/modules';
import {Policy} from '@auth';

import {FeatureName} from '../features/app/config/types';
import {LackOfPermissionIndicator, withPermissionAndJurisdictionConfig} from '../features/app/permission/PermissionHoc';
import {EmbeddedReport} from '../features/block-embedded-report/components/EmbeddedReport';

export const embeddedReportResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'Embedded Agent Report',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageEmbeddedReport, null, 'read')]),
});

export function PlayerLevelEmbeddedReportInner() {
    return <EmbeddedReport module="PlayerLevelReport" />;
}
export const PlayerLevelEmbeddedReport = withPermissionAndJurisdictionConfig(
    withPolicyAccessCheck(PlayerLevelEmbeddedReportInner, embeddedReportResource),
    {featureName: FeatureName.EmbeddedAgentReport},
    LackOfPermissionIndicator.InfoMessage
);

export function AgentLevelEmbeddedReportInner() {
    return <EmbeddedReport module="AgentLevelReport" />;
}
export const AgentLevelEmbeddedReport = withPermissionAndJurisdictionConfig(
    withPolicyAccessCheck(AgentLevelEmbeddedReportInner, embeddedReportResource),
    {featureName: FeatureName.EmbeddedAgentReport},
    LackOfPermissionIndicator.InfoMessage
);

export function TransferEmbeddedReportInner() {
    return <EmbeddedReport module="TransferReport" />;
}
export const TransferEmbeddedReport = withPermissionAndJurisdictionConfig(
    withPolicyAccessCheck(TransferEmbeddedReportInner, embeddedReportResource),
    {featureName: FeatureName.EmbeddedAgentTransferReport},
    LackOfPermissionIndicator.InfoMessage
);

import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';

import {useDownloadTransaction, UseTransactionDownloadButtonProps} from '../hooks';

const localized = defineMessages({
    buttonLabel: {
        id: 'TransactionDownloadCsvButton_buttonLabel',
        defaultMessage: 'Download CSV',
    },
});

const useStyles = makeStyles()(theme => ({
    transactionDownloadCsvButton: {
        borderRadius: theme.shape.borderRadius * 2,
    },
}));

type TransactionDownloadCsvButtonProps = UseTransactionDownloadButtonProps;

export function TransactionDownloadCsvButton(props: TransactionDownloadCsvButtonProps) {
    const {classes} = useStyles();
    const {isProgress, handleClick} = useDownloadTransaction(props);
    return (
        <Button label={localized.buttonLabel} onClick={handleClick} loading={isProgress} className={classes.transactionDownloadCsvButton} />
    );
}

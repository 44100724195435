import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {UserLogin} from '@models/generated/graphql';
import {BaseFilterKeys, EntityType, UserLoginQueryFields, UserLoginServerFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger} from '@redux/realtime';
import {
    getNonEmptyValueValidator,
    UseDetailsViewEntityProps,
    UseDetailsViewEntityResult,
    UseListViewEntityProps,
    UseListViewEntityResult,
    useViewInit,
} from '@redux/view';
import {maxPageSize} from '@services/types';
import {getFilterString, updateKeyValueFilterArray} from '@utils';

import {Filter, Sorting} from '../../common/types';
import {useAsyncActionState} from '../shared/async-action/hooks';

import {localizedUserLoginGrid} from './components/DataGridUserLogin';
import {userLoginActions} from './actions';
import {UserLoginSortingFields, UserLoginViewModel, UserLoginViewModelKeys} from './types';

type UseDownloadCsvButtonProps = {
    filterString: string;
    filename: string;
    keys: UserLoginViewModelKeys[];
};

type UseDownloadCsvButtonResult = {
    handleClick: () => void;
    isProgress: boolean;
};

export function useDownloadCsvButton({filterString, keys, filename}: UseDownloadCsvButtonProps): UseDownloadCsvButtonResult {
    const pageFilterKey: BaseFilterKeys = 'page';
    const sizeFilterKey: BaseFilterKeys = 'size';
    const dispatch = useDispatch();
    const mapper = useAutoMapper();
    const {formatMessage} = useIntl();
    const {isProgress} = useAsyncActionState(userLoginActions.download);

    const headers = keys?.reduce<Record<UserLoginViewModelKeys, string>>((previousValue, key) => {
        previousValue[key] = formatMessage(localizedUserLoginGrid[key]);
        return previousValue;
    }, {} as Record<UserLoginViewModelKeys, string>);

    const searchFilter = getFilterString(
        filterString,
        true,
        {key: pageFilterKey, value: '1'},
        {key: sizeFilterKey, value: `${maxPageSize}`}
    );

    const fields: UserLoginQueryFields[] = mapper.map<UserLoginViewModelKeys[], UserLoginQueryFields[]>(
        keys,
        nameof<UserLoginViewModelKeys>(),
        nameof<UserLoginQueryFields>()
    );

    function handleClick() {
        dispatch(userLoginActions.download.request({filter: searchFilter, fields, filename, worksheetKeys: keys, headers}));
    }

    return {handleClick, isProgress};
}

export function useUserLoginDetails({
    id,
    fields,
    viewType,
    displayName,
    cleanDelay = 0,
    realtimeMode,
    defaultFilters,
    validateFilter,
}: UseDetailsViewEntityProps<UserLoginServerFilterKeys, UserLoginViewModelKeys>): UseDetailsViewEntityResult<
    UserLogin,
    UserLoginViewModelKeys
> {
    const mapper = useAutoMapper();
    const queryFields: UserLoginQueryFields[] = mapper.map(fields, nameof<UserLoginViewModelKeys>(), nameof<UserLoginQueryFields>());

    const defaultFilter: Filter<unknown, UserLoginServerFilterKeys>[] = updateKeyValueFilterArray(
        [{key: 'id', value: id ?? null}],
        ...(defaultFilters ?? [])
    );
    const {
        items,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
    } = useViewInit<UserLogin, UserLoginServerFilterKeys, UserLoginQueryFields>({
        viewType,
        entity: {
            entity: EntityType.UserLogin,
            fields: queryFields,
        },
        realtime: realtimeMode
            ? {
                  entity: EntityType.UserLogin,
                  mode: realtimeMode,
                  triggers: [{type: RealtimeMessageTrigger.Update}],
              }
            : null,
        displayName,
        defaultFilters: defaultFilter,
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: validateFilter ?? getNonEmptyValueValidator<UserLoginServerFilterKeys>('id'),
        cleanDelay,
    });

    return {item: items?.length ? items[0] : null, handleFilterChange, searchFilter, filterString};
}

export function useUserLoginList({
    realtimeMode,
    triggers,
    fields,
    viewType,
    displayName,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    syncWithUrl,
    validateFilter,
    cleanDelay,
}: UseListViewEntityProps<UserLoginServerFilterKeys, UserLoginViewModelKeys>): UseListViewEntityResult<
    UserLoginViewModel,
    UserLoginViewModelKeys
> {
    const mapper = useAutoMapper();
    const queryFields: UserLoginQueryFields[] = mapper.map(fields, nameof<UserLoginViewModelKeys>(), nameof<UserLoginQueryFields>());

    const {
        items: userLoginList,
        totalCount,
        getSearchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit({
        viewType,
        displayName,
        entity: {
            entity: EntityType.UserLogin,
            fields: queryFields,
        },
        realtime: realtimeMode ? {entity: EntityType.UserLogin, mode: realtimeMode, triggers} : null,
        defaultSorting: mapSortingVm(defaultSorting) ?? [{field: 'logged_at_ts.seconds', sort: 'desc'}],
        defaultFilters,
        defaultPaging,
        syncWithUrl,
        validateFilter,
        cleanDelay,
    });

    const items: UserLoginViewModel[] = userLoginList?.map(i => mapper.map(i, UserLogin, UserLoginViewModel));

    function mapSortingField(fields: UserLoginSortingFields): UserLoginViewModelKeys {
        return mapper.map<UserLoginSortingFields, UserLoginViewModelKeys>(
            fields,
            nameof<UserLoginSortingFields>(),
            nameof<UserLoginViewModelKeys>()
        );
    }

    function mapSortingVmField(fields: UserLoginViewModelKeys): UserLoginSortingFields {
        return mapper.map<UserLoginViewModelKeys, UserLoginSortingFields>(
            fields,
            nameof<UserLoginViewModelKeys>(),
            nameof<UserLoginSortingFields>()
        );
    }

    function mapSortingVm(sorting: Sorting<UserLoginViewModelKeys>[]): Sorting<UserLoginSortingFields>[] {
        return sorting?.map(s => ({...s, field: mapSortingVmField(s.field)}));
    }

    function handleVmSortChange(sorting: Sorting<UserLoginViewModelKeys>[]) {
        handleSortChange(mapSortingVm(sorting));
    }

    return {
        items,
        totalCount,
        searchFilter: getSearchFilter(mapSortingField),
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
        handleFilterChange,
    };
}

import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import moment from 'moment';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {
    BulkMutationInput,
    BulkMutationResponse,
    EntityType,
    ManualTransaction,
    ManualTransactionInput,
    Mutation,
    MutationAddManualTransactionArgs,
    MutationAddManualTransactionsArgs,
    MutationAddP2PTransferBulkOperationArgs,
    MutationClaimRevenueShareArgs,
    Note,
    NoteInput,
    NoteType,
    QueryGetUsersTransactionsArgs,
    ResultType,
    RevenueShareClaimResult,
    Sorting as GqlSorting,
    TransactionStatus,
    UserTransactionFilter,
    Workspace,
} from '@models/generated/graphql';
import {applyLocationAttrs, ITracingService, map, mergeMap} from '@otel';
import {Filter, TransactionFilterKeys, TransactionQueryFields, TransactionTextFilterKeys} from '@redux/entity';
import {EntityBaseGqlService, IEntityReadService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';
import {INoteService} from '@services/noteService';
import {PaymentApiService} from '@services/rest-api';
import {momentToTimestampSeconds, partialMatchWithSpecialSymbols} from '@utils';

import {SetWithdrawalStatusRequestPayload} from 'src/features/block-transaction-actions/actions';
import {EditWithdrawalStatusModel} from 'src/features/transactions/types';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';
import {GqlMutationRequest, RestRequest, ServerResponseStatus, ServiceResponsePayload} from './types';

const transactionOperationsPrefix = 'TRANSACTION';
const transactionOperations = {
    TRANSACTION_REDEEM: `${transactionOperationsPrefix} REDEEM`,
} as const;

export interface ITransactionService extends IEntityReadService {
    setWithdrawalRiskStatus(model: EditWithdrawalStatusModel): Observable<ServiceResponsePayload<RestRequest, unknown>>;

    setWithdrawalPaymentStatus(model: EditWithdrawalStatusModel): Observable<ServiceResponsePayload<RestRequest, unknown>>;

    getCashierLink(transactionId: string): Observable<ServiceResponsePayload<RestRequest, string>>;

    addP2PTransfers(
        payload: BulkMutationInput
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationAddP2PTransferBulkOperationArgs>, BulkMutationResponse>>;

    claimRevenueShare(
        claimRevenueShareArgs: MutationClaimRevenueShareArgs
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationClaimRevenueShareArgs>, RevenueShareClaimResult>>;

    addManualTransaction(
        transactionInput: ManualTransactionInput
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationAddManualTransactionArgs>, ManualTransaction>>;

    addManualTransactions(
        payload: BulkMutationInput
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationAddManualTransactionsArgs>, BulkMutationResponse>>;
}

@injectable()
export class TransactionService
    extends EntityBaseGqlService<QueryGetUsersTransactionsArgs, TransactionQueryFields, TransactionFilterKeys>
    implements ITransactionService
{
    private readonly _paymentApiService: PaymentApiService;
    private readonly _noteService: INoteService;
    private readonly _tracingService: ITracingService;

    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.PaymentApiService) paymentApiService: PaymentApiService,
        @inject(ServiceTypes.NoteService) noteService: INoteService,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.TracingService) tracingService: ITracingService
    ) {
        super(client, mapper, new TransactionRequestBuilder());
        this._paymentApiService = paymentApiService;
        this._noteService = noteService;
        this._tracingService = tracingService;
    }

    public setWithdrawalRiskStatus(model: EditWithdrawalStatusModel): Observable<ServiceResponsePayload<RestRequest, unknown>> {
        return this._paymentApiService
            .setWithdrawalRiskStatus(model)
            .pipe(mergeMap(response => this.getCreateNoteObservable(model, response)));
    }

    public setWithdrawalPaymentStatus(model: EditWithdrawalStatusModel): Observable<ServiceResponsePayload<RestRequest, unknown>> {
        return this._paymentApiService
            .setWithdrawalPaymentStatus(model)
            .pipe(mergeMap(response => this.getCreateNoteObservable(model, response)));
    }

    public getCashierLink(transactionId: string): Observable<ServiceResponsePayload<RestRequest, string>> {
        return this._paymentApiService.getCashierLink(transactionId);
    }

    addP2PTransfers(
        payload: BulkMutationInput
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationAddP2PTransferBulkOperationArgs>, BulkMutationResponse>> {
        return this._service
            .mutate<Mutation, MutationAddP2PTransferBulkOperationArgs>(this.getAddP2PTransferBulkOperationMutation(), {bulkData: payload})
            .pipe(map(res => ({...res, responsePayload: res?.responsePayload?.addP2PTransferBulkOperation})));
    }

    claimRevenueShare(
        claimRevenueShareArgs: MutationClaimRevenueShareArgs
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationClaimRevenueShareArgs>, RevenueShareClaimResult>> {
        const tracer = this._tracingService.getTracer();
        return tracer.startActiveSpan(transactionOperations.TRANSACTION_REDEEM, span => {
            applyLocationAttrs(span);
            return this._service.mutate(this.getClaimRevenueShareMutation(), claimRevenueShareArgs).pipe(
                map(r => {
                    let transactionStatus: ServerResponseStatus;
                    if (r?.responsePayload?.claimRevenueShare?.claimstatus?.status === ResultType.Success) {
                        this._tracingService.endSpanOk(span);
                        transactionStatus = ServerResponseStatus.Success;
                    } else {
                        this._tracingService.endSpanFailed(span, r?.responsePayload?.claimRevenueShare?.claimstatus?.message);
                        transactionStatus = ServerResponseStatus.Failed;
                    }

                    return {...r, status: transactionStatus, responsePayload: r.responsePayload.claimRevenueShare};
                }),
                catchError(error => {
                    this._tracingService.endSpanFailed(span, error?.message);
                    return of({status: ServerResponseStatus.Failed, requestPayload: null, responsePayload: null, message: error?.message});
                })
            );
        });
    }

    addManualTransaction(
        transaction: ManualTransactionInput
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationAddManualTransactionArgs>, ManualTransaction>> {
        return this._service
            .mutate<Mutation, MutationAddManualTransactionArgs>(this.getManualTransactionMutation(), {transaction})
            .pipe(map(r => ({...r, responsePayload: r.responsePayload?.addManualTransaction})));
    }

    addManualTransactions(
        payload: BulkMutationInput
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationAddManualTransactionsArgs>, BulkMutationResponse>> {
        return this._service
            .mutate<Mutation, MutationAddManualTransactionsArgs>(this.getManualTransactionsMutation(), {bulkData: payload})
            .pipe(map(res => ({...res, responsePayload: res?.responsePayload?.addManualTransactions})));
    }

    //TODO: [IGP-2481] Remove after fix for admin id in event log
    //TODO: [BO-2876] Fix withdrawal status change logic after submitting note (handle partial success case when status change successful but sending note failed)
    private getCreateNoteObservable(
        payload: SetWithdrawalStatusRequestPayload,
        response: ServiceResponsePayload<RestRequest, unknown>
    ): Observable<ServiceResponsePayload<RestRequest, unknown>> {
        return this.isNoteShouldBeCreatedAfterStatusChanges(payload, response)
            ? this.createNoteAfterWithdrawalStatusUpdate(payload).pipe(map(() => response))
            : of(response);
    }

    //TODO: [IGP-2481] Remove after fix for admin id in event log
    private createNoteAfterWithdrawalStatusUpdate(model: EditWithdrawalStatusModel): Observable<Note> {
        const action = model.status === TransactionStatus.Rejected ? 'rejected' : 'approved';
        const note: NoteInput = {
            body: `User [@${model.adminName}](${model.adminId}) ${action} the withdrawal ${model.transactionId}`,
            entity: {
                id: model.transactionId,
                type: EntityType.Transaction,
                parent: {
                    id: model.userId,
                    type: EntityType.Player,
                },
            },
            note_type: NoteType.Request,
            users_tagged: [model.adminId],
            workspace: Workspace.Global,
            posted_by_uid: model.adminId,
            posted_at: {seconds: momentToTimestampSeconds(moment())},
            is_pinned: false,
        };

        return this._noteService.addNote(note).pipe(map(r => r.responsePayload));
    }

    private isNoteShouldBeCreatedAfterStatusChanges(
        model: SetWithdrawalStatusRequestPayload,
        response: ServiceResponsePayload<RestRequest, unknown>
    ) {
        return (
            response.status === ServerResponseStatus.Success &&
            (model.status === TransactionStatus.Rejected ||
                model.status === TransactionStatus.RiskAuthorized ||
                model.status === TransactionStatus.InProgress)
        );
    }

    private getAddP2PTransferBulkOperationMutation() {
        return gql`
            mutation AddP2PTransferBulkOperation($bulkData: BulkMutationInput!) {
                addP2PTransferBulkOperation(bulkData: $bulkData) {
                    id
                }
            }
        `;
    }

    private getClaimRevenueShareMutation() {
        return gql`
            mutation ClaimRevenueShare($uid: String!, $claim: RevenueShareClaimInput!) {
                claimRevenueShare(uid: $uid, claim: $claim) {
                    claimstatus {
                        message
                        status
                    }
                    transactionId
                }
            }
        `;
    }

    private getManualTransactionMutation() {
        return gql`
            mutation AddManualTransaction($transaction: ManualTransactionInput!) {
                addManualTransaction(transaction: $transaction) {
                    amount
                    created_by_uid
                    currency
                    payment_method_description
                    reasons {
                        reason_code
                    }
                    transaction_id
                    type
                    uid
                }
            }
        `;
    }

    private getManualTransactionsMutation() {
        return gql`
            mutation AddManualTransactions($bulkData: BulkMutationInput!) {
                addManualTransactions(bulkData: $bulkData) {
                    id
                }
            }
        `;
    }
}

export class TransactionRequestBuilder extends GqlRequestBuilder<
    QueryGetUsersTransactionsArgs,
    TransactionQueryFields,
    TransactionFilterKeys
> {
    protected buildFilter(filter: Filter<TransactionFilterKeys>): {filter: UserTransactionFilter} {
        const referrerId: string =
            this.toGQLStringFilter(filter, 'referrerPlayerId') ?? this.toGQLStringFilter(filter, 'defaultReferrerPlayerId');
        return {
            filter: {
                text: this.getGQLTextFilter(
                    Object.keys(this.filterFieldsMapper).map((key: TransactionTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string, this.transformTextMapper[key])
                    )
                ),
                transaction_status:
                    this.toGQLMultiselectFilter(filter, 'transactionStatus') ??
                    this.toGQLMultiselectFilter(filter, 'defaultTransactionStatus'),
                account_status: this.toGQLMultiselectFilter(filter, 'accountStatus'),
                payment_option: this.toGQLMultiselectFilter(filter, 'paymentOption'),
                transaction_types:
                    this.toGQLMultiselectFilter(filter, 'transactionTypes') ??
                    this.toGQLMultiselectFilter(filter, 'defaultTransactionTypes'),
                account_verification_status: this.toGQLMultiselectFilter(filter, 'accountVerificationStatus'),
                reason_code: this.toGQLMultiselectFilter(filter, 'reasonCode'),
                transaction_started_ts: this.toGQLDateRange(filter['startedTs.from'], filter['startedTs.to']),
                transaction_updated_ts: this.toGQLDateRange(filter.updatedTsFrom, filter.updatedTsTo),
                amount: this.toGQLNumberRangeFilter(filter.amountMin, filter.amountMax),
                iso_alpha2_country_code: this.toGQLMultiselectFilter(filter, 'registrationCountry'),
                referrer: referrerId
                    ? {referrer_player_id: referrerId, is_downstream: this.toGQLBooleanFilter(filter, 'isDownstream'), is_agent: false}
                    : undefined,
                show_total_amount: this.toGQLBooleanFilter(filter, 'show_total_amount'),
            },
        };
    }

    private getStatusLogQueryItems(): TransactionQueryFields[] {
        return ['status_log.status', ...this.getStatusLogLoggedAtQueryItems()];
    }

    private getStatusLogLoggedAtQueryItems(): TransactionQueryFields[] {
        return ['status_log.logged_at.seconds'];
    }

    private getReasonsQueryItems(): TransactionQueryFields[] {
        return ['reasons.reason_type', 'reasons.reason_code', 'reasons.reason_text', ...this.getReasonsCreatedAtTsQueryItems()];
    }

    private getReasonsCreatedAtTsQueryItems(): TransactionQueryFields[] {
        return ['reasons.created_at_ts.seconds'];
    }

    public buildQuery(fields: TransactionQueryFields[]): DocumentNode {
        return gql`
            query GetUserTransactions($filter: UserTransactionFilter, $sort: [Sorting], $start: Int, $end: Int) {
                getUsersTransactions(filter: $filter, sort: $sort, end: $end, start: $start) {
                    items {
                        transaction_id
                        uid @include(if: ${this.hasField(fields, 'uid')})
                        username @include(if: ${this.hasField(fields, 'username')})
                        created_by_uid @include(if: ${this.hasField(fields, 'created_by_uid')})
                        type @include(if: ${this.hasField(fields, 'type')})
                        currency @include(if: ${this.hasField(fields, 'currency')})
                        previous_balance @include(if: ${this.hasField(fields, 'previous_balance')})
                        current_balance @include(if: ${this.hasField(fields, 'current_balance')})
                        current_casino_coin_balance @include(if: ${this.hasField(fields, 'current_casino_coin_balance')})
                        amount @include(if: ${this.hasField(fields, 'amount')})
                        payment_system_uid @include(if: ${this.hasField(fields, 'payment_system_uid')})
                        payment_system_transaction_id @include(if: ${this.hasField(fields, 'payment_system_transaction_id')})
                        iso_alpha2_country_code @include(if: ${this.hasField(fields, 'iso_alpha2_country_code')})
                        contact @include(if: ${this.hasAnyField(fields, ['contact.email', 'contact.mobile'])}) {
                            email @include(if: ${this.hasField(fields, 'contact.email')})
                            mobile @include(if: ${this.hasField(fields, 'contact.mobile')}) {
                                area
                                mobile
                                full_number
                            }
                        }
                        referrer_player_id @include(if: ${this.hasField(fields, 'referrer_player_id')})
                        status_log @include(if: ${this.hasAnyField(fields, this.getStatusLogQueryItems())}) {
                            status @include(if: ${this.hasField(fields, 'status_log.status')})
                            logged_at @include(if: ${this.hasAnyField(fields, this.getStatusLogLoggedAtQueryItems())})  {
                                seconds @include(if: ${this.hasField(fields, 'status_log.logged_at.seconds')})
                            }
                        }
                        transaction_started_ts @include(if: ${this.hasField(fields, 'transaction_started_ts')}) {
                            seconds 
                            nanos 
                        }
                        transaction_updated_ts @include(if: ${this.hasField(fields, 'transaction_updated_ts')}) {
                            seconds
                        }
                        payment_vendor  @include(if: ${this.hasField(fields, 'payment_vendor')})
                        payment_option  @include(if: ${this.hasField(fields, 'payment_option')})
                        payment_method_name  @include(if: ${this.hasField(fields, 'payment_method_name')})
                        payment_method_description  @include(if: ${this.hasField(fields, 'payment_method_description')})
                        transaction_status  @include(if: ${this.hasField(fields, 'transaction_status')})
                        reasons @include(if: ${this.hasAnyField(fields, this.getReasonsQueryItems())})  {
                            reason_type  @include(if: ${this.hasField(fields, 'reasons.reason_type')})
                            reason_code  @include(if: ${this.hasField(fields, 'reasons.reason_code')})
                            reason_text  @include(if: ${this.hasField(fields, 'reasons.reason_text')})
                            created_at_ts @include(if: ${this.hasAnyField(fields, this.getReasonsCreatedAtTsQueryItems())}) {
                                seconds  @include(if: ${this.hasField(fields, 'reasons.created_at_ts.seconds')})
                            }
                        }
                        email @include(if: ${this.hasField(fields, 'email')})
                        register_marketing_code @include(if: ${this.hasField(fields, 'register_marketing_code')})
                        user_labels @include(if: ${this.hasAnyField(fields, this.getLabelsQueryItems())}) {
                            id @include(if: ${this.hasField(fields, 'user_labels.id')})
                            name @include(if: ${this.hasField(fields, 'user_labels.name')})
                            group @include(if: ${this.hasAnyField(fields, this.getLabelsGroupQueryItems())}) {
                                id @include(if: ${this.hasField(fields, 'user_labels.group.id')})
                                color @include(if: ${this.hasField(fields, 'user_labels.group.color')})
                                name @include(if: ${this.hasField(fields, 'user_labels.group.name')})
                            }
                        }
                    }
                    total_amount @include(if: ${this.hasField(fields, 'total_amount')})
                    total_count
                }
            }
        `;
    }

    private getLabelsQueryItems(): TransactionQueryFields[] {
        return ['user_labels.id', 'user_labels.name', ...this.getLabelsGroupQueryItems()];
    }
    private getLabelsGroupQueryItems(): TransactionQueryFields[] {
        return ['user_labels.group.id', 'user_labels.group.color', 'user_labels.group.name'];
    }

    protected buildSort(filter: Filter): {sort?: GqlSorting[]} {
        if (filter.sortOrder || filter.sortField) {
            const sortFields = (filter.sortField as string).split(',');
            const sortOrders = (filter.sortOrder as string).split(',');

            const newSorting = sortFields.flatMap(
                (field, index) =>
                    this.sortFieldsMapper[field as TransactionQueryFields].map(f => ({
                        field: f,
                        order: sortOrders[index],
                    })) ?? {
                        field,
                        order: sortOrders[index],
                    }
            );

            const mappedFilter: Filter = {
                ...filter,
                sortField: newSorting.map(sort => sort.field).join(','),
                sortOrder: newSorting.map(sort => sort.order).join(','),
            };
            return this.buildMultipleSort(mappedFilter);
        } else return {};
    }

    private sortFieldsMapper: Partial<Record<TransactionQueryFields, TransactionQueryFields[]>> = {
        transaction_started_ts: ['transaction_started_ts.seconds', 'transaction_started_ts.nanos'],
        transaction_updated_ts: ['transaction_updated_ts.seconds', 'transaction_updated_ts.nanos'],
    };

    private filterFieldsMapper: Record<TransactionTextFilterKeys, TransactionQueryFields[]> = {
        uid: ['uid'],
        username: ['username'],
        transactionId: ['transaction_id'],
        withdrawalId: ['transaction_id'],
        email: ['email'],
        uidUsernameTransactionId: ['uid', 'username', 'transaction_id'],
        uid_em_un_rmc: ['uid', 'email', 'username', 'register_marketing_code'],
        uid_un_tid_em: ['uid', 'username', 'transaction_id', 'email'],
        paymentMethodName: ['payment_method_name'],
        register_marketing_code: ['register_marketing_code'],
        labels: ['user_labels.id'],
        labelsText: ['user_labels.name'],
    };

    private transformTextMapper: Partial<Record<TransactionTextFilterKeys, (value: string) => string>> = {
        email: this.ignoreCase,
        paymentMethodName: partialMatchWithSpecialSymbols,
        labelsText: this.phraseSearch,
        labels: value => this.phraseListSearch(value, ' '),
    };
}

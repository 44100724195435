import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import LocalizedText from '@components/i18n/LocalizedText';
import {TransactionDuration as TransactionDurationType} from '@models/transaction';
import {getTransactionDuration} from '@models/transaction/utils';

function TransactionDuration(props: TransactionDurationType) {
    const formattedValue = getTransactionDuration(props);
    return <LocalizedText label={formattedValue} />;
}

export const renderTransactionDuration = (params: GridCellParams) => {
    const transactionDurationProps: TransactionDurationType = params.value as TransactionDurationType;

    return <TransactionDuration history={transactionDurationProps?.history} started_at={transactionDurationProps?.started_at} />;
};

import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';

import {usePolicyAccessCheck} from '@access-control';
import {BulkModalContext} from '@components/modal';
import {useService} from '@inversify';
import {FeatureAccessStatus} from '@models/generated/graphql';
import {agentUpdatePolicy} from '@models/permissions/permissions';
import {extendedViewCleanDelay} from '@redux/view';

import {useJurisdictionFeature} from '../../app/config/hooks';
import {FeatureName} from '../../app/config/types';
import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {BulkChooseActionStepButton, useBulkAction} from '../../block-bulk-actions';
import {useUserProfileDetails} from '../../block-user-profile-details';
import {entityTransactionCreateResource, p2pTransactionCreateResource} from '../permissions';
import {LoadP2PTransferStrategy, LoadP2PTransferStrategyRequest} from '../services/loadItemsStrategy';

import {BulkTransactionP2PTransferExecutionStepModal} from './BulkTransactionExecutionStepModal';
import {BulkTransactionP2PTransferApplyStepModal} from './BulkTransactionP2PTransferApplyStepModal';
import {BulkTransactionP2PTransferValidationStepModal} from './BulkTransactionP2PTransferValidationStepModal';
import {BulkTransactionP2PTransferSummaryStepModal} from './BulkTransactionSummaryStepModal';

const localized = defineMessages({
    label: {
        id: 'BulkP2PTransferButton_label',
        defaultMessage: 'P2P Transfer',
    },
});

type BulkP2PTransferButtonProps = {
    uids: string[];
    agentUid: string;
};

const BulkTransactionApplyStepP2PButtonInner = ({uids, agentUid}: BulkP2PTransferButtonProps) => {
    const {item} = useUserProfileDetails({
        viewType: 'P2PTransfer',
        fields: ['uid', 'p2p_transfer_status'],
        id: agentUid,
        cleanDelay: extendedViewCleanDelay,
    });

    const loadStrategy = useService<LoadP2PTransferStrategy>('LoadP2PTransferStrategy');
    const {chooseStep, closeModal} = useContext(BulkModalContext);
    const request: LoadP2PTransferStrategyRequest = {uids, keys: ['uid', 'finance.balance']};
    const {resetBulkState} = useBulkAction({strategy: loadStrategy, request});

    const openApplyStep = () => chooseStep(applyStep);
    const openValidation = () => chooseStep(validationStep);
    const openChangesSummary = () => chooseStep(changesSummaryStep);
    const openExecutionSummary = () => chooseStep(executionSummaryStep);

    const applyStep = <BulkTransactionP2PTransferApplyStepModal onNext={openValidation} onPrev={closeModal} />;
    const validationStep = (
        <BulkTransactionP2PTransferValidationStepModal onNext={openChangesSummary} onPrev={openApplyStep} agentUid={agentUid} />
    );
    const changesSummaryStep = <BulkTransactionP2PTransferSummaryStepModal onNext={openExecutionSummary} onPrev={closeModal} />;
    const executionSummaryStep = <BulkTransactionP2PTransferExecutionStepModal onNext={closeModal} hasRetry={true} />;

    const handleClick = () => {
        resetBulkState();
        openApplyStep();
    };

    const p2pTransferLocked = item?.p2p_transfer_status !== FeatureAccessStatus.Unlocked;

    return (
        <BulkChooseActionStepButton
            disabled={p2pTransferLocked}
            label={localized.label}
            onClick={handleClick}
            color="primary"
            itemsCount={uids?.length}
        />
    );
};

/**
 * @deprecated
 * <p>Use {@link BulkP2PTransferButtonUpdated} instead</p>
 */

const BulkP2PTransferButtonObsolete = withMultiplePermission(BulkTransactionApplyStepP2PButtonInner, {
    allowedPermissions: [agentUpdatePolicy],
});

function BulkP2PTransferButtonUpdated(props: BulkP2PTransferButtonProps) {
    const isP2pTransactionPolicyAvailiable = useJurisdictionFeature({
        featureName: FeatureName.FinanceActionsNewPolicies,
    });

    const isAvailable = usePolicyAccessCheck(
        isP2pTransactionPolicyAvailiable ? p2pTransactionCreateResource : entityTransactionCreateResource
    );

    return isAvailable ? <BulkTransactionApplyStepP2PButtonInner {...props} /> : <></>;
}

export function BulkP2PTransferButton(props: BulkP2PTransferButtonProps) {
    const isAvailable = useJurisdictionFeature({featureName: FeatureName.P2POptionsHideForPHRoles});
    const Component: React.ComponentType<BulkP2PTransferButtonProps> = isAvailable
        ? BulkP2PTransferButtonUpdated
        : BulkP2PTransferButtonObsolete;
    return <Component {...props} />;
}

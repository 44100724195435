import React from 'react';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {
    AmountCurrencyCellProps,
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    labelCellValueGetter,
    MuiDataGridClient,
    MuiDataGridServer,
    renderAmountCurrencyCellWithTooltip,
    renderCellHeader,
    renderCellWithTooltip,
    renderChipCell,
    renderCurrencyCellWithEmptyCheck,
    renderDefaultChipCell,
    renderLabelsCell,
    renderSecurityCasesCell,
    renderTextLink,
    renderTextListWithTooltipCell,
    TextLinkCell,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {CurrencyColumnValue} from '@components/data-grid/types';
import {AccountVerificationStatus, Brand, FeatureAccessStatus, Platform, UserAccountStatus} from '@models/generated/graphql';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {formatTimestamp} from '@utils';

import {RouteUrl} from 'src/common/routeEnums';
import {UserProfileGridItem} from '../types';

import {gridUserProfileLocalizedHeaders} from './DataGridUserProfile.localize';
import {UserPlatform} from './UserPlatform';

export class DataGridUserProfileConfigFactory extends DataGridEntityConfigFactory<UserProfileViewModelKeys, UserProfileGridItem> {
    getColumnConfig(): Partial<Record<UserProfileViewModelKeys, DataGridEntityColDef<UserProfileViewModelKeys, UserProfileGridItem>>> {
        const columnsMapping: Partial<
            Record<UserProfileViewModelKeys, DataGridEntityColDef<UserProfileViewModelKeys, UserProfileGridItem>>
        > = {
            uid: {
                field: 'uid',
                valueGetter: p => this.getUid(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.uid,
                renderCell: renderDefaultChipCell,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            full_name: {
                field: 'full_name',
                valueGetter: p => this.getFullName(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.full_name,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            nickname: {
                field: 'nickname',
                valueGetter: p => this.getNickname(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.nickname,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            platform: {
                field: 'platform',
                valueGetter: p => this.getPlatform(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.platform,
                renderCell: params => <UserPlatform value={params.value as Platform} />,
                sortable: false,
                flex: 0.2,
                width: 200,
            },
            brand: {
                field: 'brand',
                valueGetter: p => this.getBrand(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.brand,
                renderCell: params => <UserPlatform value={params.value as Brand} />,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            license_type: {
                field: 'license_type',
                valueGetter: p => this.getLicenseType(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.license_type,
                renderCell: renderCellWithTooltip,
                sortable: true,
                flex: 0.1,
                width: 100,
            },
            platform_cs: {
                field: 'platform_cs',
                valueGetter: p => this.getPlatform(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.platform_cs,
                renderCell: params => <UserPlatform value={params.value as Platform} />,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            account_status: {
                field: 'account_status',
                valueGetter: p => this.getChipValue(this.getAccountStatus(p), nameof<UserAccountStatus>()),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.account_status,
                renderCell: renderChipCell,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            withdrawal_status: {
                field: 'withdrawal_status',
                valueGetter: p => this.getChipValue(this.getWithdrawalStatus(p), nameof<FeatureAccessStatus>()),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.withdrawal_status,
                renderCell: renderChipCell,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            deposit_status: {
                field: 'deposit_status',
                valueGetter: p => this.getChipValue(this.getDepositStatus(p), nameof<FeatureAccessStatus>()),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.deposit_status,
                renderCell: renderChipCell,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            security_cases: {
                field: 'security_cases',
                valueGetter: p => this.getSecurityCases(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.security_cases,
                renderCell: renderSecurityCasesCell,
                sortable: false,
                flex: 0.2,
                width: 200,
            },
            'latest_kyc.payment.account_verification_status': {
                field: 'latest_kyc.payment.account_verification_status',
                valueGetter: p => this.getChipValue(this.getLatestPaymentAccountVerificationStatus(p), nameof<AccountVerificationStatus>()),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders['latest_kyc.payment.account_verification_status'],
                renderCell: renderChipCell,
                sortable: false,
                flex: 0.15,
                width: 150,
            },
            'latest_kyc.payment.account_verification_status_cs': {
                field: 'latest_kyc.payment.account_verification_status_cs',
                valueGetter: p => this.getChipValue(this.getLatestPaymentAccountVerificationStatus(p), nameof<AccountVerificationStatus>()),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders['latest_kyc.payment.account_verification_status_cs'],
                renderCell: renderChipCell,
                sortable: false,
                flex: 0.11,
                width: 110,
            },
            'latest_kyc.security.account_verification_status': {
                field: 'latest_kyc.security.account_verification_status',
                valueGetter: p => this.getChipValue(this.getSecurityKycStatus(p), nameof<AccountVerificationStatus>()),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders['latest_kyc.security.account_verification_status'],
                renderCell: renderChipCell,
                sortable: false,
                flex: 0.11,
                width: 110,
            },
            phone: {
                field: 'phone',
                valueGetter: p => this.getPhone(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.phone,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.12,
                width: 120,
            },
            referrer_player_id: {
                field: 'referrer_player_id',
                valueGetter: p => this.getReferrerPlayerId(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.referrer_player_id,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            referrer_username: {
                field: 'referrer_username',
                valueGetter: p => this.getReferrerUsername(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.referrer_username,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            referred_date: {
                field: 'referred_date',
                valueGetter: p => this.getReferredDate(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.referred_date,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            email: {
                field: 'email',
                valueGetter: p => this.getEmail(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.email,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.14,
                width: 140,
            },
            username: {
                field: 'username',
                valueGetter: p => this.getUsername(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.username,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            'finance.balance': {
                field: 'finance.balance',
                valueGetter: p => this.getBalance(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders['finance.balance'],
                renderCell: renderAmountCurrencyCellWithTooltip,
                sortable: true,
                flex: 0.1,
                width: 100,
            },
            p2p_transfer_status: {
                field: 'p2p_transfer_status',
                valueGetter: p => this.getChipValue(this.getP2PTransferStatus(p), nameof<FeatureAccessStatus>()),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.p2p_transfer_status,
                renderCell: renderChipCell,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            uid_link_security: {
                field: 'uid_link_security',
                valueGetter: p => this.getUidLinkSecurity(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.uid_link_security,
                renderCell: renderTextLink,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            uid_link_cs: {
                field: 'uid_link_cs',
                valueGetter: p => this.getUidLinkCS(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.uid_link_cs,
                renderCell: renderTextLink,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            uid_link_agent: {
                field: 'uid_link_agent',
                valueGetter: p => this.getUidLinkAgent(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.uid_link_agent,
                renderCell: renderTextLink,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            register_marketing_code: {
                field: 'register_marketing_code',
                valueGetter: p => this.getMarketingCode(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.register_marketing_code,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            referrer: {
                field: 'referrer',
                valueGetter: p => this.getReferrer(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.referrer,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            labels: {
                field: 'labels',
                valueGetter: p => this.getLabels(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.labels,
                renderCell: renderLabelsCell,
                sortable: false,
                flex: 0.17,
                width: 170,
            },
            national_id: {
                field: 'national_id',
                valueGetter: p => this.getNationalId(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.national_id,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            networks_PKW: {
                field: 'networks_PKW',
                valueGetter: p => this.getCashGamesId(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.networks_PKW,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.11,
                width: 110,
            },
            networks_BL: {
                field: 'networks_BL',
                valueGetter: p => this.getTournamentsId(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.networks_BL,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.11,
                width: 110,
            },
            withdrawal_approved_amount: {
                field: 'withdrawal_approved_amount',
                valueGetter: p => this.getApprovedWithdrawalAmount(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.withdrawal_approved_amount,
                renderCell: renderAmountCurrencyCellWithTooltip,
                sortable: false,
                flex: 0.15,
                width: 140,
            },
            withdrawal_approved_count: {
                field: 'withdrawal_approved_count',
                valueGetter: p => this.getApprovedWithdrawalCount(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.withdrawal_approved_count,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.12,
                width: 120,
            },
            withdrawal_pending_amount: {
                field: 'withdrawal_pending_amount',
                valueGetter: p => this.getPendingWithdrawalAmount(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.withdrawal_pending_amount,
                renderCell: renderAmountCurrencyCellWithTooltip,
                sortable: false,
                flex: 0.15,
                width: 150,
            },
            'latest_login.logged_at_ts.seconds': {
                field: 'latest_login.logged_at_ts.seconds',
                valueGetter: p => this.getLastLogin(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders['latest_login.logged_at_ts.seconds'],
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.25,
                width: 250,
            },
            country: {
                field: 'country',
                valueGetter: p => this.getCountry(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.country,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            first_name: {
                field: 'first_name',
                valueGetter: p => this.getFirstName(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.first_name,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.15,
                width: 150,
            },
            last_name: {
                field: 'last_name',
                valueGetter: p => this.getLastName(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.last_name,
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.15,
                width: 150,
            },
            'date_of_joining.seconds': {
                field: 'date_of_joining.seconds',
                valueGetter: p => this.getDateOfJoining(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders['date_of_joining.seconds'],
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.2,
                width: 200,
            },
            login_countries: {
                field: 'login_countries',
                valueGetter: p => this.getCountries(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.login_countries,
                renderCell: renderTextListWithTooltipCell,
                sortable: false,
                flex: 0.12,
                width: 130,
            },
            'finance.withdrawal.total.pending_count': {
                field: 'finance.withdrawal.total.pending_count',
                valueGetter: p => this.getWithdrawalPendingCount(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders['finance.withdrawal.total.pending_count'],
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.1,
                width: 100,
            },
            deposit_total: {
                field: 'deposit_total',
                valueGetter: p => this.getDepositTotal(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.deposit_total,
                renderCell: renderCurrencyCellWithEmptyCheck,
                sortable: false,
                flex: 0.15,
                width: 140,
            },
            withdrawal_total: {
                field: 'withdrawal_total',
                valueGetter: p => this.getWithdrawalTotal(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.withdrawal_total,
                renderCell: renderCurrencyCellWithEmptyCheck,
                sortable: false,
                flex: 0.15,
                width: 140,
            },
            casino_ggr_lifetime: {
                field: 'casino_ggr_lifetime',
                valueGetter: p => this.getCasinoGgrLifetime(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.casino_ggr_lifetime,
                renderCell: renderCurrencyCellWithEmptyCheck,
                sortable: false,
                flex: 0.15,
                width: 140,
            },
            poker_ggr_lifetime: {
                field: 'poker_ggr_lifetime',
                valueGetter: p => this.getPokerGgrLifetime(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.poker_ggr_lifetime,
                renderCell: renderCurrencyCellWithEmptyCheck,
                sortable: false,
                flex: 0.15,
                width: 140,
            },
            sports_ggr_lifetime: {
                field: 'sports_ggr_lifetime',
                valueGetter: p => this.getSportsGgrLifetime(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: gridUserProfileLocalizedHeaders.sports_ggr_lifetime,
                renderCell: renderCurrencyCellWithEmptyCheck,
                sortable: false,
                flex: 0.15,
                width: 140,
            },
        };

        return columnsMapping;
    }
    private getSportsGgrLifetime(p: GridValueGetterParams): CurrencyColumnValue {
        const user = this.getRow(p);
        return {amount: user?.finance?.ggr_lifetime?.sports, currency: user?.currency};
    }
    private getPokerGgrLifetime(p: GridValueGetterParams): CurrencyColumnValue {
        const user = this.getRow(p);
        return {amount: user?.finance?.ggr_lifetime?.poker, currency: user?.currency};
    }
    private getCasinoGgrLifetime(p: GridValueGetterParams): CurrencyColumnValue {
        const user = this.getRow(p);
        return {amount: user?.finance?.ggr_lifetime?.casino, currency: user?.currency};
    }
    private getDepositTotal(p: GridValueGetterParams): CurrencyColumnValue {
        const user = this.getRow(p);
        return {amount: user?.finance?.deposit?.total?.successful_amount, currency: user?.currency};
    }
    private getWithdrawalTotal(p: GridValueGetterParams): CurrencyColumnValue {
        const user = this.getRow(p);
        return {amount: user?.finance?.withdrawal?.total?.successful_amount, currency: user?.currency};
    }

    private getUid(p: GridValueGetterParams) {
        return this.getRow(p)?.uid;
    }

    private getCountries(p: GridValueGetterParams): string[] {
        return this.getRow(p)?.countries;
    }

    private getDateOfJoining(p: GridValueGetterParams): string {
        return formatTimestamp(this.getRow(p)?.date_of_joining, 'date-time-with-seconds');
    }

    private getFirstName(p: GridValueGetterParams): string {
        return this.getRow(p)?.first_name;
    }

    private getLastName(p: GridValueGetterParams): string {
        return this.getRow(p)?.last_name;
    }

    private getLastLogin(p: GridValueGetterParams) {
        return formatTimestamp(this.getRow(p)?.latest_login?.logged_at_ts, 'date-time-with-seconds');
    }

    private getNickname(p: GridValueGetterParams) {
        return this.getRow(p)?.nickname;
    }

    private getFullName(p: GridValueGetterParams) {
        const user = this.getRow(p);
        return user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : null;
    }

    private getAccountStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.account_status ?? UserAccountStatus.Active;
    }

    private getWithdrawalStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.withdrawal_status ?? FeatureAccessStatus.Unlocked;
    }

    private getDepositStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.deposit_status ?? FeatureAccessStatus.Unlocked;
    }

    private getSecurityCases(p: GridValueGetterParams) {
        return this.getRow(p)?.security_cases;
    }

    private getPlatform(p: GridValueGetterParams) {
        return this.getRow(p)?.platform;
    }

    private getBrand(p: GridValueGetterParams) {
        return this.getRow(p)?.brand;
    }

    private getLicenseType(p: GridValueGetterParams) {
        return this.getRow(p)?.license_type;
    }

    private getLatestPaymentAccountVerificationStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.latest_kyc?.payment?.account_verification_status;
    }

    private getPhone(p: GridValueGetterParams) {
        return this.getRow(p)?.contact?.mobile?.full_number;
    }

    private getReferrerPlayerId(p: GridValueGetterParams) {
        return this.getRow(p)?.referral?.inviting_user_id;
    }

    private getReferrerUsername(p: GridValueGetterParams) {
        return this.getRow(p)?.referral?.inviting_user_username;
    }

    private getReferredDate(p: GridValueGetterParams) {
        return formatTimestamp(this.getRow(p)?.referral?.referred_at, 'date');
    }

    private getEmail(p: GridValueGetterParams) {
        return this.getRow(p)?.contact?.email;
    }

    private getUsername(p: GridValueGetterParams) {
        return this.getRow(p)?.username;
    }

    private getBalance(p: GridValueGetterParams): AmountCurrencyCellProps {
        const user = this.getRow(p);
        return {amount: user?.finance?.balance, currency: user?.currency};
    }

    private getP2PTransferStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.p2p_transfer_status;
    }

    private getUidLinkCS(p: GridValueGetterParams) {
        return {id: this.getRow(p)?.uid, path: RouteUrl.CustomerSupportDetails} as TextLinkCell;
    }

    private getUidLinkAgent(p: GridValueGetterParams) {
        return {id: this.getRow(p)?.uid, path: RouteUrl.AgentReportPlayerDetails} as TextLinkCell;
    }

    private getUidLinkSecurity(p: GridValueGetterParams) {
        return {id: this.getRow(p)?.uid, path: RouteUrl.SecurityDetails} as TextLinkCell;
    }

    private getMarketingCode(p: GridValueGetterParams) {
        return this.getRow(p)?.marketing_info?.register_marketing_code;
    }

    private getReferrer(p: GridValueGetterParams) {
        return this.getRow(p)?.affiliate?.btag ?? this.getRow(p)?.referral?.inviting_user_id;
    }

    private getLabels(p: GridValueGetterParams) {
        return labelCellValueGetter(this.getRow(p)?.labels);
    }

    private getNationalId(p: GridValueGetterParams) {
        return this.getRow(p)?.region_player_info?.national_id;
    }

    private getSecurityKycStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.latest_kyc?.security?.account_verification_status;
    }

    private getCashGamesId(p: GridValueGetterParams) {
        return this.getRow(p)?.networks?.find(item => item.platform === Platform.Pkw)?.uid;
    }

    private getTournamentsId(p: GridValueGetterParams) {
        return this.getRow(p)?.networks?.find(item => item.platform === Platform.Bl)?.uid;
    }

    private getApprovedWithdrawalAmount(p: GridValueGetterParams): AmountCurrencyCellProps {
        const user = this.getRow(p);
        return {amount: user?.finance?.withdrawal?.total?.successful_amount, currency: user?.currency};
    }

    private getApprovedWithdrawalCount(p: GridValueGetterParams) {
        const user = this.getRow(p);
        return user?.finance?.withdrawal?.total?.successful_count;
    }

    private getPendingWithdrawalAmount(p: GridValueGetterParams): AmountCurrencyCellProps {
        const user = this.getRow(p);
        return {amount: user?.finance?.withdrawal?.total?.pending_amount, currency: user?.currency};
    }

    private getCountry(p: GridValueGetterParams) {
        return this.getRow(p)?.address?.country_info?.iso_alpha2_code;
    }

    private getWithdrawalPendingCount(p: GridValueGetterParams) {
        return this.getRow(p)?.finance?.withdrawal?.total?.pending_count;
    }
}

export const DataGridUserProfileClient = withDataGridEntityClient<UserProfileViewModelKeys, UserProfileGridItem>(MuiDataGridClient, () =>
    new DataGridUserProfileConfigFactory().getColumnConfig()
);

export const DataGridUserProfileServer = withDataGridEntityServer<UserProfileViewModelKeys, UserProfileGridItem>(MuiDataGridServer, () =>
    new DataGridUserProfileConfigFactory().getColumnConfig()
);

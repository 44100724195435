import {ServiceResponsePayload} from '@services/types';

export const domain = 'entity';
export const fetchBufferTimeSpan = 100;
export const nestedEntitiesJoinCharacter = '.';

export enum EntityType {
    UserProfile = 'UserProfile',
    UserLogin = 'UserLogin',
    Transaction = 'Transaction',
    KycCheckResults = 'KycCheckResults',
    RgUserLimits = 'RgUserLimits',
    UserAction = 'UserAction',
    Note = 'Note',
    BotGroup = 'BotGroup',
    UserStatistics = 'UserStatistics',
    FriendGroup = 'FriendGroup',
    PlayersRelation = 'PlayersRelation',
    SecurityCase = 'SecurityCase',
    AccountVerification = 'AccountVerification',
    MarketingCode = 'MarketingCode',
    PlayerBonus = 'PlayerBonus',
    PlayerReferral = 'PlayerReferral',
    PlayerReferrerSummary = 'PlayerReferrerSummary',
    BoUser = 'BoUser',
    BoRole = 'BoRole',
    BoModule = 'BoModule',
    LegalDocAcceptanceVersion = 'LegalDocAcceptanceVersion',
    Country = 'Country',
    State = 'State',
    Occupation = 'Occupation',
    GeoCheckSummary = 'GeoCheckSummary',
    ReferralSettings = 'ReferralSettings',
    BulkOperation = 'BulkOperation',
    EventLogRecord = 'EventLogRecord',
    LabelGroup = 'LabelGroup',
    AggregatedFinanceSummary = 'AggregatedFinanceSummary',
    AgentPathStatistics = 'AgentPathStatistics',
    AgentPlayerReporting = 'AgentPlayerReporting',
    AgentProfile = 'AgentProfile',
    Backpack = 'Backpack',
    GameTemplate = 'GameTemplate',
    GameRoom = 'GameRoom',
    GameBlind = 'GameBlind',
    BonusCode = 'BonusCode',
    Rule = 'Rule',
    CasinoGame = 'CasinoGame',
    CasinoSupplier = 'CasinoSupplier',
    AgentLevelReport = 'AgentLevelReport',
    SportsbookTransaction = 'SportsbookTransaction',
    EmbeddedReport = 'EmbeddedReport',
    PageView = 'PageView',
    UserActivitySummary = 'UserActivitySummary',
}

export const baseFilterKeys = ['id', 'page', 'size', 'sortField', 'sortOrder', 'invalid'] as const;

export type BaseFilterKeys = (typeof baseFilterKeys)[number];

export type Filter<T extends string = BaseFilterKeys> = Partial<Record<T, unknown>>;

export class EntityFetchRequestPayload<TFields extends string = string> {
    type: EntityType;
    filter: string;
    fields: TFields[];
    body?: object;
}

export type EntityFetchResponsePayload<TItem = unknown> = {
    items: TItem[];
    total: number;
};

export class EntityFetchServiceResponsePayload<TItem = unknown> extends ServiceResponsePayload<
    EntityFetchRequestPayload,
    EntityFetchResponsePayload<TItem>
> {}

export type EntityMeta = {
    type: EntityType;
    keys: string[];
    fields: string[];
};

export enum SortOrder {
    Asc = 'asc',
    Desc = 'desc',
}

export const entityReservedId = 'entityReservedId'; //will be used as an id for none paginated response

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {defineMessages} from 'react-intl';
import {GridRowParams} from '@mui/x-data-grid';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {useDataGridSelectedIds} from '@components/data-grid/mui';
import {FilterWithPlacement} from '@components/filter/types';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {BonusCreditType, BonusStatus} from '@models/generated/graphql';
import {ModuleName} from '@models/modules';
import {Policy} from '@auth';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {extendedViewCleanDelay} from '@redux/view';

import {
    BonusCodeDetailsViewModel,
    BonusCodeDetailsViewModelKeys,
    BonusCodeQueryFilters,
    BonusEngineAddBonusCode,
    BulkBonusEngineButton,
    DataGridBonusCodeKeys,
    DataGridBonusCodeServer,
    useBonusCodes,
} from '../features/block-bonus-code';

const localized = defineMessages({
    bonusEngineHeader: {
        id: 'BE_bonusEngineHeader',
        defaultMessage: 'Bonus Engine',
    },
});

export function BonusEnginePage() {
    const keys: BonusCodeDetailsViewModelKeys[] = [
        'bonus_id',
        'bonus_name',
        'credit_type',
        'total_paid_out_amount',
        'create_time.seconds',
        'bonus_status',
        'description',
    ];
    const columns: DataGridBonusCodeKeys[] = [...keys, 'deactivate_action', 'details_action'];
    const {items, totalCount, handleFilterChange, filterString, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} =
        useBonusCodes({
            viewType: 'BonusEngine',
            fields: keys,
            realtimeMode: RealtimeUpdatesMode.Confirm,
            triggers: [{type: RealtimeMessageTrigger.Add}],
            syncWithUrl: true,
            validateFilter: null,
            defaultSorting: [{field: 'create_time.seconds', sort: 'desc'}],
            cleanDelay: extendedViewCleanDelay,
        });
    const {selectedIds, handleSelect} = useDataGridSelectedIds();

    const isRowSelectable = useCallback((params: GridRowParams): boolean => {
        const row = params.row as BonusCodeDetailsViewModel;
        const isSelectable = row?.bonus_status === BonusStatus.Active && row?.credit_type === BonusCreditType.MttToken;
        return isSelectable;
    }, []);

    const [primaryNode, setPrimaryNode] = useState<Omit<FilterWithPlacement, 'filterName'>>();
    const [secondaryNode, setSecondaryNode] = useState<Omit<FilterWithPlacement, 'filterName'>>();

    const primaryRef = useRef<HTMLDivElement>();
    const secondaryRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (primaryRef.current) {
            setPrimaryNode({nodeId: 'primary', node: primaryRef.current});
        }
        if (secondaryRef.current) {
            setSecondaryNode({nodeId: 'secondary', node: secondaryRef.current});
        }
    }, []);

    return (
        <LayoutQueue
            header={
                <PageHeader
                    header={localized.bonusEngineHeader}
                    actions={
                        <>
                            <BonusEngineAddBonusCode />
                            <BulkBonusEngineButton selectedIds={selectedIds} />
                        </>
                    }
                />
            }
            toolbar={
                <>
                    <Toolbar>
                        <ToolbarItemsContainer primary>
                            <ToolbarItems streched ref={primaryRef} />
                            <ToolbarItems secondary ref={secondaryRef} />
                        </ToolbarItemsContainer>
                    </Toolbar>
                    <BonusCodeQueryFilters
                        model={filterString}
                        onChange={handleFilterChange}
                        availableFilters={[
                            {
                                filterName: 'id_name',
                                ...primaryNode,
                            },
                            {
                                filterName: 'credit_type',
                                ...primaryNode,
                            },
                            {
                                filterName: 'bonus_status',
                                ...primaryNode,
                            },
                            {
                                filterName: 'create_time',
                                ...secondaryNode,
                            },
                        ]}
                    />
                </>
            }
            body={
                <DataGridBonusCodeServer
                    checkboxSelection
                    selectedIds={selectedIds}
                    columns={columns}
                    rows={items}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                    isRowSelectable={isRowSelectable}
                    onSelect={handleSelect}
                />
            }
        />
    );
}

export const bonusEngineResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'BonusEngine',
    rules: new PoliciesAccessRule([new Policy(ModuleName.MarketingFunction, null, 'read')]),
});
